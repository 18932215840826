import React, { useMemo, useState, forwardRef } from 'react';
import { Avatar, SxProps, Theme } from '@mui/material';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import { addAlpha, getInitials } from '../utils/helpers';
import { colorValuesUtil } from '../utils/ColorValuesUtil';
import { TypographySize } from 'orby-ui/src/components/typography/typography-utils';
import { FontWeight } from 'orby-ui/src/components/typography/typography-utils';

interface UserIconProps {
  imageUrl?: string;
  fullName?: string;
  email: string;
  sx?: SxProps<Theme>;
  textColor?: string;
  textSize?: TypographySize;
  textWeight?: FontWeight;
}

// Forward ref is needed so this component can be used within a MUI tooltip
// https://mui.com/material-ui/react-tooltip/#custom-child-element
const UserIcon = forwardRef<HTMLDivElement, UserIconProps>(
  (
    {
      imageUrl,
      fullName,
      email,
      sx = {},
      textColor = OrbyColorPalette['grey-900'],
      textSize = 'md',
      textWeight = 'medium',
      ...props
    },
    ref,
  ) => {
    const [imgError, setImgError] = useState(false);
    const displayName = fullName?.trim() || email;

    const initials = useMemo(() => getInitials(displayName), [displayName]);

    return imgError || !imageUrl ? (
      <Avatar
        alt='user-initials'
        sx={{ bgcolor: addAlpha(colorValuesUtil.getColor(email), 0.4), ...sx }}
        {...props}
        ref={ref}
      >
        <OrbyTypography size={textSize} weight={textWeight} color={textColor}>
          {initials}
        </OrbyTypography>
      </Avatar>
    ) : (
      <Avatar
        alt='user-photo'
        sx={sx}
        slotProps={{
          img: { referrerPolicy: 'no-referrer' },
        }}
        src={imageUrl}
        onError={() => setImgError(true)}
        {...props}
        ref={ref}
      />
    );
  },
);

export default React.memo(UserIcon);
