import produce from 'immer';
import {
  TaskStatusStats,
  TaskSummaryStats,
  AccuracyStats,
  AutomationStats,
  ExecutionStats,
  TeamStats,
} from 'protos/pb/v1alpha2/dashboard_service';
import { Reducer } from 'redux';
import { DashboardActionType } from '../actions/actions.constants';

export interface DashboardState {
  taskSummary?: TaskSummaryStats;
  taskStatus?: TaskStatusStats;
  accuracyStats?: AccuracyStats;
  automationStats?: AutomationStats;
  executionStats?: ExecutionStats;
  teamStats?: TeamStats;
  statsError?: Error;
}

const initialState: DashboardState = {};

export const dashboardReducer: Reducer<DashboardState> = (
  state: DashboardState = initialState,
  action: any,
) =>
  produce(state, (draft: DashboardState) => {
    switch (action.type) {
      case DashboardActionType.GET_DASHBOARD_STATS: {
        draft.taskStatus = undefined;
        draft.taskSummary = undefined;
        draft.accuracyStats = undefined;
        break;
      }
      case DashboardActionType.GET_DASHBOARD_STATS_COMPLETED: {
        const {
          taskStatus,
          taskSummary,
          accuracyStats,
          automationStats,
          executionStats,
          teamStats,
        } = action.payload;

        if (taskStatus) {
          draft.taskStatus = taskStatus;
        }
        if (taskSummary) {
          draft.taskSummary = taskSummary;
        }
        if (accuracyStats) {
          draft.accuracyStats = accuracyStats;
        }
        if (automationStats) {
          draft.automationStats = automationStats;
        }
        if (executionStats) {
          draft.executionStats = executionStats;
        }
        if (teamStats) {
          draft.teamStats = teamStats;
        }
        break;
      }
      case DashboardActionType.GET_DASHBOARD_STATS_ERROR: {
        draft.statsError = action.payload;
        break;
      }
      default:
        break;
    }
  });
