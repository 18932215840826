//TODO: Refactor the functions to enums. (OA-4485)
export const getFontWeight = (weight: FontWeight) => {
  switch (weight) {
    case 'regular':
      return 400;
    case 'medium':
      return 500;
    case 'semibold':
      return 600;
    case 'bold':
      return 700;
    default:
      return 400; // Default to 'regular' if no weight is specified
  }
};

//TODO: Refactor the functions to enums.(OA-4485)
export const getFontSize = (size: TypographySize) => {
  switch (size) {
    case 'xl':
      return '1.25rem';
    case 'lg':
      return '1.125rem';
    case 'md':
      return '1rem';
    case 'sm':
      return '.875rem';
    case 'xs':
      return '.75rem';
    case 'xxs':
      return '.625rem';
    case 'display-sm':
      return '1.875rem';
    case 'display-xs':
      return '1.5rem';
    case 'display-md':
      return '2.25rem';
    case 'display-lg':
      return '2.5rem';
    case 'display-xxs':
      return '0.5rem';
    case 'text-xxs':
      return '0.563rem';
    default:
      return '.875rem'; // Default to 'sm' if no size is specified
  }
};

export const getLineHeight = (size: TypographySize) => {
  switch (size) {
    case 'xl':
      return '1.875rem';
    case 'lg':
      return '1.75rem';
    case 'md':
      return '1.5rem';
    case 'sm':
      return '1.25rem';
    case 'xs':
      return '1.125rem';
    case 'display-sm':
      return '2.375rem';
    case 'display-xs':
      return '2rem';
    case 'display-md':
      return '2.75rem';
    default:
      return '1.25rem'; // Default to 'sm' if no size is specified
  }
};

export type FontWeight = 'regular' | 'medium' | 'semibold' | 'bold' | undefined;

export type TypographySize =
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'display-xxs'
  | 'display-sm'
  | 'display-xs'
  | 'display-md'
  | 'display-lg'
  | 'text-xxs'
  | undefined;
