import { Box, CircularProgress } from '@mui/material';
import React, { FC, memo, useRef, useState } from 'react';
import UploaderIcon from '../static/icons/task-uploader-icon.svg';
import { Info } from '@mui/icons-material';
import { File } from 'protos/automation_mining/ontology/data_models';
import { v4 as uuid4 } from 'uuid';
import { OrbyButton, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { SvgIconComponent } from '@mui/icons-material';
import { MAX_PROTO_SIZE_IN_BYTES } from '../utils/constants';

enum ErrorType {
  NO_ERROR = 0,
  WRONG_FILE_TYPE = 1,
  FILE_TOO_LARGE = 2,
}

const errorTypeString = {
  [ErrorType.NO_ERROR]: '',
  [ErrorType.WRONG_FILE_TYPE]: 'Document format is not supported',
  [ErrorType.FILE_TOO_LARGE]:
    'Please ensure that the total file size does not exceed 100MB',
};

interface Props {
  files: File[];
  setFiles?: React.Dispatch<React.SetStateAction<File[]>>;
  setFile?: (file: File) => void;
  error: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * accepts comma separated formats with no whitespace character
   * ex: '.png,.mkv,.mp3,.mp4'
   */
  acceptedFormat: string;
  acceptedMIMEFormat: string[];
  acceptedFormatMessage: string;
  /**
   * fileIcon can be a string(.svg) or a map of string to (string | SvgIconComponent).
   * map key is MIME type of the file existed in acceptedMIMEFormat,
   * map value is either a string(.svg) or a SvgIconComponent.
   * e.g. new Map([['image/png', ImageIcon], ['application/pdf', 'path/to/png.svg']])
   */
  fileIcon: string | Map<string, string | SvgIconComponent>;
  allowMultipleFiles: boolean;
  isUploading: boolean;
  setTotalFilesSize?: React.Dispatch<React.SetStateAction<number>>;
  /**
   * heading to be displayed in the file picker, this is displayed when there are no files and is suffixed by 'or Browse'
   */
  heading?: string;

  /**
   * height of the file picker
   */
  height?: string;
}

const CustomFilePicker: FC<Props> = ({
  files,
  setFiles,
  setFile,
  error,
  setError,
  acceptedFormat,
  acceptedMIMEFormat,
  acceptedFormatMessage,
  fileIcon,
  allowMultipleFiles,
  isUploading,
  setTotalFilesSize,
  heading = 'Drag and Drop the file(s) here',
  height = '202px',
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [errorType, setErrorType] = useState(ErrorType.NO_ERROR);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsHovered(true);
  };

  const validateAndSetFiles = (fileList: FileList | null) => {
    setErrorType(ErrorType.NO_ERROR);
    setError(false);
    if (fileList) {
      let totalFilesSize = 0;
      for (const localFile of fileList) {
        const file = File.create({});
        file.mimeType = localFile.type;
        // Check if the file is of desired format
        if (acceptedMIMEFormat.includes(localFile.type)) {
          if (localFile.size >= MAX_PROTO_SIZE_IN_BYTES) {
            setErrorType(ErrorType.FILE_TOO_LARGE);
            setError(true);
            return;
          }
          totalFilesSize += localFile.size;
          file.path = localFile.name;
          file.name = localFile.name;
          file.id = uuid4();
          const reader = new FileReader();
          reader.readAsArrayBuffer(localFile);
          reader.onload = function () {
            file.byteContent = new Uint8Array(reader.result as ArrayBuffer);
            if (setFiles) {
              setFiles((prevFiles) => [...prevFiles, file]);
            } else if (setFile) {
              setFile(file);
            }
          };
        } else {
          setErrorType(ErrorType.WRONG_FILE_TYPE);
          setError(true);
          return;
        }
      }
      if (setTotalFilesSize) {
        setTotalFilesSize(totalFilesSize);
      }
    }
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsHovered(true);
  };

  const handleDragLeave = () => {
    setIsHovered(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsHovered(false);
    validateAndSetFiles(e.dataTransfer.files);
  };

  const renderFileIcon = (file: File) => {
    if (isUploading) {
      return (
        <CircularProgress
          style={{ height: '20px', width: '20px', alignSelf: 'center' }}
        />
      );
    }
    if (typeof fileIcon === 'string') {
      return (
        <img
          alt='Check Icon'
          style={{ height: '24px', alignSelf: 'center' }}
          src={fileIcon}
        />
      );
    } else if (file && fileIcon instanceof Map) {
      const IconComponent = fileIcon.get(file.mimeType || '');
      if (typeof IconComponent === 'string') {
        return (
          <img
            alt='Check Icon'
            style={{ height: '24px', alignSelf: 'center' }}
            src={IconComponent}
          />
        );
      } else if (IconComponent) {
        return (
          <IconComponent
            sx={{
              color: OrbyColorPalette['blueGrey-700'],
              alignSelf: 'center',
            }}
          />
        );
      }
    }
    return (
      <AttachFileIcon
        sx={{
          color: OrbyColorPalette['blueGrey-700'],
          alignSelf: 'center',
        }}
      />
    );
  };

  return (
    <>
      <input
        multiple={allowMultipleFiles}
        type='file'
        accept={acceptedFormat}
        ref={inputRef}
        tabIndex={-1} // disables focus for this element since this element is off screen
        style={{ display: 'none' }}
        onChange={(event) => {
          validateAndSetFiles(event.target.files);
          if (inputRef.current) {
            inputRef.current.value = '';
          }
        }}
      />
      {files.length === 0 && (
        <Box
          height={height}
          tabIndex={0}
          role='button'
          bgcolor={
            !isHovered
              ? OrbyColorPalette['blueGrey-50']
              : OrbyColorPalette['white-0']
          }
          border={`1px dashed ${OrbyColorPalette['grey-300']}`}
          padding={'40px'}
          textAlign={'center'}
          id='drop-zone'
          borderRadius={'8px'}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              inputRef.current?.click();
            }
          }}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          position={'relative'}
          justifyContent={'center'}
        >
          <img
            alt='Uploader Icon'
            style={{ height: '64px' }}
            src={UploaderIcon}
          />
          <Box
            sx={{
              position: 'absolute',
              left: '0',
              top: '0',
              display: isHovered ? 'flex' : 'none',
              justifyContent: 'center',
              alignItems: 'center',
              background: '#EEF4FFB2',
              backdropFilter: 'blur(4px)',
              width: '100%',
              height: '100%',
              borderRadius: '8px',
              zIndex: 1,
            }}
          >
            <OrbyTypography
              color={OrbyColorPalette['blueGrey-700']}
              weight='medium'
              sx={{
                lineHeight: '20px',
              }}
            >
              Release the mouse to upload the file
            </OrbyTypography>
          </Box>

          <OrbyTypography
            weight={'medium'}
            color={OrbyColorPalette['grey-700']}
            sx={{
              lineHeight: '20px',
              filter: isHovered ? 'blur(2px)' : 'none',
            }}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              {heading} or{' '}
              <OrbyButton
                variant='monochrome-text-flat'
                sx={{
                  color: OrbyColorPalette['blue-700'],
                }}
                onClick={() => {
                  inputRef.current?.click();
                }}
                label='Browse'
              />
            </Box>
          </OrbyTypography>

          {acceptedFormatMessage && (
            <OrbyTypography
              size='xs'
              color={OrbyColorPalette['grey-500']}
              sx={{
                filter: isHovered ? 'blur(2px)' : 'none',
              }}
            >
              {acceptedFormatMessage}
            </OrbyTypography>
          )}
        </Box>
      )}
      {files.map((file, index) => (
        <Box paddingTop={'17px'} display={'flex'} key={file.id}>
          {renderFileIcon(file)}
          <OrbyTypography
            weight={'regular'}
            size={'md'}
            sx={{
              lineHeight: '18px',
              paddingLeft: '8px',
              paddingRight: '24px',
              width: '78%',
              overflowWrap: 'break-word',
              alignSelf: 'center',
            }}
          >
            {file?.name}
          </OrbyTypography>
          <OrbyButton
            variant={'monochrome-text'}
            ariaLabel=''
            sx={{
              padding: '4px 6px',
              alignSelf: 'self-start',
              color: `${OrbyColorPalette['blue-700']} !important`,
            }}
            onClick={() => {
              if (inputRef.current) {
                files.splice(index, 1);
                const filesCopy = [...files];
                if (setFiles) {
                  setFiles(filesCopy);
                } else if (setFile) {
                  setFile(filesCopy[0]);
                }
              }
            }}
            label={'Remove'}
          />
        </Box>
      ))}
      {error && (
        <OrbyTypography
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            paddingTop: '16px',
          }}
          weight={'medium'}
          size={'sm'}
          color={OrbyColorPalette['warning-800']}
        >
          <Info
            fontSize='small'
            sx={{ color: OrbyColorPalette['warning-800'] }}
          />
          {errorTypeString[errorType]}
        </OrbyTypography>
      )}
    </>
  );
};

export default memo(CustomFilePicker);
