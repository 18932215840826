import { Workflow } from 'protos/pb/v1alpha1/orbot_workflow';

export const MANUAL_ASSIGNMENT = 'manualAssignment';
export const BASIC_ROUND_ROBIN = 'basicRoundRobin';
interface UserPreference {
  userId: string;
  flag: string;
}

export const isManualAssignment = (workflow: Workflow, roundNumber: number) => {
  if (roundNumber === 2) {
    return false;
  }
  return (
    Object.keys(
      workflow?.reviewerLists?.find((r) => r?.roundNumber === 1)
        ?.assignmentOption?.manualAssignment || {},
    ).length > 0
  );
};

export const getReviewersListEmails = (
  workflow: Workflow,
  roundNumber: number,
) => {
  const isUnassigned = isManualAssignment(workflow, roundNumber);
  const reviewerList = workflow?.reviewerLists?.find(
    (r) => r?.roundNumber === roundNumber,
  );
  return (
    reviewerList?.assignmentOption?.[
      isUnassigned ? MANUAL_ASSIGNMENT : BASIC_ROUND_ROBIN
    ]?.users?.map((u) => u.user) ?? []
  );
};

export const isRunWorkflowPopupPreferred = (userId?: string): boolean => {
  if (!userId) return false;

  try {
    const stored = localStorage.getItem('isRunWorkflowPopupChecked');
    if (!stored) return false;

    const parsed = JSON.parse(stored);
    if (Array.isArray(parsed)) {
      const userPref = parsed.find(
        (item: UserPreference) => item.userId === userId,
      );
      return userPref?.flag === 'true';
    }
    return false;
  } catch {
    return false;
  }
};
