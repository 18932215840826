import React, { FC, memo } from 'react';
import { Select, SelectProps, SxProps, Theme } from '@mui/material';
import { ExpandMoreRounded } from '@mui/icons-material';
import OrbyColorPalette from '../colors/ColorPalette';
import '../../styles/global.css';

export interface OrbySelectProps
  extends Pick<
    SelectProps,
    | 'MenuProps'
    | 'renderValue'
    | 'onChange'
    | 'value'
    | 'children'
    | 'multiple'
    | 'disabled'
    | 'onClose'
    | 'onOpen'
    | 'inputProps'
  > {
  width?: string;
  height?: string;
  anchorOrigin?: {
    vertical: 'top' | 'center' | 'bottom' | number;
    horizontal: 'left' | 'center' | 'right' | number;
  };
  transformOrigin?: {
    vertical: 'top' | 'center' | 'bottom' | number;
    horizontal: 'left' | 'center' | 'right' | number;
  };
  error?: boolean;
  errorBorderColor?: string;
  dataTestId?: string;
  containerSx?: SxProps<Theme>;
}

const OrbySelect: FC<OrbySelectProps> = ({
  children,
  renderValue,
  onChange,
  onOpen,
  onClose,
  value,
  multiple,
  width = '320px',
  height = '40px',
  anchorOrigin,
  transformOrigin,
  disabled,
  inputProps,
  error,
  errorBorderColor = OrbyColorPalette['error-300'],
  dataTestId,
  containerSx = {},
}) => {
  return (
    <Select
      disabled={disabled}
      multiple={multiple}
      error={error}
      type=''
      sx={{
        width,
        height,
        borderRadius: '8px',
        background: OrbyColorPalette['white-0'],
        borderWidth: '1px !important',
        '.MuiSelect-outlined': {
          display: 'flex',
          alignItems: 'center',
        },
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: OrbyColorPalette['grey-200'],
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: OrbyColorPalette['blue-700'],
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF',
          borderWidth: '1px !important',
          borderColor: OrbyColorPalette['blue-700'],
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: errorBorderColor,
        },
        '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
          boxShadow:
            '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2',
        },
        ...containerSx,
      }}
      IconComponent={ExpandMoreRounded}
      MenuProps={{
        anchorOrigin: anchorOrigin,
        transformOrigin: transformOrigin,
        autoFocus: false,
        PaperProps: {
          sx: {
            scrollbarColor: `${OrbyColorPalette['grey-200']} transparent`, // does not work for safari https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-color#browser_compatibility
            maxHeight: '320px',
            overflowY: 'auto',
            borderRadius: '8px',
            marginTop: '8px',
            border: `1px solid ${OrbyColorPalette['grey-100']}`,
            boxShadow:
              '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
          },
        },
      }}
      inputProps={{
        ...inputProps,
        'data-testid': dataTestId,
        'aria-label': `${value}` ? `${value}` : 'Select',
      }}
      renderValue={renderValue}
      value={value}
      onChange={onChange}
      onOpen={onOpen}
      onClose={(e) => {
        // Hack to blur out the focus from select element
        setTimeout(() => {
          (document.activeElement as HTMLElement).blur();
        }, 0);
        onClose?.(e);
      }}
      displayEmpty
    >
      {children}
    </Select>
  );
};

export default memo(OrbySelect);
