import { Connector } from 'protos/pb/v1alpha2/connector';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';
import { AssignmentMode, customOutlookInbox } from './constants';
import {
  formatDate,
  getActionIndex,
  getApplicationNames,
  getTriggerIndex,
  hasGroupConditionsChanged,
} from './helpers';
import { ApplicationName } from './protos/enums';
import { GmailParamTriggerTriggerType } from 'protos/application/application_params';
import {
  WorkflowAssignmentOption,
  ConditionalAssignment,
  CompositeGroupCondition,
  Operator,
  WorkflowUser,
  ReviewerList,
  Condition,
} from 'protos/common/review';

export const getCreationDetails = (data: Workflow | Connector) => {
  const { creatorEmail, createTime, lastModifiedTime } = data;
  let toolTip = '';
  if (creatorEmail) toolTip += `Created by: ${creatorEmail}\n`;
  if (createTime) toolTip += `Time Created: ${formatDate(createTime)}\n`;
  if (lastModifiedTime)
    toolTip += `Time Modified: ${formatDate(lastModifiedTime)}`;
  return toolTip.trim();
};

export const getIdFromResourceName = (resourceName: string) => {
  const splitArray = resourceName.split('/');
  if (splitArray.length > 1) return splitArray[1];
  return undefined;
};

export const getWorkflowAssignmentOptions = (
  values: any,
): WorkflowAssignmentOption => {
  const conditionalAssignment: ConditionalAssignment[] = [];

  values?.conditions.forEach((condition: any) => {
    const groupCondition: CompositeGroupCondition = {
      conditions: [
        {
          value: condition.value.toString(), // Condition value is always a string
          operator: condition.operator,
          attributeType: condition.attributeType,
        },
      ],
    };
    conditionalAssignment.push({
      groupCondition: groupCondition,
      users: condition.assignees,
    });
  });

  return { conditionalAssignment } as WorkflowAssignmentOption;
};

export const getConditionsFromConditionalAssignment = (workflow?: Workflow) => {
  if (
    workflow?.reviewerLists?.[0]?.assignmentOption?.conditionalAssignment &&
    workflow?.reviewerLists?.[0]?.assignmentOption?.conditionalAssignment
      ?.length > 0
  ) {
    return workflow?.reviewerLists?.[0]?.assignmentOption?.conditionalAssignment?.map(
      (cAssignment: any) => {
        return {
          operator: cAssignment.groupCondition.conditions[0].operator,
          value: cAssignment.groupCondition.conditions[0].value,
          attributeType: cAssignment.groupCondition.conditions[0].attributeType,
          assignees: cAssignment.users,
        };
      },
    );
  }

  return [
    {
      operator: Operator.EQUAL,
      value: '',
      attributeType: { parent: '', name: '' },
      assignees: [],
    },
  ];
};

export const checkAssignmentOptionsChanged = (
  values: any,
  workflow: Workflow,
) => {
  const prevConditionalAssignment =
    workflow?.reviewerLists?.[0]?.assignmentOption?.conditionalAssignment || [];
  const newConditionalAssignment =
    getWorkflowAssignmentOptions(values).conditionalAssignment;

  if (prevConditionalAssignment.length !== newConditionalAssignment?.length) {
    return true;
  }

  for (let i = 0; i < prevConditionalAssignment.length; i++) {
    if (
      hasGroupConditionsChanged(
        prevConditionalAssignment[i].groupCondition?.conditions as Condition[],
        newConditionalAssignment[i].groupCondition?.conditions as Condition[],
      ) ||
      hasReviewUsersChanged(
        prevConditionalAssignment?.[i].users as WorkflowUser[],
        newConditionalAssignment[i].users as WorkflowUser[],
      )
    ) {
      return true;
    }
  }

  return false;
};

export const hasReviewUsersChanged = (
  prevUsers: WorkflowUser[],
  newUsers: WorkflowUser[],
) => {
  if (prevUsers.length != newUsers.length) {
    return true;
  }
  const existingUsersRoundOneMap = new Set(
    prevUsers.map((user) => user.user) || [],
  );
  for (const newUser of newUsers) {
    if (!existingUsersRoundOneMap.has(newUser.user)) {
      return true;
    }
  }
  return false;
};

export const checkForManualAssignment = (workflow?: Workflow) => {
  return !!workflow?.reviewerLists?.find((r) => r?.roundNumber === 1)
    ?.assignmentOption?.manualAssignment;
};

export const checkManualAssignmentStatusChange = (
  values: any,
  workflow?: Workflow,
) => {
  const isPrevManuallyAssigned = checkForManualAssignment(workflow);
  const isManuallyAssigned =
    values.assignmentMode === AssignmentMode.UNASSIGNED;
  // if previous manual assigned status is different from current manual assigned
  // status then form is dirty
  return isPrevManuallyAssigned !== isManuallyAssigned;
};

export const getRoundOneReviewers = (workflow: Workflow) => {
  return checkForManualAssignment(workflow)
    ? workflow?.reviewerLists?.find((r: ReviewerList) => r.roundNumber === 1)
        ?.assignmentOption?.manualAssignment?.users || []
    : workflow?.reviewerLists?.find((r) => r.roundNumber === 1)?.users || [];
};

export const getRoundTwoReviewers = (workflow: Workflow) => {
  return workflow?.reviewerLists?.find((r) => r.roundNumber === 2)?.users || [];
};

export const handleSubmitEmailTriggerForm = (
  workflow: Workflow,
  values: any,
) => {
  const gmailIndex = getTriggerIndex(workflow, ApplicationName.Gmail);
  const outlookIndex = getTriggerIndex(workflow, ApplicationName.Outlook);
  if (
    gmailIndex.stepIndex !== undefined &&
    gmailIndex.triggerIndex !== undefined
  ) {
    workflow.steps![gmailIndex.stepIndex].triggers![
      gmailIndex.triggerIndex
    ].gmail!.trigger!.type = GmailParamTriggerTriggerType.NEW_EMAIL_BODY_ONLY;
    workflow.steps![gmailIndex.stepIndex].triggers![
      gmailIndex.triggerIndex
    ].gmail!.trigger!.ownerEmail = values.email;
    workflow.steps![gmailIndex.stepIndex].triggers![
      gmailIndex.triggerIndex
    ].gmail!.trigger!.labels = values.labels;
  } else if (
    outlookIndex.stepIndex !== undefined &&
    outlookIndex.triggerIndex !== undefined
  ) {
    workflow.steps![outlookIndex.stepIndex].triggers![
      outlookIndex.triggerIndex
    ].gmail!.trigger!.type = GmailParamTriggerTriggerType.NEW_EMAIL_BODY_ONLY;
    workflow.steps![outlookIndex.stepIndex].triggers![
      outlookIndex.triggerIndex
    ].gmail!.trigger!.ownerEmail = values.email;
    workflow.steps![outlookIndex.stepIndex].triggers![
      outlookIndex.triggerIndex
    ].gmail!.trigger!.labels = values.labels;
  }
};

export const handleSubmitSheetActionForm = (
  workflow: Workflow,
  values: any,
) => {
  const gsheetsIndex = getActionIndex(workflow, ApplicationName.GoogleSheets);
  const msExcelIndex = getActionIndex(workflow, ApplicationName.MSExcel);
  if (
    gsheetsIndex.stepIndex !== undefined &&
    gsheetsIndex.actionIndex !== undefined
  ) {
    workflow.steps![gsheetsIndex.stepIndex].actions![
      gsheetsIndex.actionIndex
    ].gsheets!.action!.sheets!.id = values['file_id'];
    workflow.steps![gsheetsIndex.stepIndex].actions![
      gsheetsIndex.actionIndex
    ].gsheets!.action!.sheets!.path = values['sheet_url'];
  } else if (
    msExcelIndex.stepIndex !== undefined &&
    msExcelIndex.actionIndex !== undefined
  ) {
    workflow.steps![msExcelIndex.stepIndex].actions![
      msExcelIndex.actionIndex!
    ].spreadsheet!.action!.sheets!.id = values['file_id'];
    workflow.steps![msExcelIndex.stepIndex].actions![
      msExcelIndex.actionIndex
    ].spreadsheet!.action!.sheets!.path = values['sheet_url'];
  }
};

export const updateStepsTriggerOutlookLabels = (w: Workflow) => {
  const applications = getApplicationNames(w);
  if (applications.includes(ApplicationName.Outlook)) {
    const outlookIndex = getTriggerIndex(w, ApplicationName.Outlook);
    if (outlookIndex.triggerIndex !== -1) {
      const selectedLabels =
        w.steps![outlookIndex.stepIndex!]?.triggers![
          outlookIndex.triggerIndex as number
        ]?.gmail!.trigger!.labels;
      if (selectedLabels!.find((label) => label.id === customOutlookInbox.id)) {
        w.steps![outlookIndex.stepIndex!].triggers![
          outlookIndex.triggerIndex as number
        ].gmail!.trigger!.labels = [];
      }
    }
  }
};
