import React, { memo } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton, SxProps, Theme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { OrbyButton, OrbyTypography } from 'orby-ui/src';

interface CustomModalProps {
  open: boolean;
  heading: string;
  content: JSX.Element;
  handleClose: () => void;
  primaryLabel: string;
  secondaryLabel: string;
  onPrimaryClick: () => void;
  onSecondaryClick?: () => void;
  primaryDisabled?: boolean;
  secondaryDisabled?: boolean;
  headerSx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
  primaryButtonSx?: SxProps<Theme>;
  secondaryButtonSx?: SxProps<Theme>;
  actionSx?: SxProps<Theme>;
  titleSx?: SxProps<Theme>;
  closeButtonSx?: SxProps<Theme>;
  closable?: boolean; // Determines whether the close icon should be displayed in the top right corner."
  disableCloseOnEsc?: boolean; // Determines whether to close modal on pressing Escape key
  disableCloseOnOutsideClick?: boolean; // Determines whether to close modal on clicking modal
  isPrimaryLoading?: boolean;
  hideBackdrop?: boolean;
  buttonSize?: 'small' | 'medium' | 'large';
  primaryButtonTestId?: string;
  disablePortal?: boolean; // Determines whether to disable portal, added this to make the modals compatible with test cases
  headerIcon?: React.ReactNode;
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  heading,
  content,
  handleClose,
  primaryLabel,
  secondaryLabel,
  onPrimaryClick,
  onSecondaryClick = handleClose,
  primaryDisabled = false,
  secondaryDisabled = false,
  containerSx = {},
  primaryButtonSx = {},
  secondaryButtonSx = {},
  closeButtonSx = {},
  actionSx = {},
  headerSx = {},
  titleSx = {},
  closable = true,
  disableCloseOnEsc = false,
  disableCloseOnOutsideClick = false,
  isPrimaryLoading = false,
  hideBackdrop = false,
  buttonSize = 'small',
  primaryButtonTestId,
  disablePortal = false,
  headerIcon,
}) => {
  const handleModalClose: DialogProps['onClose'] = (_event, reason) => {
    if (disableCloseOnOutsideClick && reason && reason === 'backdropClick')
      return;
    handleClose();
  };

  return (
    <Dialog
      disableEscapeKeyDown={disableCloseOnEsc}
      disablePortal={disablePortal}
      onClose={handleModalClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '12px',
        },
      }}
      open={open}
      hideBackdrop={hideBackdrop}
    >
      {headerIcon}
      <DialogTitle sx={{ pt: '24px', ...headerSx }}>
        <OrbyTypography size={'lg'} sx={titleSx} weight={'semibold'}>
          {heading}
        </OrbyTypography>
      </DialogTitle>
      {closable && (
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: '#090909',
            ...closeButtonSx,
          }}
        >
          <CloseIcon fontSize={'small'} />
        </IconButton>
      )}
      <DialogContent sx={{ minWidth: '380px', ...containerSx }}>
        {content}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          px: '24px',
          pb: '24px',
          ...actionSx,
        }}
      >
        {secondaryLabel && (
          <OrbyButton
            onClick={onSecondaryClick}
            variant='monochrome-outline'
            disabled={secondaryDisabled}
            size={buttonSize}
            sx={{
              flexGrow: 1,
              ...secondaryButtonSx,
            }}
            label={secondaryLabel}
            ariaLabel={secondaryLabel}
          />
        )}
        {primaryLabel && (
          <OrbyButton
            loading={isPrimaryLoading}
            onClick={onPrimaryClick}
            variant='primary-contained'
            disabled={primaryDisabled}
            size={buttonSize}
            sx={{
              flexGrow: 1,
              maxWidth: secondaryLabel ? '100%' : '50%',
              ...primaryButtonSx,
            }}
            label={primaryLabel}
            ariaLabel={primaryLabel}
            dataTestId={primaryButtonTestId}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default memo(CustomModal);
