import { produce } from 'immer';
import {
  Document,
  DocumentEntity,
  DocumentTextAnchor,
} from 'protos/google/cloud/documentai/v1/document';
import { Vertex } from 'protos/google/cloud/documentai/v1/geometry';
import { WorkflowTask } from 'protos/pb/v1alpha1/orbot_workflow';
import {
  ExportTasksResponse,
  MissedTask,
  Task,
  TaskSTATUS,
} from 'protos/pb/v1alpha2/tasks_service';
import { WorkflowExecution } from 'protos/pb/v1alpha2/workflow_executions_service';
import { Reducer } from 'redux';
import {
  DataLoadingStatus,
  DeleteTaskActionCompletedPayload,
  DeleteTaskActionPayload,
  DeleteTaskType,
} from '../../utils/constants';
import {
  getSelectedTaskDocument,
  groupTasksByError,
} from '../../utils/helpers';
import { TaskV2ActionType } from '../actions/actions.constants';

export interface EntityStatus {
  id: string;
  label: string;
  isReviewed: boolean;
  isModified: boolean;
  startedEditing: boolean;
  isInDoc: boolean;
  isConfident: boolean;
  isExtra: boolean;
  pages: number[];
  vertices: Vertex[][];
  manualTexts: (string | undefined)[];
}

export interface TaskState {
  tasks: Task[];
  declinedTasks: Task[];
  pendingTasks: Task[];
  pendingTasksAdditionalRound: Task[];
  tasksLoadingStatus: DataLoadingStatus;
  declinedTaskLoadingStatus: DataLoadingStatus;
  declinedNextPageToken?: string;
  declinedTotalSize?: number; // user_declined
  declinedTasksError?: Error;
  systemDeclinedTasks: Task[];
  systemDeclinedTaskLoadingStatus: DataLoadingStatus;
  systemDeclinedNextPageToken?: string;
  systemDeclinedTotalSize?: number; // system_declined
  systemDeclinedTasksError?: Error;
  listTasksError?: Error;
  taskCreationError?: Error;
  processingTaskStatus: DataLoadingStatus;
  nextPageToken?: string;
  totalSize?: number; // completed
  task?: Task;
  confidence?: number;
  getTaskError?: Error;
  entitiesInTask: { [id: string]: { entityStatus: EntityStatus } };
  selectedTaskName?: string;
  pendingTasksLoadingStatus: DataLoadingStatus;
  listPendingTasksError?: Error;
  pendingTasksNextPageToken?: string;
  pendingTasksTotalSize?: number; // pending
  pendingTasksAdditionalRoundLoadingStatus: DataLoadingStatus;
  listPendingTasksAdditionalRoundError?: Error;
  pendingTasksAdditionalRoundNextPageToken?: string;
  pendingTasksAdditionalRoundTotalSize?: number; // pending_additional
  deletingTaskStatus: DataLoadingStatus;
  deletedTask?: DeleteTaskType;
  deletingTaskError?: Error;
  updatingTaskError?: Error;
  addedTask?: Task;
  lastClickOnRow?: number;
  debugDocument?: Document;
  taskDeclinedSuccess?: boolean;
  completedTaskSuccess?: boolean;
  updateBatchTasksLoadingStatus: DataLoadingStatus;
  updateBatchTasksError?: string | any;
  updateBatchTasksSuccess?: string;
  tasksFailedToReassign: string[];
  tasksFailedToDelete: MissedTask[];
  tasksDeletedSuccessfully: string[];
  tasksRequestedForDeletion: string[];
  enableDebugLayout: boolean;
  showActivityMonitor: boolean;

  // UI EXECUTION HISTORY
  uiExecutionHistory: WorkflowTask[];
  uiExecutionHistoryLoadingStatus: DataLoadingStatus;
  listUiExecutionHistoryError?: Error;
  uiExecutionHistoryNextPageToken?: string;
  uiExecutionHistoryTotalSize?: number;
  deleteUiExecutionHistoryCompleted: boolean;
  deleteUiExecutionHistoryError?: string;

  // API EXECUTION HISTORY
  apiExecutionHistory: WorkflowExecution[];
  apiExecutionHistoryLoadingStatus: DataLoadingStatus;
  listApiExecutionHistoryError?: Error;
  apiExecutionHistoryTotalSize?: number;

  // RETRY TASK
  retryTasksLoadingStatus: DataLoadingStatus;
  retryTasksError?: MissedTask[] | string;
  retryTasksSuccess: boolean;

  // API BLOCKED WORKFLOW EXECUTION STATISTICS
  blockedWorkflowExecutionsCount: number;
  blockedWorkflowResourceNames: string[];

  // EXPORT TASKS
  exportTasksLoadingStatus: DataLoadingStatus;
  exportTasksError?: string;
  exportTasksResponse?: ExportTasksResponse;

  // EXPORT TASKS DOWNLOAD
  exportTasksDownloadError?: string;
  exportTasksDownloadLoadingStatus: DataLoadingStatus;

  // DOWNLOAD TASK
  downloadTasksLoadingStatus: DataLoadingStatus;
  downloadTasksError?: string;
  downloadTasksData?: string;
}

const initialState: TaskState = {
  tasks: [],
  processingTaskStatus: DataLoadingStatus.INITIAL,
  pendingTasks: [],
  pendingTasksAdditionalRound: [],
  tasksLoadingStatus: DataLoadingStatus.INITIAL,
  declinedTasks: [],
  declinedTaskLoadingStatus: DataLoadingStatus.INITIAL,
  systemDeclinedTasks: [],
  systemDeclinedTaskLoadingStatus: DataLoadingStatus.INITIAL,
  selectedTaskName: '',
  pendingTasksLoadingStatus: DataLoadingStatus.INITIAL,
  pendingTasksAdditionalRoundLoadingStatus: DataLoadingStatus.INITIAL,
  deletingTaskStatus: DataLoadingStatus.INITIAL,
  deletedTask: undefined,
  task: undefined,
  entitiesInTask: {},
  lastClickOnRow: Date.now(),
  updateBatchTasksLoadingStatus: DataLoadingStatus.INITIAL,
  tasksFailedToReassign: [],
  tasksFailedToDelete: [],
  tasksRequestedForDeletion: [],
  tasksDeletedSuccessfully: [],
  enableDebugLayout: false,
  showActivityMonitor: false,

  apiExecutionHistory: [],
  apiExecutionHistoryLoadingStatus: DataLoadingStatus.INITIAL,

  uiExecutionHistory: [],
  uiExecutionHistoryLoadingStatus: DataLoadingStatus.INITIAL,
  deleteUiExecutionHistoryCompleted: false,
  deleteUiExecutionHistoryError: undefined,

  retryTasksLoadingStatus: DataLoadingStatus.INITIAL,
  retryTasksSuccess: false,
  blockedWorkflowExecutionsCount: 0,
  blockedWorkflowResourceNames: [],

  exportTasksLoadingStatus: DataLoadingStatus.INITIAL,

  exportTasksDownloadLoadingStatus: DataLoadingStatus.INITIAL,
  downloadTasksLoadingStatus: DataLoadingStatus.INITIAL,
};

export const taskV2Reducer: Reducer<TaskState> = (
  state: TaskState = initialState,
  action,
) =>
  produce(state, (draft: TaskState) => {
    switch (action.type) {
      case TaskV2ActionType.UPDATE_ENTITY_STATUS: {
        const entityStatus = action.payload;
        draft.entitiesInTask[entityStatus.id].entityStatus = {
          ...entityStatus,
        };
        draft.entitiesInTask = { ...draft.entitiesInTask };
        const task = draft.task;
        const documentStep = getSelectedTaskDocument(task);
        let rawConfidence = 0;
        let realEntities: DocumentEntity[] = [];
        let reviewedAndInDocEntitiesCount = 0;
        if (documentStep?.documents?.[0]?.entities) {
          for (const entity of documentStep.documents[0].entities) {
            if (entity?.properties?.length) {
              realEntities = [...realEntities, ...entity.properties];
            } else {
              realEntities = [...realEntities, entity];
            }
          }
        }
        realEntities.forEach((e: DocumentEntity) => {
          const reviewed =
            draft.entitiesInTask[e.id as string].entityStatus.isReviewed;
          if (reviewed) {
            rawConfidence += 1;
            reviewedAndInDocEntitiesCount++;
          } else {
            const isInDoc =
              draft.entitiesInTask[e.id as string].entityStatus.isInDoc;
            if (isInDoc) {
              rawConfidence += e.confidence as number;
              reviewedAndInDocEntitiesCount++;
            }
          }
        });
        draft.confidence = rawConfidence / reviewedAndInDocEntitiesCount;
        break;
      }
      case TaskV2ActionType.UPDATE_CELL_TIMESTAMP:
        draft.lastClickOnRow = action.payload;
        break;
      case TaskV2ActionType.UPDATE_ENTITY_IN_TASK: {
        const task = draft.task;
        const entity = action.payload;
        const documentStep = getSelectedTaskDocument(task);
        const index = documentStep?.documents?.[0]?.entities?.findIndex(
          (e) => e.id === entity.id,
        );
        if (index && index >= 0) {
          documentStep?.documents?.[0]?.entities?.splice(index, 1, entity);
          draft.task = { ...task };
        }
        break;
      }
      case TaskV2ActionType.REMOVE_PAGE_ANCHOR: {
        const { entity, index, removeAll } = action.payload;
        const entityStatus = draft.entitiesInTask[entity.id]?.entityStatus;

        if (entityStatus && Object.keys(entityStatus.vertices).length > index) {
          entityStatus.vertices.splice(index, 1);
          if (removeAll) {
            for (let i = index - 1; i >= 0; i--) {
              entityStatus.vertices.splice(i, 1);
            }
          }
          const task = draft.task;
          const documentStep = getSelectedTaskDocument(task);
          const stepIndex = documentStep?.documents?.[0]?.entities?.findIndex(
            (e) => e.id === entity.id,
          );
          if (stepIndex && stepIndex >= 0) {
            entity.pageAnchor?.pageRefs?.splice(index, 1);
            entity.textAnchor?.textSegments?.splice(index, 1);
            if (removeAll) {
              for (let j = index - 1; j >= 0; j--) {
                entity.pageAnchor?.pageRefs?.splice(j, 1);
                entity.textAnchor?.textSegments?.splice(j, 1);
              }
            }
            documentStep?.documents?.[0]?.entities?.splice(
              stepIndex,
              1,
              entity,
            );
            draft.task = { ...task };
          }
        }
        break;
      }
      case TaskV2ActionType.CLEAR_MANUAL_TEXT: {
        const { entityId, segmentId } = action.payload;
        const newEntityStatus = draft.entitiesInTask[entityId]?.entityStatus;
        if (
          newEntityStatus &&
          newEntityStatus.manualTexts?.length > segmentId
        ) {
          newEntityStatus.manualTexts[segmentId] = undefined;
        }
        draft.entitiesInTask[entityId].entityStatus = { ...newEntityStatus };
        break;
      }
      case TaskV2ActionType.ADD_ENTITY_TO_TASK: {
        const task = draft.task;
        const entity = action.payload;
        const documentStep = getSelectedTaskDocument(task);
        const entityCopy = { ...entity };
        entityCopy.id = entity.id + '_copy_' + Date.now();
        const childrenCopy: DocumentEntity[] = [];
        for (const e of entityCopy.properties) {
          const eCopy = { ...e };
          eCopy.id = e.id + '_copy_' + Date.now();
          eCopy.mentionText = '';
          eCopy.confidence = 0;
          eCopy.textAnchor = {} as DocumentTextAnchor;
          childrenCopy.push(eCopy);
        }
        entityCopy.properties = childrenCopy;
        const originalEntityIndex =
          documentStep?.documents?.[0]?.entities?.findIndex(
            (en) => en.id == entity.id,
          );
        if (originalEntityIndex !== -1) {
          documentStep?.documents?.[0]?.entities?.splice(
            (originalEntityIndex as number) + 1,
            0,
            entityCopy,
          );
        } else {
          documentStep?.documents?.[0]?.entities?.splice(
            documentStep.documents[0].entities.length,
            0,
            entityCopy,
          );
        }
        childrenCopy.forEach((entity) => {
          const id = entity.id as string;
          const inDoc = false;
          draft.entitiesInTask[id] = {
            entityStatus: {
              id: id,
              isInDoc: inDoc,
              isModified: false,
              isReviewed: false,
              isExtra: false,
              label: entity.type as string,
              isConfident: (entity.confidence as number) >= 0.8,
              startedEditing: false,
              pages:
                entity.pageAnchor?.pageRefs?.map((ref) => ref.page!) ||
                ([] as number[]),
              //TODO: should be the same as the copies
              vertices: [],
              manualTexts: [],
            },
          };
        });
        draft.task = { ...task };
        break;
      }
      case TaskV2ActionType.SAVE_UPLOADED_DOCUMENT: {
        draft.debugDocument = action.payload;
        break;
      }
      case TaskV2ActionType.REMOVE_SAVED_DOCUMENT: {
        draft.debugDocument = undefined;
        draft.task = undefined;
        break;
      }
      case TaskV2ActionType.LIST_TASKS_V2: {
        draft.tasksLoadingStatus = DataLoadingStatus.LOADING;
        break;
      }
      case TaskV2ActionType.LIST_TASKS_V2_COMPLETED: {
        if (action.refresh) {
          draft.tasks = action.payload;
        } else {
          draft.tasks.push(...action.payload);
        }
        draft.nextPageToken = action.nextPageToken;
        draft.totalSize = action.totalSize;
        draft.tasksLoadingStatus = DataLoadingStatus.LOADED;
        break;
      }
      case TaskV2ActionType.LIST_TASKS_V2_ERROR: {
        draft.tasksLoadingStatus = DataLoadingStatus.ERROR;
        draft.listTasksError = action.payload;
        break;
      }
      case TaskV2ActionType.LIST_DECLINED_TASKS_V2: {
        draft.declinedTaskLoadingStatus = DataLoadingStatus.LOADING;
        break;
      }
      case TaskV2ActionType.LIST_DECLINED_TASKS_V2_COMPLETED: {
        if (action.refresh) {
          draft.declinedTasks = action.payload;
        } else {
          draft.declinedTasks.push(...action.payload);
        }
        draft.declinedNextPageToken = action.nextPageToken;
        draft.declinedTotalSize = action.totalSize;
        draft.declinedTaskLoadingStatus = DataLoadingStatus.LOADED;
        break;
      }
      case TaskV2ActionType.LIST_DECLINED_TASKS_V2_ERROR: {
        draft.declinedTaskLoadingStatus = DataLoadingStatus.ERROR;
        draft.declinedTasksError = action.payload;
        break;
      }
      case TaskV2ActionType.LIST_SYSTEM_DECLINED_TASKS_V2: {
        draft.systemDeclinedTaskLoadingStatus = DataLoadingStatus.LOADING;
        break;
      }
      case TaskV2ActionType.LIST_SYSTEM_DECLINED_TASKS_V2_COMPLETED: {
        if (action.refresh) {
          draft.systemDeclinedTasks = action.payload;
        } else {
          draft.systemDeclinedTasks.push(...action.payload);
        }
        draft.systemDeclinedNextPageToken = action.nextPageToken;
        draft.systemDeclinedTotalSize = action.totalSize;
        draft.systemDeclinedTaskLoadingStatus = DataLoadingStatus.LOADED;
        break;
      }
      case TaskV2ActionType.LIST_SYSTEM_DECLINED_TASKS_V2_ERROR: {
        draft.systemDeclinedTaskLoadingStatus = DataLoadingStatus.ERROR;
        draft.systemDeclinedTasksError = action.payload;
        break;
      }
      case TaskV2ActionType.LIST_PENDING_TASKS: {
        draft.pendingTasksLoadingStatus = DataLoadingStatus.LOADING;
        break;
      }
      case TaskV2ActionType.LIST_PENDING_TASKS_COMPLETED: {
        if (action.refresh) {
          draft.pendingTasks = action.payload;
        } else {
          draft.pendingTasks.push(...action.payload);
        }
        draft.pendingTasksNextPageToken = action.nextPageToken;
        draft.pendingTasksTotalSize = action.totalSize;
        draft.pendingTasksLoadingStatus = DataLoadingStatus.LOADED;
        break;
      }
      case TaskV2ActionType.LIST_PENDING_TASKS_ERROR: {
        draft.pendingTasksLoadingStatus = DataLoadingStatus.ERROR;
        draft.listPendingTasksError = action.payload;
        break;
      }
      case TaskV2ActionType.LIST_PENDING_TASKS_ADDITIONAL_ROUND: {
        draft.pendingTasksAdditionalRoundLoadingStatus =
          DataLoadingStatus.LOADING;
        break;
      }
      case TaskV2ActionType.LIST_PENDING_TASKS_ADDITIONAL_ROUND_COMPLETED: {
        if (action.refresh) {
          draft.pendingTasksAdditionalRound = action.payload;
        } else {
          draft.pendingTasksAdditionalRound.push(...action.payload);
        }
        draft.pendingTasksAdditionalRoundNextPageToken = action.nextPageToken;
        draft.pendingTasksAdditionalRoundTotalSize = action.totalSize;
        draft.pendingTasksAdditionalRoundLoadingStatus =
          DataLoadingStatus.LOADED;
        break;
      }
      case TaskV2ActionType.LIST_PENDING_TASKS_ADDITIONAL_ROUND_ERROR: {
        draft.pendingTasksAdditionalRoundLoadingStatus =
          DataLoadingStatus.ERROR;
        draft.listPendingTasksAdditionalRoundError = action.payload;
        break;
      }
      case TaskV2ActionType.CREATE_TASK_V2:
        draft.processingTaskStatus = DataLoadingStatus.LOADING;
        break;
      case TaskV2ActionType.CREATE_TASK_V2_COMPLETED: {
        if (action.payload) {
          draft.addedTask = action.payload;
          draft.pendingTasks.push(action.payload);
          draft.pendingTasksTotalSize =
            (draft.pendingTasksTotalSize as number) + 1;
        }
        draft.processingTaskStatus = DataLoadingStatus.LOADED;
        break;
      }
      case TaskV2ActionType.CREATE_TASK_V2_ERROR: {
        draft.taskCreationError = action.payload;
        draft.processingTaskStatus = DataLoadingStatus.ERROR;
        break;
      }
      case TaskV2ActionType.CREATE_TASK_V2_CLEAR: {
        draft.addedTask = undefined;
        draft.processingTaskStatus = DataLoadingStatus.INITIAL;
        break;
      }
      case TaskV2ActionType.UPDATE_TASK_V2:
        draft.processingTaskStatus = DataLoadingStatus.LOADING;
        break;
      case TaskV2ActionType.UPDATE_TASK_V2_COMPLETED: {
        const task: Task = action.payload;
        if (task.status !== TaskSTATUS.READY) {
          if (
            [
              TaskSTATUS.REJECTED_INCORRECT,
              TaskSTATUS.REJECTED_ALREADY_COMPLETED,
            ].includes(task.status as TaskSTATUS)
          ) {
            draft.declinedTasks.push(task);
          } else if (
            [TaskSTATUS.ACCEPTED, TaskSTATUS.COMPLETED].includes(
              task.status as TaskSTATUS,
            )
          ) {
            draft.tasks.push(task);
            draft.completedTaskSuccess = true;
          }
        }
        draft.processingTaskStatus = DataLoadingStatus.LOADED;
        if (
          task.status === TaskSTATUS.REJECTED_INCORRECT ||
          task.status === TaskSTATUS.REJECTED_ALREADY_COMPLETED
        ) {
          draft.taskDeclinedSuccess = true;
        }
        break;
      }
      case TaskV2ActionType.UPDATE_TASK_V2_ERROR: {
        draft.updatingTaskError = action.payload;
        draft.processingTaskStatus = DataLoadingStatus.ERROR;
        draft.completedTaskSuccess = false;
        break;
      }
      case TaskV2ActionType.SET_TASK_DECLINED_SUCCESS: {
        draft.taskDeclinedSuccess = action.payload;
        break;
      }
      case TaskV2ActionType.SET_COMPLETED_TASK_SUCCESS: {
        draft.completedTaskSuccess = action.payload;
        break;
      }
      case TaskV2ActionType.DELETE_TASK: {
        const { req }: DeleteTaskActionPayload = action.payload;
        draft.deletingTaskStatus = DataLoadingStatus.LOADING;
        draft.deletedTask = undefined;
        draft.tasksRequestedForDeletion = req.names || ([] as string[]);
        break;
      }
      case TaskV2ActionType.DELETE_TASK_COMPLETED: {
        const { deleteType, res }: DeleteTaskActionCompletedPayload =
          action.payload;
        const missedTasks = res.missedTasks;
        if (missedTasks && missedTasks.length > 0) {
          draft.tasksFailedToDelete = missedTasks;
        }
        const missedTaskNames = missedTasks?.map(
          (missedTask) => missedTask?.task?.name,
        );
        // We only need to remove tasks which are not present
        // in the missed tasks i.e. they we successfully deleted
        const taskNamesToFilter = draft.tasksRequestedForDeletion.filter(
          (name) => !missedTaskNames?.includes(name),
        );
        draft.tasksDeletedSuccessfully = taskNamesToFilter;
        draft.deletedTask = deleteType;
        draft.deletingTaskStatus = DataLoadingStatus.LOADED;
        switch (deleteType) {
          case DeleteTaskType.PENDING: {
            draft.pendingTasks = draft.pendingTasks.filter(
              (t) => !taskNamesToFilter.includes(t.name as string),
            );
            draft.pendingTasksTotalSize =
              (draft.pendingTasksTotalSize as number) -
              taskNamesToFilter.length;
            break;
          }
          case DeleteTaskType.PENDING_ADDITIONAL: {
            draft.pendingTasksAdditionalRound =
              draft.pendingTasksAdditionalRound.filter(
                (t) => !taskNamesToFilter.includes(t.name as string),
              );
            draft.pendingTasksAdditionalRoundTotalSize =
              (draft.pendingTasksAdditionalRoundTotalSize as number) -
              taskNamesToFilter.length;
            break;
          }
          case DeleteTaskType.COMPLETED: {
            draft.tasks = draft.tasks.filter(
              (t) => !taskNamesToFilter.includes(t.name as string),
            );
            draft.totalSize =
              (draft.totalSize as number) - taskNamesToFilter.length;
            break;
          }
          case DeleteTaskType.USER_DECLINED: {
            draft.declinedTasks = draft.declinedTasks.filter(
              (t) => !taskNamesToFilter.includes(t.name as string),
            );
            draft.declinedTotalSize =
              (draft.declinedTotalSize as number) - taskNamesToFilter.length;
            break;
          }
          case DeleteTaskType.SYSTEM_DECLINED: {
            draft.systemDeclinedTasks = draft.systemDeclinedTasks.filter(
              (t) => !taskNamesToFilter.includes(t.name as string),
            );
            draft.systemDeclinedTotalSize =
              (draft.systemDeclinedTotalSize as number) -
              taskNamesToFilter.length;
            break;
          }
        }
        break;
      }
      case TaskV2ActionType.DELETE_TASK_ERROR: {
        draft.deletingTaskStatus = DataLoadingStatus.ERROR;
        draft.deletingTaskError = action.payload;
        draft.deletedTask = undefined;
        break;
      }
      case TaskV2ActionType.CLEAR_DELETED_TASK: {
        draft.tasksFailedToDelete = [];
        draft.deletingTaskError = undefined;
        draft.deletedTask = undefined;
        draft.tasksRequestedForDeletion = [];
        draft.tasksDeletedSuccessfully = [];
        break;
      }
      case TaskV2ActionType.SET_SELECTED_TASK: {
        draft.task = action.payload;
        break;
      }
      case TaskV2ActionType.UPDATE_BATCH_TASKS: {
        draft.updateBatchTasksLoadingStatus = DataLoadingStatus.LOADING;
        break;
      }
      case TaskV2ActionType.UPDATE_BATCH_TASKS_COMPLETED: {
        const { tasks, missedTasks } = action.payload;
        if (missedTasks.length) {
          draft.tasksFailedToReassign = missedTasks.map(
            (t: { task: Task }) => t.task.name,
          );
          const groupErrors = groupTasksByError(missedTasks);
          draft.updateBatchTasksError = groupErrors;
          draft.updateBatchTasksLoadingStatus = DataLoadingStatus.ERROR;
        }
        if (tasks.length) {
          const updatedPendingTasks = draft.pendingTasks.map((task) => {
            const newTask = tasks.find((t: Task) => t.name === task.name);
            return newTask ? newTask : task;
          });
          const updatedAdditionalRoundTasks =
            draft.pendingTasksAdditionalRound.map((task) => {
              const newTask = tasks.find((t: Task) => t.name === task.name);
              return newTask ? newTask : task;
            });
          draft.pendingTasks = updatedPendingTasks;
          draft.pendingTasksAdditionalRound = updatedAdditionalRoundTasks;
          draft.updateBatchTasksSuccess = `${tasks.length} ${
            tasks.length > 1 ? 'Tasks were' : 'Task was'
          } successfully reassigned`;
          draft.updateBatchTasksLoadingStatus = DataLoadingStatus.LOADED;
        }
        break;
      }
      case TaskV2ActionType.UPDATE_BATCH_TASKS_ERROR: {
        draft.updateBatchTasksLoadingStatus = DataLoadingStatus.ERROR;
        draft.updateBatchTasksError = action.payload.message;
        break;
      }

      case TaskV2ActionType.RESET_REASSIGNMENT_STATE: {
        draft.updateBatchTasksLoadingStatus = DataLoadingStatus.INITIAL;
        draft.updateBatchTasksError = undefined;
        draft.updateBatchTasksSuccess = undefined;
        if (action.removeFailedTasks) {
          draft.tasksFailedToReassign = [];
        }
        break;
      }
      case TaskV2ActionType.UPDATE_DEBUG_LAYOUT: {
        draft.enableDebugLayout = action.payload;
        break;
      }
      case TaskV2ActionType.UPDATE_ACTIVITY_MONITOR_VISIBILITY: {
        draft.showActivityMonitor = action.payload;
        break;
      }
      case TaskV2ActionType.LIST_API_EXECUTION_HISTORY: {
        draft.apiExecutionHistoryLoadingStatus = DataLoadingStatus.LOADING;
        break;
      }
      case TaskV2ActionType.LIST_API_EXECUTION_HISTORY_COMPLETED: {
        if (action.refresh) {
          draft.apiExecutionHistory = action.payload;
        } else {
          draft.apiExecutionHistory.push(...action.payload);
        }
        draft.apiExecutionHistoryTotalSize = action.totalSize;
        draft.apiExecutionHistoryLoadingStatus = DataLoadingStatus.LOADED;
        break;
      }
      case TaskV2ActionType.LIST_API_EXECUTION_HISTORY_ERROR: {
        draft.apiExecutionHistoryLoadingStatus = DataLoadingStatus.ERROR;
        draft.listApiExecutionHistoryError = action.payload;
        break;
      }

      case TaskV2ActionType.LIST_UI_EXECUTION_HISTORY: {
        draft.uiExecutionHistoryLoadingStatus = DataLoadingStatus.LOADING;
        break;
      }
      case TaskV2ActionType.LIST_UI_EXECUTION_HISTORY_COMPLETED: {
        if (action.refresh) {
          draft.uiExecutionHistory = action.payload;
        } else {
          draft.uiExecutionHistory.push(...action.payload);
        }

        draft.uiExecutionHistoryNextPageToken = action.nextPageToken;
        draft.uiExecutionHistoryTotalSize = action.totalSize;
        draft.uiExecutionHistoryLoadingStatus = DataLoadingStatus.LOADED;
        break;
      }
      case TaskV2ActionType.LIST_UI_EXECUTION_HISTORY_ERROR: {
        draft.uiExecutionHistoryLoadingStatus = DataLoadingStatus.ERROR;
        draft.listUiExecutionHistoryError = action.payload;
        break;
      }
      case TaskV2ActionType.RETRY_TASKS: {
        draft.retryTasksLoadingStatus = DataLoadingStatus.LOADING;
        break;
      }
      case TaskV2ActionType.RETRY_TASKS_COMPLETED: {
        const { missedTasks } = action.payload;
        if (missedTasks.length) {
          draft.retryTasksError = missedTasks;
          draft.tasksFailedToDelete = missedTasks;
          draft.retryTasksSuccess = false;
        } else {
          draft.retryTasksSuccess = true;
        }
        draft.retryTasksLoadingStatus = DataLoadingStatus.LOADED;
        break;
      }
      case TaskV2ActionType.RETRY_TASKS_ERROR: {
        draft.retryTasksLoadingStatus = DataLoadingStatus.ERROR;
        draft.retryTasksError = action.payload.message;
        break;
      }
      case TaskV2ActionType.CLEAR_RETRY_TASKS: {
        draft.retryTasksLoadingStatus = DataLoadingStatus.INITIAL;
        draft.tasksFailedToDelete = [];
        draft.retryTasksError = undefined;
        draft.retryTasksSuccess = false;
        break;
      }
      case TaskV2ActionType.GET_BLOCKED_WORKFLOW_EXECUTION_STATISTICS_COMPLETED: {
        const { blockedWorkflowExecutionsCount, blockedWorkflowResourceNames } =
          action.payload;
        draft.blockedWorkflowExecutionsCount = blockedWorkflowExecutionsCount;
        draft.blockedWorkflowResourceNames = blockedWorkflowResourceNames;
        break;
      }
      case TaskV2ActionType.GET_BLOCKED_WORKFLOW_EXECUTION_STATISTICS_ERROR: {
        draft.blockedWorkflowExecutionsCount = 0;
        draft.blockedWorkflowResourceNames = [];
        break;
      }
      case TaskV2ActionType.DELETE_UI_EXECUTION_HISTORY_COMPLETED: {
        draft.deleteUiExecutionHistoryCompleted = action.payload;
        draft.deleteUiExecutionHistoryError = undefined;
        break;
      }
      case TaskV2ActionType.DELETE_UI_EXECUTION_HISTORY_ERROR: {
        draft.deleteUiExecutionHistoryError = action.payload;
        break;
      }
      case TaskV2ActionType.EXPORT_TASKS: {
        draft.exportTasksLoadingStatus = DataLoadingStatus.LOADING;
        break;
      }
      case TaskV2ActionType.EXPORT_TASKS_COMPLETED: {
        draft.exportTasksResponse = action.payload;
        draft.exportTasksLoadingStatus = DataLoadingStatus.LOADED;
        break;
      }
      case TaskV2ActionType.EXPORT_TASKS_ERROR: {
        draft.exportTasksError = action.payload;
        draft.exportTasksLoadingStatus = DataLoadingStatus.ERROR;
        break;
      }
      case TaskV2ActionType.CLEAR_EXPORT_TASKS: {
        delete draft.exportTasksResponse;
        delete draft.exportTasksError;
        draft.exportTasksLoadingStatus = DataLoadingStatus.INITIAL;
        break;
      }
      case TaskV2ActionType.EXPORT_TASKS_DOWNLOAD_PENDING: {
        draft.exportTasksDownloadLoadingStatus = DataLoadingStatus.LOADING;
        break;
      }
      case TaskV2ActionType.EXPORT_TASKS_DOWNLOAD_COMPLETED: {
        draft.exportTasksDownloadLoadingStatus = DataLoadingStatus.LOADED;
        break;
      }
      case TaskV2ActionType.EXPORT_TASKS_DOWNLOAD_RESET_STATE: {
        draft.exportTasksDownloadLoadingStatus = DataLoadingStatus.INITIAL;
        delete draft.exportTasksDownloadError;
        break;
      }
      case TaskV2ActionType.EXPORT_TASKS_DOWNLOAD_ERROR: {
        draft.exportTasksDownloadError = action.payload;
        draft.exportTasksDownloadLoadingStatus = DataLoadingStatus.ERROR;
        break;
      }
      case TaskV2ActionType.DOWNLOAD_TASKS: {
        draft.downloadTasksLoadingStatus = DataLoadingStatus.LOADING;
        break;
      }
      case TaskV2ActionType.DOWNLOAD_TASKS_COMPLETED: {
        draft.downloadTasksData = action.payload;
        draft.downloadTasksLoadingStatus = DataLoadingStatus.LOADED;
        break;
      }
      case TaskV2ActionType.DOWNLOAD_TASKS_ERROR: {
        draft.downloadTasksLoadingStatus = DataLoadingStatus.ERROR;
        draft.downloadTasksError = action.payload;
        break;
      }
      case TaskV2ActionType.CLEAR_DOWNLOAD_TASK_COMPLETED: {
        delete draft.downloadTasksData;
        draft.downloadTasksLoadingStatus = DataLoadingStatus.INITIAL;
        delete draft.downloadTasksError;
        break;
      }
      default:
        break;
    }
  });
