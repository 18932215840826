import {
  GetProcessRequest,
  ListProcessesRequest,
} from 'protos/pb/v1alpha2/process_discovery_service';
import {
  getProcessAction,
  listProcessesAction,
  setGetProcess,
  setGetProcessError,
  setGetProcessLoading,
  setListProcesses,
  setListProcessesError,
  setListProcessesLoading,
} from '../slices/process-discovery.slice';
import { put, call, takeLatest } from 'redux-saga/effects';
import { DataLoadingStatus } from '../../utils/constants';
import { processDiscoveryService } from '../../services/ProcessDiscoveryService';

export function* listProcessesSaga(data: {
  type: typeof listProcessesAction.type;
  payload: ListProcessesRequest;
}): any {
  try {
    yield put(setListProcessesLoading(DataLoadingStatus.LOADING));

    const { response, error } = yield call(
      processDiscoveryService.listProcesses,
      data.payload,
    );
    if (response) {
      yield put(setListProcesses(response));
      yield put(setListProcessesLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setListProcessesError(error?.message || error));
      yield put(setListProcessesLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setListProcessesError(error?.message || error));
    yield put(setListProcessesLoading(DataLoadingStatus.ERROR));
  }
}

export function* getProcessSaga(data: {
  type: typeof getProcessAction.type;
  payload: GetProcessRequest;
}): any {
  try {
    yield put(setListProcessesLoading(DataLoadingStatus.LOADING));

    const { response, error } = yield call(
      processDiscoveryService.getProcess,
      data.payload,
    );
    if (response) {
      yield put(setGetProcess(response));
      yield put(setGetProcessLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setGetProcessError(error?.message || error));
      yield put(setGetProcessLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setGetProcessError(error?.message || error));
    yield put(setGetProcessLoading(DataLoadingStatus.ERROR));
  }
}

export function* processDiscoverySaga() {
  yield takeLatest(listProcessesAction.type, listProcessesSaga);
  yield takeLatest(getProcessAction.type, getProcessSaga);
}
