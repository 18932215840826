import { createSelector } from 'reselect';
import { reviewTaskSelector } from './app.selectors';
import {
  getDeletionStepFromTaskExecutions,
  getSelectedTaskDocument,
} from '../../utils/helpers';
import { TaskSTATUS } from 'protos/pb/v1alpha2/tasks_service';
import { InfoToDetectChanges } from '../../utils/UnsavedChangesUtils';
import {
  checkIfMultipleCellsSelected,
  getEntitiesList,
} from '../../utils/ReviewTaskUtils';
import _ from 'lodash';
import { EntityInfo } from '../reducers/review_task.reducer';
import { DataLoadingStatus } from '../../utils/constants';

export const taskForReviewSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.task,
);

export const taskForReviewLoadingSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) =>
    reviewTaskState.taskLoadingStatus === DataLoadingStatus.LOADING,
);

export const taskForReviewLoadedSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) =>
    reviewTaskState.taskLoadingStatus === DataLoadingStatus.LOADED,
);

export const taskForReviewLoadingErrorSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) =>
    reviewTaskState.taskLoadingStatus === DataLoadingStatus.ERROR
      ? reviewTaskState.taskLoadingError
      : undefined,
);

export const selectedTaskEntityInfoSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.selectedTaskEntityInfo,
);

export const originalTaskEntityInfoSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.originalTaskEntityInfo,
);

export const selectedEntityIdSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.selectedEntityId,
);

export const selectedEntityInfoSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.selectedEntityInfo,
);

export const selectedTextSegmentInfoSelector = createSelector(
  [reviewTaskSelector, selectedEntityInfoSelector],
  (reviewTaskState, entityInfo) => {
    const id = reviewTaskState.selectedTextSegmentId;
    if (id) {
      return entityInfo?.textSegments[id];
    }
    return undefined;
  },
);

export const selectedTextSegmentIdSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.selectedTextSegmentId,
);

export const tokensInDocumentSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.tokensInDocument,
);

export const getDocumentActivityTypeSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.documentActivityType,
);

export const getClassificationLabelsListSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.classificationLabelsList,
);

export const getPredictedResultSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.predictionResult,
);

export const getOriginalPredictedResultSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => {
    const documentStep = getSelectedTaskDocument(reviewTaskState.task);
    return documentStep?.predictionResult;
  },
);

export const getAutomationProgressSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.automationProgressStatus,
);

export const getAutomationCompletedSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.isAutomationCompleted,
);

export const getAutomationCompletedErrorSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.automationError,
);

export const getSelectedReviewFilterSectionSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.selectedReviewFilterSection,
);

export const searchTextSelector = createSelector(
  [reviewTaskSelector],
  (taskState) => taskState.searchText,
);

export const searchedTokensInDocumentSelector = createSelector(
  [reviewTaskSelector],
  (taskState) => taskState.searchedTokensInDocument,
);

export const searchedTokensStartIndicesSelector = createSelector(
  [reviewTaskSelector],
  (taskState) => taskState.searchedTokensStartIndices,
);

export const selectedTokenIndexSelector = createSelector(
  [reviewTaskSelector],
  (taskState) => taskState.selectedTokenIndex,
);

export const tokenForHighlightSelector = createSelector(
  [reviewTaskSelector],
  (taskState) => taskState.tokenForHighlight,
);

export const selectedParentEntityInfoSelector = createSelector(
  [reviewTaskSelector],
  (taskState) => taskState.selectedParentEntityInfo,
);

export const selectedTableEntitiesInfoSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.selectedTableEntitiesInfo,
);

// TODO: Refactor this code base to use Map version for selectedTableEntitiesInfo instead of array version
export const selectedTableEntitiesInfoMapSelector = createSelector(
  [selectedTableEntitiesInfoSelector],
  (selectedTableEntitiesInfo) => _.keyBy(selectedTableEntitiesInfo, 'id'),
);

export const suggestedTableEntitiesInfoSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.suggestedTableEntitiesInfo,
);

export const selectedEntityIdsForAnnotationSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.selectedEntityIdsForAnnotation,
);

// TODO: Refactor this code base to use set version for selectedEntityIdsForAnnotation instead of array version
export const selectedEntityIdsForAnnotationSetSelector = createSelector(
  [selectedEntityIdsForAnnotationSelector],
  (selectedIds) => new Set([...selectedIds]),
);

export const isMultipleCellsSelectedSelector = createSelector(
  [
    selectedEntityIdsForAnnotationSelector,
    selectedTableEntitiesInfoMapSelector,
  ],
  (selectedEntityIdsForAnnotation, selectedTableEntitiesInfoMap) =>
    checkIfMultipleCellsSelected(
      selectedEntityIdsForAnnotation,
      selectedTableEntitiesInfoMap,
    ),
);

export const addLocationSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.addLocation,
);

export const lastLocatedEntityTypeSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.lastLocatedEntityType,
);

export const columnOrderInfoForTableEntitiesSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.columnOrderInfoForTableEntities,
);

export const hiddenEntityTypesForTableAnnotationSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.hiddenEntityTypesForTableAnnotation,
);

// this state contains all info necessary to detect unsaved changes
export const infoToDetectChangesSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState): InfoToDetectChanges => {
    return {
      modifiedEntityInfoMap: reviewTaskState.selectedTaskEntityInfo,
      originalEntityInfoMap: reviewTaskState.originalTaskEntityInfo,
      selectedEntityInfo: reviewTaskState.selectedEntityInfo,
      selectedTableChildEntities: reviewTaskState.selectedTableEntitiesInfo,
      selectedParentEntityInfo: reviewTaskState.selectedParentEntityInfo,
    };
  },
);

export const tokenListToHighlightSelector = createSelector(
  [reviewTaskSelector],
  (taskState) => taskState.tokenListToHighlight,
);

export const tokenListPageToHighlightSelector = createSelector(
  [reviewTaskSelector],
  (taskState) => taskState.tokenListPageToHighlight,
);

export const rowOrderInfoForTableEntitiesSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.rowOrderInfoForTableEntities,
);

export const rowOrderInfoForSelectedParentEntitySelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) =>
    reviewTaskState.rowOrderInfoForTableEntities[
      reviewTaskState.selectedParentEntityInfo?.type || ''
    ] || [],
);

export const entitySidePanelCollapsedSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.isEntitySidePanelCollapsed,
);

export const openAddRowModalSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.openAddRowModal,
);

export const suggestionDataSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.suggestionData,
);

export const allowEditingTaskSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.allowEditingTask,
);

export const hitlDataUpdatingSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) =>
    reviewTaskState.hitlDataUpdatingStatus === DataLoadingStatus.LOADING,
);

export const hitlDataUpdatedSuccessSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) =>
    reviewTaskState.hitlDataUpdatingStatus === DataLoadingStatus.LOADED,
);

export const hitlDataUpdatedErrorSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) =>
    reviewTaskState.hitlDataUpdatingStatus === DataLoadingStatus.ERROR
      ? reviewTaskState.hitlDataUpdatingError
      : undefined,
);

export const visibleColumnsSelector = createSelector(
  [
    columnOrderInfoForTableEntitiesSelector,
    selectedParentEntityInfoSelector,
    hiddenEntityTypesForTableAnnotationSelector,
  ],
  (
    columnOrderInfoForTableEntities,
    selectedParentEntityInfo,
    hiddenEntityTypesForTableAnnotation,
  ) => {
    const columns =
      columnOrderInfoForTableEntities[selectedParentEntityInfo?.type ?? ''];
    const hiddenColumn =
      hiddenEntityTypesForTableAnnotation[
        selectedParentEntityInfo?.type ?? ''
      ] ?? [];
    // TODO: Refactor to only return type if not needed
    const visibleColumns = columns?.filter(
      (c) => !hiddenColumn.includes(c.type),
    );
    return visibleColumns;
  },
);

// Selector to create a map of table entities by their parentEntityId (rowId) and type (columnId)
// This is useful for quickly accessing entities based on their parent and type, improving lookup efficiency.
export const tableMapSelector = createSelector(
  [selectedTableEntitiesInfoSelector],
  (tableEntities) => {
    const map = tableEntities.reduce(
      (obj: { [key: string]: EntityInfo }, e) => {
        // rowId-columnId
        obj[`${e.parentEntityId}-${e.type}`] = e;
        return obj;
      },
      {},
    );
    return map;
  },
);

// Selector to group entities by their column type
export const entitiesByColumnSelector = createSelector(
  [selectedTableEntitiesInfoSelector],
  (tableEntities) => {
    const entitiesByColumn = new Map<string, string[]>();

    return tableEntities.reduce((acc, entity) => {
      if (!acc.has(entity.type)) {
        acc.set(entity.type, []);
      }
      acc.get(entity.type)!.push(entity.id);
      return acc;
    }, entitiesByColumn);
  },
);

export const idleSessionsSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.idleSessions,
);

export const reviewStartTimeSelector = createSelector(
  [reviewTaskSelector],
  (taskState) => taskState.reviewStartTime,
);

export const areOCRResultsAvailableSelector = createSelector(
  [reviewTaskSelector],
  ({ task }) => {
    return (
      task?.status === TaskSTATUS.READY ||
      !getDeletionStepFromTaskExecutions(task)
    );
  },
);

export const sidebarEntitiesSelector = createSelector(
  [selectedTaskEntityInfoSelector, originalTaskEntityInfoSelector],
  (selectedTaskEntityInfo, originalTaskEntityInfo) =>
    getEntitiesList(selectedTaskEntityInfo, originalTaskEntityInfo),
);

export const saveTaskStatusSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.saveTaskStatus,
);

export const saveTaskErrorSelector = createSelector(
  [reviewTaskSelector],
  (reviewTaskState) => reviewTaskState.saveTaskError,
);
