import {
  Document,
  DocumentPage,
  DocumentPageToken,
} from 'protos/google/cloud/documentai/v1/document';
import { Vertex } from 'protos/google/cloud/documentai/v1/geometry';
import {
  FOCUSED_TOKEN_ID,
  PADDING_BW_PDF_PAGES,
  PDF_PANEL_ID,
  PDF_PANEL_WRAPPER_ID,
} from './constants';
import { boxPositionValuesUtilV2 } from './BoxPositionValuesUtilV2';

/**
 * Makes HTML element draggable
 *
 * @param elem - The HTML element to make draggable.
 * @param updateCoordinates - A callback function to update the coordinates of the element.
 *   This function is called during dragging with the current computed styles and a flag indicating
 *   whether the dragging operation has finished.
 */
// TODO: Merge this with makeDraggableCard
export const makeDraggableDiv = (
  elem: HTMLElement,
  updateCoordinates: (styles: any, finishAnnotation?: boolean) => void,
) => {
  elem.style.position = 'absolute';
  let initX: number,
    initY: number,
    firstX: number,
    firstY: number,
    whichDown: HTMLElement;

  const mouseDownHandler = function (e: MouseEvent) {
    e.preventDefault();
    whichDown = elem; // Set the element being dragged
    initX = elem.offsetLeft; // Store the initial X position of the element
    initY = elem.offsetTop; // Store the initial Y position of the element
    firstX = e.pageX; // Store the initial X position of the mouse
    firstY = e.pageY; // Store the initial Y position of the mouse
    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  const mouseMoveHandler = function (e: MouseEvent) {
    e.stopPropagation();
    const styles = window.getComputedStyle(elem);
    // Calculate and update the new position of the element based on mouse movement
    whichDown.style.left = initX + (e.pageX - firstX) + 'px';
    whichDown.style.top = initY + (e.pageY - firstY) + 'px';
    updateCoordinates(styles, true);
  };

  const mouseUpHandler = function () {
    // Remove event listeners as dragging has stopped
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  };

  elem.addEventListener('mousedown', mouseDownHandler);
};

/**
 * Makes an HTML element draggable within a bounded area.
 *
 * @param elem - The HTML element to make draggable.
 * @param getLeftMax - A function that returns the maximum left position (boundary) the element can be dragged to.
 * @param getTopMax - A function that returns the maximum top position (boundary) the element can be dragged to.
 * @param draggableElem - The HTML element that will trigger the drag operation when clicked. It can be null.
 */
export const makeDraggableCard = (
  elem: HTMLElement,
  getLeftMax: () => number,
  getTopMax: () => number,
  draggableElem: HTMLElement | null,
) => {
  elem.style.position = 'absolute';
  let initX: number,
    initY: number,
    firstX: number,
    firstY: number,
    leftMax: number,
    topMax: number;

  const mouseMoveHandler = (e: MouseEvent) => {
    e.stopPropagation();

    // Calculate the new position of the element based on mouse movement
    const newLeft = initX + (e.pageX - firstX);
    const newTop = initY + (e.pageY - firstY);

    // Ensure the new position is within the allowed boundaries
    if (newLeft < 0) {
      elem.style.left = '0px'; // Limit to left boundary
    } else if (newLeft > leftMax) {
      elem.style.left = leftMax + 'px'; // Limit to right boundary
    } else {
      elem.style.left = newLeft + 'px'; // Set new left position
    }

    if (newTop < 0) {
      elem.style.top = '0px'; // Limit to top boundary
    } else if (newTop > topMax) {
      elem.style.top = topMax + 'px'; // Limit to bottom boundary
    } else {
      elem.style.top = newTop + 'px'; // Set new top position
    }
  };

  const mouseUpHandler = () => {
    // Remove event listeners as dragging has stopped
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);
  };

  const mouseDownHandler = (e: MouseEvent) => {
    // Retrieve the boundary limits for dragging
    leftMax = getLeftMax();
    topMax = getTopMax();
    initX = elem.offsetLeft; // Store the initial X position of the element
    initY = elem.offsetTop; // Store the initial Y position of the element
    firstX = e.pageX; // Store the initial X position of the mouse
    firstY = e.pageY; // Store the initial Y position of the mouse
    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  draggableElem?.addEventListener('mousedown', mouseDownHandler);
};

/**
 * Makes an HTML element resizable by adding event listeners to its resizer handles.
 *
 * @param element - The HTML element to make resizable. It should have resizer handles as child elements with the class 'resizer'.
 * @param updateCoordinates - A callback function to update the element's coordinates and dimensions.
 *   This function is called during resizing with the current computed styles and a flag indicating whether resizing is complete.
 * @param minimumWidth - The minimum width the element can be resized to. Default is 3 pixels.
 * @param minimumHeight - The minimum height the element can be resized to. Default is 3 pixels.
 * @param setIsMinWidthReached - An optional callback function to set a flag indicating whether the minimum width has been reached.
 *   This can be used to handle specific behaviors when the element is resized to its minimum width.
 * @param getTopMax - A function that returns the maximum top position (boundary) the element can be resize to.
 * @param getLeftMax - A function that returns the maximum left position (boundary) the element can be resize to.

 */
export const makeResizableDiv = (
  element: HTMLElement,
  updateCoordinates: (styles: any, finishAnnotation?: boolean) => void,
  minimumWidth = 3,
  minimumHeight = 3,
  setIsMinWidthReached?: (x: boolean) => void,
  getTopMax?: () => number,
  getLeftMax?: () => number,
) => {
  const resizers = element.getElementsByClassName('resizer');
  let originalWidth = 0;
  let originalHeight = 0;
  let originalX = 0;
  let originalY = 0;
  let originalMouseX = 0;
  let originalMouseY = 0;
  let leftMax: number, topMax: number;

  for (let i = 0; i < resizers.length; i++) {
    const currentResizer = resizers[i];
    // Mouse down event handler to initialize resizing.
    currentResizer.addEventListener('mousedown', function (e: any) {
      e.stopPropagation();
      // Store the original dimensions and positions
      originalWidth = parseFloat(
        getComputedStyle(element, null)
          .getPropertyValue('width')
          .replace('px', ''),
      );
      originalHeight = parseFloat(
        getComputedStyle(element, null)
          .getPropertyValue('height')
          .replace('px', ''),
      );
      const styles = window.getComputedStyle(element);
      originalX = parseInt(styles.left);
      originalY = parseInt(styles.top);
      originalMouseX = e.pageX;
      originalMouseY = e.pageY;
      leftMax = getLeftMax?.() ?? 0;
      topMax = getTopMax?.() ?? 0;
      window.addEventListener('mousemove', resize);
      window.addEventListener('mouseup', stopResize);
    });

    const setHeightFromTop = (e: MouseEvent) => {
      const height = originalHeight - (e.pageY - originalMouseY);
      const newTop = Math.max(originalY + (e.pageY - originalMouseY), 0);

      // Check if the new height is above the minimum height and
      // if the new top position is within allowed bounds (if topMax is defined)
      if (height > minimumHeight && (!topMax || newTop <= topMax)) {
        if (element.style.top !== '0px') {
          element.style.height = height + 'px';
        }
        element.style.top = newTop + 'px';
      }
    };

    const setHeightFromBottom = (e: MouseEvent) => {
      const height = originalHeight + (e.pageY - originalMouseY);
      if (height > minimumHeight) {
        element.style.height = height + 'px';
      }
    };

    const setWidthFromRight = (e: MouseEvent) => {
      const width = originalWidth + (e.pageX - originalMouseX);
      if (width > minimumWidth) {
        element.style.width = width + 'px';
      }
    };

    const setWidthFromLeft = (e: MouseEvent) => {
      const width = originalWidth - (e.pageX - originalMouseX);
      const newLeft = Math.max(originalX + (e.pageX - originalMouseX), 0);

      // Check if the new width is greater than the minimum width and
      // if the new left position is within the allowed bounds (if leftMax is defined)
      if (width > minimumWidth && (!leftMax || newLeft <= leftMax)) {
        if (element.style.left !== '0px') {
          element.style.width = width + 'px';
        }

        element.style.left = newLeft + 'px';
      }
    };

    const resize = (e: MouseEvent) => {
      const resizeHandlers: Record<string, () => void> = {
        // Edges
        'bottom-right': () => {
          setHeightFromBottom(e);
          setWidthFromRight(e);
        },
        'bottom-left': () => {
          setHeightFromBottom(e);
          setWidthFromLeft(e);
        },
        'top-right': () => {
          setHeightFromTop(e);
          setWidthFromRight(e);
        },
        'top-left': () => {
          setHeightFromTop(e);
          setWidthFromLeft(e);
        },
        // vertices
        top: () => setHeightFromTop(e),
        right: () => setWidthFromRight(e),
        bottom: () => setHeightFromBottom(e),
        left: () => setWidthFromLeft(e),
      };

      // Determine which resize handler to call based on the class of the resizer.
      const resizerClasses = Array.from(currentResizer.classList);
      for (const className of resizerClasses) {
        if (resizeHandlers[className]) {
          resizeHandlers[className]();
          break;
        }
      }
      // This is to change the layout of modal before we reach to the minimum width. 100px is buffer for that
      if (element.clientWidth > minimumWidth + 100) {
        setIsMinWidthReached?.(false);
      } else {
        setIsMinWidthReached?.(true);
      }
      const styles = window.getComputedStyle(element);
      updateCoordinates(styles, true);
    };

    const stopResize = () => {
      window.removeEventListener('mousemove', resize);
    };
  }
};

//
/**
 * THIS IS TO ADD A CLICK LISTENER OVER PDF SO WHEN THE USER CLICKS, WE CAN SHIFT THE BOUNDING BOX TO THAT POSITION.
 *
 * @param elem - The element whose bounding box is to be adjusted.
 * @param updateCoordinates - A callback function to update the element's coordinates. Receives the computed styles of the element.
 * @param heightToRemove - The height to subtract from the new Y-coordinate when adjusting the element's position.
 */
export const makeDivDraggableOnClick = (
  elem: any,
  updateCoordinates: (styles: any) => void,
  heightToRemove: number,
) => {
  let clientX: number, clientY: number;
  const pdfPanel = document.getElementById(PDF_PANEL_ID);

  if (!pdfPanel) return;

  const mouseClickHandler = (e: MouseEvent) => {
    const styles = window.getComputedStyle(elem);
    const pdfPanelRect = pdfPanel.getBoundingClientRect();
    const focusedToken = document.getElementById(FOCUSED_TOKEN_ID);

    if (focusedToken) {
      const parentOffsetTop = focusedToken.parentElement?.offsetTop || 0;
      const focusedTokenStyles = window.getComputedStyle(focusedToken);
      const newTop =
        focusedToken.offsetTop + parentOffsetTop - heightToRemove + 'px';

      elem.style.left = focusedTokenStyles.left;
      elem.style.top = newTop;
      elem.style.width = focusedTokenStyles.width;
      elem.style.height = focusedTokenStyles.height;
    } else {
      // Calculate the new position relative to the pdfPannel
      const newX = e.clientX - pdfPanelRect.left - elem.offsetWidth / 2;
      const newY = e.clientY - pdfPanelRect.top - elem.offsetHeight / 2;
      // Update the element's position
      elem.style.left = newX + 'px';
      elem.style.top = newY - heightToRemove + 'px';
    }
    updateCoordinates(styles);
  };

  // Added two event listeners mousedown and mouseup to achieve click listener functionality
  // Didn't add click event because it happens to affect drag and resizing functionalities of the bounding box.
  // Using these 2 event listeners first I stored position of the mouse down location and then when mouse
  // up event is triggered, the function checks if the position of the mouse up location is exactly same
  // as of mousedown. If the condition is satisfied, we assume its a click and hence update bounding box position
  // to that same position
  const mouseDownHandler = (e: MouseEvent) => {
    clientX = e.clientX;
    clientY = e.clientY;
  };

  const mouseUpHandler = (e: MouseEvent) => {
    if (clientX === e.clientX && clientY === e.clientY) {
      mouseClickHandler(e);
    }
  };

  // Added mouseup and mousedown event
  pdfPanel.addEventListener('mousedown', mouseDownHandler);
  pdfPanel.addEventListener('mouseup', mouseUpHandler);
};

export const doesStyleHaveValues = (styles: CSSStyleDeclaration) => {
  return Object.values(styles).find((val) => val !== '');
};

// Function to get the current page index of the bounding box on which it lies
// e.g. if it was initially on page index 2 of height 1000px at yCoord 700px and it was moved 500px downward,
// then the new page index we will get from this function will be page index 3
// Requires all pages present in the document, y coordinate of the bounding box,
// padding between the pages for precise calculation and the currentPage of the bounding box
export const getPdfPageIndexForBoundingbox = (
  pages: DocumentPage[],
  yCoord: number,
  padding: number,
  currentPage: number,
): number => {
  // This is the case where user drags the bounding box to the previous page from its current page
  // This will return the top of the bounding box in negative since we store the y coordinate respective to
  // the page where it originally belongs to
  if (yCoord < 0) {
    let dummyYCoord = yCoord;
    // This will return the pages present in the doc before the current page
    const subArray = pages.slice(0, currentPage);
    // Loop over the pages e.g. if there are 5 pages ['1', '2', '3', '4', '5'] and current page is '3'
    // The subArray will be ['1', '2'] and it will iterate in reverse i.e. '2' and then '1'
    for (let index = subArray.length - 1; index >= 0; index--) {
      // First we will add height along with the padding to negative coordinates
      dummyYCoord += subArray[index].image!.height! + padding;
      // Check whether the resulted coordinate is positive means the box lies on this page
      if (dummyYCoord >= 0) {
        return index;
      }
    }
  } else {
    // If user drags the bounding box to the pages after the current page
    let totalPagesHeight = 0;
    // Loop over the pages e.g. if there are 5 pages ['1', '2', '3', '4', '5'] and current page is '3'
    // The iteration will be over ['3', '4', '5']
    for (let index = currentPage; index < pages.length; index++) {
      const page = pages[index];
      // Here the height of each page gets added along with the padding until it surpasses/gets equal
      // to the yCoordinate that we get after scrolling the box
      totalPagesHeight += page.image!.height! + padding;
      // If height exceeds or becomes equal to the yCoordinate, then the page will be the current index
      // of the iteration
      if (yCoord <= totalPagesHeight) {
        return index;
      }
    }
  }
  return 0;
};

// Function to get the y Coordinate of the bounding box as per the page it lies on
// e.g. if it was initially on page 2 of height 1000px at yCoord 700px and it was moved 500px downward,
// then the new y Coord we will get from this function will be 200px from page 3
// Requires all pages present in the document, y coordinate of the bounding box from the initial page
// before box was moved, padding between the pages for precise calculation and the currentPage of the bounding box
export const getYCoordForCurrentPage = (
  pages: DocumentPage[],
  yCoord: number,
  padding: number,
  currentPage: number,
): number => {
  // This is the case where user drags the bounding box to the previous page from its current page
  // This will return the top of the bounding box in negative since we store the y coordinate respective to
  // the page where it originally belongs to
  if (yCoord < 0) {
    let dummyYCoord = yCoord;
    // This will return the pages present in the doc before the current page
    const subArray = pages.slice(0, currentPage);
    // Loop over the pages e.g. if there are 5 pages ['1', '2', '3', '4', '5'] and current page is '3'
    // The subArray will be ['1', '2'] and it will iterate in reverse i.e. '2' and then '1'
    for (let index = subArray.length - 1; index >= 0; index--) {
      // First we will add height along with the padding to negative coordinates
      dummyYCoord += subArray[index].image!.height! + padding;
      // Check whether the resulted coordinate is positive means the box lies on this page
      // so returning the coordinates that remains
      if (dummyYCoord >= 0) {
        return dummyYCoord;
      }
    }
  } else {
    // If user drags the bounding box to the pages after the current page
    let dummyYCoord = yCoord;
    // Loop over the pages e.g. if there are 5 pages ['1', '2', '3', '4', '5'] and current page is '3'
    // The iteration will be over ['3', '4', '5']
    for (let index = currentPage; index < pages.length; index++) {
      const page = pages[index];
      // If the y Coordinate is withing the page height then we return the coordinates as it is
      if (dummyYCoord < page.image!.height!) {
        return dummyYCoord;
      } else {
        // Otherwuse we subtracts the height of that page along with the padding from the coordinates and
        // checks for next page in the next iteration until if condition is met
        dummyYCoord -= page.image!.height! + padding;
      }
    }
  }
  return 0;
};

/**
 * Adds a mouse move listener to the PDF panel element to update the token based on cursor position.
 *
 * @param scale - The scale factor applied to the PDF view.
 * @param selectedTaskDocument - The document associated with the selected task, used to retrieve tokens and other details.
 * @param tokensInDocument - A dictionary where the keys are page numbers and the values are arrays of `DocumentPageToken` objects representing tokens on each page.
 * @param setToken - Callback function to update the currently selected token based on the cursor position.
 */
export const addMouseMoveListener = (
  scale: number,
  selectedTaskDocument: Document,
  tokensInDocument: {
    [page: number]: DocumentPageToken[];
  },
  setToken: (token?: DocumentPageToken) => void,
) => {
  const pdfPanel = document.getElementById(PDF_PANEL_ID);

  if (!pdfPanel) return;

  const mouseHoverHandler = (e: MouseEvent) => {
    const pdfPanelRect = pdfPanel.getBoundingClientRect();
    const newX = e.clientX - pdfPanelRect.left;
    const newY = e.clientY - pdfPanelRect.top;
    updateCursorCoordinates(
      { x: newX, y: newY },
      scale,
      selectedTaskDocument,
      tokensInDocument,
      setToken,
    );
  };

  pdfPanel.addEventListener('mousemove', mouseHoverHandler);
};

// This function was added to enable token highlight search using binary search which is still WIP
// export const updateCursorCoordinatesWithBinarySearch = (
//   coord: Vertex,
//   scale: number,
//   selectedTaskDocument: Document,
//   tokensInDocument: {
//     [page: number]: DocumentPageToken[]
//   },
//   setToken: (token: DocumentPageToken | undefined) => void
// ) => {
//   const padding = PADDING_BW_PDF_PAGES / scale
//   const y = getYCoordForCurrentPage(
//     selectedTaskDocument.pages,
//     coord.y / scale,
//     padding,
//     0
//   )
//   const page = getPdfPageIndexForBoundingbox(
//     selectedTaskDocument.pages,
//     coord.y / scale,
//     padding,
//     0
//   )
//   const pageTokens: DocumentPageToken[] = tokensInDocument[page]
//   let left = 0
//   let right = pageTokens.length - 1
//   while (left <= right) {
//     const mid = Math.floor((left + right) / 2)
//     const midToken = pageTokens[mid]
//     const vertices = boxPositionValuesUtilV2.getAdjustedScaleVertices(
//       midToken.layout?.boundingPoly?.vertices || [],
//       scale
//     )
//     const bottomRightVertex =
//       boxPositionValuesUtilV2.getBottomRightCornerVertices(vertices)
//     const topLeftVertex =
//       boxPositionValuesUtilV2.getTopLeftCornerVertices(vertices)

//     const adjustedY = y * scale

//     if (adjustedY >= topLeftVertex.y && adjustedY <= bottomRightVertex.y) {
//       // The potential token is on the same line
//       if (coord.x >= topLeftVertex.x && coord.x <= bottomRightVertex.x) {
//         setToken(midToken)
//         break
//       } else if (coord.x > bottomRightVertex.x) {
//         left = mid + 1
//       } else {
//         right = mid - 1
//       }
//     } else if (adjustedY > bottomRightVertex.y) {
//       left = mid + 1
//     } else {
//       right = mid - 1
//     }
//     setToken(undefined)
//   }
// }

// Helper function to calculate bounding box area
const calculateTokenArea = (
  token: DocumentPageToken,
  scale: number,
): number => {
  const vertices = boxPositionValuesUtilV2.getAdjustedScaleVertices(
    token.layout?.boundingPoly?.vertices || [],
    scale,
  );
  const coords = boxPositionValuesUtilV2.getBoxPositionValues(vertices, scale);
  return coords.width * coords.height;
};

export const updateCursorCoordinates = (
  coord: Vertex,
  scale: number,
  selectedTaskDocument: Document,
  tokensInDocument: {
    [page: number]: DocumentPageToken[];
  },
  setToken: (token?: DocumentPageToken) => void,
) => {
  const padding = PADDING_BW_PDF_PAGES / scale;
  const y = getYCoordForCurrentPage(
    selectedTaskDocument.pages!,
    coord.y! / scale,
    padding,
    0,
  );
  const page = getPdfPageIndexForBoundingbox(
    selectedTaskDocument.pages!,
    coord.y! / scale,
    padding,
    0,
  );

  const pageTokens: DocumentPageToken[] = tokensInDocument[page];
  let smallestMatchToken: DocumentPageToken | undefined;
  let smallestArea = Infinity;

  //  Finds the smallest possible token that contains the cursor position. This is particularly
  //  useful for PDFs with overlapping elements (like text within watermarks) where multiple
  //  tokens may occupy the same space. By prioritizing the smallest matching token,
  //  we achieve better precision in element selection.
  pageTokens.forEach((t) => {
    const vertices = boxPositionValuesUtilV2.getAdjustedScaleVertices(
      t.layout?.boundingPoly?.vertices || [],
      scale,
    );
    const bottomRightVertex =
      boxPositionValuesUtilV2.getBottomRightCornerVertices(vertices);
    const topLeftVertex =
      boxPositionValuesUtilV2.getTopLeftCornerVertices(vertices);

    if (coord.x! > bottomRightVertex.x! || y * scale > bottomRightVertex.y!) {
      return;
    } else if (coord.x! < topLeftVertex.x! || y * scale < topLeftVertex.y!) {
      return;
    } else {
      const area = calculateTokenArea(t, scale);
      if (area < smallestArea) {
        smallestArea = area;
        smallestMatchToken = t;
      }
    }
  });
  setToken(smallestMatchToken);
};

// This function enables the creation of a resizable box within a PDF panel.
// It allows users to click and drag to resize the box, updating its position
// and dimensions dynamically. The function also provides a callback to update
// coordinates upon resizing.
export const makeClickResizableBox = (
  elem: HTMLElement,
  updateCoordinates: (
    styles: CSSStyleDeclaration,
    finishAnnotation: boolean,
  ) => void,
  heightToRemove: number,
  isMultipleCellsSelected: boolean,
) => {
  let clientX: number, clientY: number;
  const pdfPanel = document.getElementById(PDF_PANEL_WRAPPER_ID) as HTMLElement;
  let startX: number, startY: number;
  // Function to handle mouse movement during resizing
  const mouseMoveHandler = (e: MouseEvent) => {
    const styles = window.getComputedStyle(elem);
    const pdfPanelRect = pdfPanel.getBoundingClientRect();

    // Calculate new mouse coordinates adjusted for PDF panel position and scrolling
    const newX = e.pageX - pdfPanelRect.left + pdfPanel.scrollLeft;
    const newY = e.pageY - pdfPanelRect.top + pdfPanel.scrollTop;

    // Calculate box dimensions based on initial and new mouse coordinates
    const boxLeft = Math.min(startX, newX);
    const boxTop = Math.min(startY, newY) - heightToRemove;
    const boxWidth = Math.abs(newX - startX);
    const boxHeight = Math.abs(newY - startY);

    // Update the position and size of the resizable box
    elem.style.left = boxLeft + 'px';
    elem.style.top = boxTop + 'px';
    elem.style.width = boxWidth + 'px';
    elem.style.height = boxHeight + 'px';

    // Update coordinates (callback function)
    updateCoordinates(styles, false);
  };

  // Function to handle mouse down event
  const mouseDownHandler = (e: MouseEvent) => {
    const pdfPanelRect = pdfPanel.getBoundingClientRect();
    clientX = e.clientX;
    clientY = e.clientY;
    // Calculate initial mouse coordinates adjusted for PDF panel position and scrolling
    startX = e.pageX - pdfPanelRect.left + pdfPanel.scrollLeft;
    startY = e.pageY - pdfPanelRect.top + pdfPanel.scrollTop;

    if (isMultipleCellsSelected) {
      // Add event listeners for mouse movement when isMultipleCellsSelected true
      pdfPanel.addEventListener('mousemove', mouseMoveHandler);
    }
    pdfPanel.addEventListener('mouseup', mouseUpHandler);

    // Prevent text selection while dragging
    e.preventDefault();
  };

  // Function to handle mouse up event
  const mouseUpHandler = (e: MouseEvent) => {
    const styles = window.getComputedStyle(elem);

    // Execute code when isMultipleCellsSelected false
    if (!isMultipleCellsSelected) {
      const pdfPanelRect = pdfPanel.getBoundingClientRect();
      // Calculate initial mouse coordinates adjusted for PDF panel position and scrolling
      const newX = e.pageX - pdfPanelRect.left + pdfPanel.scrollLeft;
      const newY = e.pageY - pdfPanelRect.top + pdfPanel.scrollTop;
      const focusedToken = document.getElementById(FOCUSED_TOKEN_ID);
      // Check if the focused token exists and the mouse coordinates haven't changed (indicating a click)
      if (focusedToken && newX === startX && newY === startY) {
        // Calculate the offset top of the parent element of the focused token, or default to 0 if not available
        const parentOffsetTop = focusedToken.parentElement?.offsetTop || 0;
        // Get the computed styles of the focused token
        const focusedTokenStyles = window.getComputedStyle(focusedToken);
        // Calculate the new top position for the box
        const newTop =
          focusedToken.offsetTop + parentOffsetTop - heightToRemove + 'px';
        // Set the position and dimensions of the box to match the focused token
        elem.style.left = focusedTokenStyles.left;
        elem.style.top = newTop;
        elem.style.width = focusedTokenStyles.width;
        elem.style.height = focusedTokenStyles.height;
        updateCoordinates(styles, true);
      }
    } else {
      pdfPanel.removeEventListener('mousemove', mouseMoveHandler);
      // This check stops update if the mouse up and mouse down is on same point because
      // no box must be drawn in this case which will empty the annotation
      if (clientX !== e.clientX || clientY !== e.clientY) {
        updateCoordinates(styles, true);
      }
    }
  };

  // Function to remove event listeners and reset mouse events
  const removeListner = (e: MouseEvent) => {
    mouseUpHandler(e);
    pdfPanel.removeEventListener('mousedown', mouseDownHandler);
  };

  // Add event listener to stop resizing when start dragging bbox
  elem.addEventListener('mousedown', removeListner);
  // Add event listener to resume resizing when dragging bbox stopped
  elem.addEventListener('mouseup', () =>
    pdfPanel.addEventListener('mousedown', mouseDownHandler),
  );
  // Add initial event listener to start resizing when the mouse is pressed
  pdfPanel.addEventListener('mousedown', mouseDownHandler);
};
