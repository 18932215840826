import React from 'react';
import {
  Box,
  InputLabel,
  TextField,
  InputAdornment,
  SxProps,
  Theme,
  TextFieldProps,
} from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import OrbyColorPalette from '../colors/ColorPalette';
import '../../styles/global.css';
import OrbyTypography from '../typography/OrbyTypography';
import { getFontSize } from '../typography/typography-utils';
export interface OrbyTextFieldProps extends Pick<TextFieldProps, 'rows'> {
  /**
   * Name attribute of the input element.
   */
  name?: string;
  /**
   * If true, the component is disabled.
   * @default -
   */
  disabled?: boolean;
  /**
   * Placeholder for input
   * @default false
   */
  placeholder?: string;
  /**
   * The label for the input.
   */
  label?: string;
  /**
   * The value for the input (If input is controlled).
   */
  value?: string;
  /**
   * @default text
   *  Type of input.
   */
  type?: 'text' | 'password' | 'number' | 'email';
  /**
   *  Input Change Handler.
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  /**
   *  Input Blur Handler.
   */
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  /**
   * On Key Down
   */
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  /**
   * On Click Event
   */
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  /**
   *  Error Message to display.
   */
  error?: string;
  /**
   *  React node like Icon, img, text, symbol .
   */
  startAdornment?: React.ReactNode;
  /**
   *  React node like Icon, img, text, symbol .
   */
  endAdornment?: React.ReactNode;
  /**
   *  Additional Helper Message to display
   */
  helperText?: string;
  /**
   * The tabIndex attribute of the component.
   */
  tabIndex?: number;
  /**
   * Width of the component
   */
  width?: string;
  /**
   * Variant of input
   */
  variant?: 'standard' | 'flat';
  /**
   * Font Size
   */
  fontSize?: string;
  /*
   * The custom styles for the outermost container
   */
  containerSx?: SxProps<Theme>;
  /**
   * Makes the readonly
   */
  readOnly?: boolean;
  /**
   * Makes the form fills faster
   */
  autoComplete?: string;
  /**
   * The custom styles for the the input
   */
  inputStyle?: React.CSSProperties;
  /**
   * If you want to show text in multiple lines
   */
  multiline?: boolean;
  /**
   * If you want to show Error text
   */
  showErrorText?: boolean;
  /**
   * Ref object
   */
  containerRef?: React.Ref<unknown>;
  /**
   * Style for input
   */
  inputSx?: SxProps<Theme>;
  /**
   * Autofocus the input element
   */
  autoFocus?: boolean;
  /**
   * On Focus Event
   */
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  /**
   * On Mouse Enter event
   */
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  /**
   * On Mouse Leave event
   */
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
  /**
   * Ref for input
   */
  inputRef?: React.Ref<HTMLInputElement>;
}

const OrbyTextField: React.FC<OrbyTextFieldProps> = ({
  disabled = false,
  placeholder,
  label,
  name,
  type = 'text',
  onChange,
  onBlur,
  onKeyDown,
  onClick,
  error,
  startAdornment,
  value,
  helperText,
  width = '320px',
  variant = 'standard',
  fontSize = '16px',
  containerSx,
  readOnly = false,
  endAdornment,
  autoComplete,
  inputStyle,
  multiline = false,
  showErrorText = true,
  containerRef,
  inputSx,
  rows,
  autoFocus = false,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  inputRef,
}) => {
  const getPadding = () => {
    if (startAdornment) {
      return '8.5px 14px 8.5px 0px';
    } else if (endAdornment) {
      return '8.5px 0px 8.5px 14px';
    } else {
      return '8.5px 14px';
    }
  };
  return (
    <Box ref={containerRef} width={width} sx={containerSx}>
      {label && (
        <InputLabel
          htmlFor='orby-text-field'
          sx={{
            position: 'relative',
            marginBottom: '6px',
          }}
        >
          <OrbyTypography
            size='sm'
            weight='medium'
            color={OrbyColorPalette['grey-700']}
          >
            {label}
          </OrbyTypography>
        </InputLabel>
      )}
      <TextField
        autoFocus={autoFocus}
        rows={rows}
        onClick={onClick}
        multiline={multiline}
        autoComplete={autoComplete}
        error={!disabled && !!error}
        helperText={showErrorText ? error || helperText : ''}
        type={type}
        name={name}
        aria-label={name}
        id={'orby-text-field'}
        disabled={disabled}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
        FormHelperTextProps={{
          sx: {
            color: `${OrbyColorPalette['grey-500']}`,
            ml: '2px',
            '&.Mui-error': {
              color: `${OrbyColorPalette['error-500']}`,
            },
            textWrap: 'balance',
            fontFamily: 'var(--font-family-inter-variable)',
            fontSize: getFontSize('sm'),
          },
        }}
        InputProps={{
          inputRef: inputRef,
          readOnly: readOnly,
          placeholder: placeholder,
          endAdornment: endAdornment ? (
            <InputAdornment position='end'>{endAdornment}</InputAdornment>
          ) : error ? (
            <InputAdornment position='end'>
              <ErrorOutline sx={{ color: OrbyColorPalette['error-500'] }} />
            </InputAdornment>
          ) : undefined,
          startAdornment: startAdornment ? (
            <InputAdornment position='start'>{startAdornment}</InputAdornment>
          ) : undefined,
          sx: {
            fontFamily: 'var(--font-family-inter-variable)',
            fontSize: fontSize,
            backgroundColor: 'white',
            height: multiline ? 'unset' : '40px',
            borderRadius: '8px',
            '& .MuiInputBase-input': { padding: getPadding() },
            '&.Mui-disabled': {
              backgroundColor: OrbyColorPalette['grey-50'],
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: `${OrbyColorPalette['error-300']} !important`,
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderWidth: variant === 'flat' ? '0px' : '1px',
              borderColor: OrbyColorPalette['grey-200'],
              boxShadow:
                variant === 'flat'
                  ? 'unset'
                  : '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: disabled
                ? OrbyColorPalette['grey-300']
                : OrbyColorPalette['blue-700'],
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              boxShadow: error
                ? '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2;'
                : variant === 'flat'
                  ? 'unset'
                  : `0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px ${OrbyColorPalette['purple-100']}`,
              borderWidth: variant === 'flat' ? '0px' : '1px',
              borderColor: OrbyColorPalette['blue-700'],
            },
            '& ::placeholder': {
              opacity: '1 !important',
              color: `${OrbyColorPalette['grey-500']} !important`,
            },
            '::-webkit-input-placeholder': {
              opacity: '1 !important',
              color: `${OrbyColorPalette['grey-500']} !important`,
            },
            ...inputSx,
          },
        }}
        sx={{
          width: width,
          '& .MuiInputBase-root': {
            ...inputStyle,
          },
        }}
      />
    </Box>
  );
};

export default React.memo(OrbyTextField);
