import {
  GetProcessRequest,
  GetProcessResponse,
  ListProcessesRequest,
  ListProcessesResponse,
  ProcessDiscoveryClientImpl,
} from 'protos/pb/v1alpha2/process_discovery_service';
import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtills';
import { storageService } from './StorageService';

export class ProcessDiscoveryService {
  private static _instance = new ProcessDiscoveryService();
  private _client: ProcessDiscoveryClientImpl;

  private constructor() {
    this._client = new ProcessDiscoveryClientImpl(rpcWithErrorHandling);
  }

  static getInstance(): ProcessDiscoveryService {
    if (!this._instance) {
      this._instance = new ProcessDiscoveryService();
    }
    return this._instance;
  }

  listProcesses = async (
    req: ListProcessesRequest,
  ): Promise<{ response?: ListProcessesResponse; error?: Error }> => {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await this._client.ListProcesses(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  };

  getProcess = async (
    req: GetProcessRequest,
  ): Promise<{ response?: GetProcessResponse; error?: Error }> => {
    try {
      const authorization = await storageService.getAuthorizationHeader();
      const response = await this._client.GetProcess(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  };
}

export const processDiscoveryService = ProcessDiscoveryService.getInstance();
