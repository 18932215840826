import React, { FC, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createTaskAction,
  createTaskClearAction,
  createTaskErrorAction,
} from '../../../../../redux/actions/taskV2.action';
import { CreateTaskRequest } from 'protos/pb/v1alpha2/tasks_service';
import {
  taskCreationErrorSelector,
  processingTaskSelector,
  processingTaskStatusSelector,
} from '../../../../../redux/selectors/taskV2.selectors';
import CustomMultiTypeFilePicker from '../../../../../components/CustomMultiTypeFilePicker';
import { File } from 'protos/automation_mining/ontology/data_models';
import {
  DataLoadingStatus,
  MAX_FILES_ALLOWED_TO_BE_UPLOADED_IN_BATCH,
  MAX_PROTO_SIZE_IN_BYTES,
} from '../../../../../utils/constants';
import { OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import {
  showErrorToast,
  showSuccessToast,
} from 'orby-ui/src/components/toast/OrbyToast';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../../../../components/CustomModal';
import { Box } from '@mui/system';
import { DEFAULT_FIRST_PAGE } from 'orby-ui/src/components/table/table-utils';
import { WORKFLOW_PAGE } from '../../..';
import { Workflow } from 'protos/pb/v1alpha2/workflows_service';

interface RunModalProps {
  open: boolean;
  selectedWorkflow: Workflow;
  setOpen: (x: boolean) => void;
}

const RunModal: FC<RunModalProps> = ({ open, setOpen, selectedWorkflow }) => {
  const navigate = useNavigate();
  const [files, setFiles] = useState<File[]>([]);
  // Size of all files in bytes
  const [totalFilesSize, setTotalFilesSize] = useState(0);
  const [error, setError] = useState(false);
  const taskCreationError = useSelector(taskCreationErrorSelector);
  const processingTaskStatus = useSelector(processingTaskStatusSelector);
  const addingTask = useSelector(processingTaskSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (processingTaskStatus === DataLoadingStatus.LOADED) {
      if (files.length === 1) {
        showSuccessToast(
          'Task ' + (files[0].name ?? '') + ' is added successfully',
        );
      } else {
        showSuccessToast(`${files?.length} tasks are added successfully`);
      }
      handleDownloadTaskSuccess();
    } else if (processingTaskStatus === DataLoadingStatus.ERROR) {
      showErrorToast(taskCreationError?.message);
      dispatch(createTaskErrorAction());
      resetForm();
    }
  }, [processingTaskStatus]);

  const handleDownloadTaskSuccess = () => {
    handleModalClose();
    navigate(
      `/executions?page_no=${DEFAULT_FIRST_PAGE}&tab=${WORKFLOW_PAGE.API_AUTOMATION_TAB_INDEX}`,
    );
  };

  const resetForm = () => {
    setFiles([]);
    dispatch(createTaskClearAction());
  };

  const handleModalClose = () => {
    setOpen(false);
    setError(false);
    resetForm();
  };

  const handleSubmit = () => {
    if (files.length > MAX_FILES_ALLOWED_TO_BE_UPLOADED_IN_BATCH) {
      showErrorToast(
        `You can upload maximum ${MAX_FILES_ALLOWED_TO_BE_UPLOADED_IN_BATCH} files at a time`,
      );
      return;
    }
    const req: CreateTaskRequest = {
      parent: selectedWorkflow?.name,
      filesWrapper: { files: files },
    };
    if (totalFilesSize > MAX_PROTO_SIZE_IN_BYTES) {
      showErrorToast(
        `You can send maximum ${MAX_PROTO_SIZE_IN_BYTES} bytes in a single request`,
      );
      return;
    }
    dispatch(createTaskAction(req));
  };

  return (
    <CustomModal
      disablePortal={true}
      open={open}
      closable={true}
      heading={`Documents Needed`}
      containerSx={{ width: '480px', paddingTop: '16px !important' }}
      primaryLabel={'Submit'}
      secondaryLabel={'Cancel'}
      handleClose={handleModalClose}
      onPrimaryClick={handleSubmit}
      isPrimaryLoading={addingTask}
      primaryDisabled={(files?.length ?? 0) === 0 || addingTask}
      onSecondaryClick={handleModalClose}
      actionSx={{
        pt: '0px !important',
        pb: '32px !important',
        display: 'flex',
        justifyContent: 'flex-end',
        alignContent: 'flex-end',
      }}
      secondaryButtonSx={{
        maxWidth: '117px',
      }}
      primaryButtonSx={{
        maxWidth: '171px',
      }}
      content={
        <Box sx={{ pt: '0px !important', marginBottom: '88px' }}>
          <OrbyTypography
            color={OrbyColorPalette['grey-600']}
            size='sm'
            weight='regular'
            sx={{
              paddingBottom: '32px',
              paddingTop: '8px',
            }}
          >
            This workflow “{selectedWorkflow?.displayName}” requires you to
            upload one or more documents.
          </OrbyTypography>
          <OrbyTypography
            weight='medium'
            size='sm'
            color={OrbyColorPalette['grey-700']}
            sx={{
              paddingBottom: '8px',
            }}
          >
            Upload files
          </OrbyTypography>
          <CustomMultiTypeFilePicker
            files={files}
            setFiles={setFiles}
            workflowName={selectedWorkflow?.name || ''}
            error={error}
            setError={setError}
            isUploading={addingTask}
            setTotalFilesSize={setTotalFilesSize}
          />
        </Box>
      }
    />
  );
};

export default memo(RunModal);
