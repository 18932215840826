import { Box } from '@mui/material';
import React from 'react';
import { EXTENSION_ID } from 'extension/src/constants';
import { OrbyButton, OrbyColorPalette, OrbyTypography } from 'orby-ui/src';
import { useNavigate, useSearchParams } from 'react-router-dom';
import OrbyLoginLogo from '../../static/orby.svg';
import { showErrorToast } from 'orby-ui/src/components/toast/OrbyToast';

export default function MachineRegistration() {
  const navigate = useNavigate();
  // get auth_code and agent_id from the query params
  const [searchParams] = useSearchParams();
  const authCode = searchParams.get('auth_code');
  const agentId = searchParams.get('agent_id');

  if (!authCode || !agentId) {
    showErrorToast(
      'Invalid URL. Please use the link with the correct auth code and agent id.',
    );
    navigate('/');
  }

  const sendAuthenticationDetailsToExtension = async () => {
    if (chrome && chrome.runtime) {
      await chrome.runtime.sendMessage(EXTENSION_ID, {
        type: 'authenticateMachine',
        authCode,
        agentId,
      });
    }
  };

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        padding: '20px',
        flexDirection: 'column',
      }}
    >
      <img src={OrbyLoginLogo} alt='orby-logo' width={'232px'} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '50px',
          marginTop: '50px',
          alignItems: 'center',
        }}
      >
        <OrbyTypography
          weight='semibold'
          color={OrbyColorPalette['grey-900']}
          size='display-lg'
        >
          Do you want to connect your machine?
        </OrbyTypography>

        <OrbyButton
          variant='monochrome-outline'
          size='large'
          label='Decline'
          onClick={() => {
            navigate('/');
          }}
        />
        <OrbyButton
          variant='primary-contained'
          size='large'
          label='Connect'
          onClick={() => {
            sendAuthenticationDetailsToExtension();
            navigate('/');
          }}
        />
      </Box>
    </Box>
  );
}
