import { Vertex } from 'protos/google/cloud/documentai/v1/geometry';
import { boxPositionValuesUtilV2 } from './BoxPositionValuesUtilV2';
import {
  Document,
  DocumentPage,
  DocumentPageTable,
  DocumentPageToken,
} from 'protos/google/cloud/documentai/v1/document';
import {
  EntityInfo,
  TextSegmentInfo,
} from '../redux/reducers/review_task.reducer';
import { getSelectedTaskDocument } from './helpers';
import { Task } from 'protos/pb/v1alpha2/tasks_service';
import { tokenValuesUtil } from './TokenValuesUtil';
import {
  getNewTextSegmentId,
  getNormalizedVertices,
  getTextFromTextSegments,
} from './ReviewTaskUtils';
import { getYCoordForCurrentPage } from './BoundingBoxUtils';
import {
  BOUNDING_BOX_ID,
  PADDING_BW_PDF_PAGES,
  PDF_PANEL_ID,
} from './constants';
import store from '../redux/store';
import {
  setSelectedTextSegmentIdAction,
  setTokenListToHighlightAction,
} from '../redux/actions/review_task.action';

// This function enables the creation of a resizable box within a PDF panel.
// It allows users to click and drag to resize the box, updating its position
// and dimensions dynamically. The function also provides a callback to update
export const getPositionToHighlightTokens = (
  updateCoordinates: (styles: any) => void,
) => {
  const boundingBox = document.getElementById(BOUNDING_BOX_ID) as HTMLElement;
  const pdfPanel = document.getElementById(PDF_PANEL_ID) as HTMLElement;
  let startX: number, startY: number;

  // Function to handle mouse movement during resizing
  const mouseMoveHandler = (e: MouseEvent) => {
    const pdfPanelRect = pdfPanel.getBoundingClientRect();

    // Calculate new mouse coordinates adjusted for PDF panel position and scrolling
    const endX = e.pageX - pdfPanelRect.left + pdfPanel.scrollLeft;
    const endY = e.pageY - pdfPanelRect.top + pdfPanel.scrollTop;

    let start = { x: startX, y: startY };
    let end = { x: endX, y: endY };

    // Swap the value of start and end if endY is less then startY,
    // this is to handle reverse selection of pdf text for highlight
    if (endY < startY) {
      start = { x: endX, y: endY };
      end = { x: startX, y: startY };
    }

    // Update coordinates (callback function)
    updateCoordinates({
      startX: start.x + 'px',
      startY: start.y + 'px',
      endX: end.x + 'px',
      endY: end.y + 'px',
    });
  };

  // Function to handle mouse down event
  const mouseDownHandler = (e: MouseEvent) => {
    const pdfPanelRect = pdfPanel.getBoundingClientRect();
    // Calculate initial mouse coordinates adjusted for PDF panel position and scrolling
    startX = e.pageX - pdfPanelRect.left + pdfPanel.scrollLeft;
    startY = e.pageY - pdfPanelRect.top + pdfPanel.scrollTop;
    // Add event listeners for mouse movement and mouse up
    pdfPanel.addEventListener('mousemove', mouseMoveHandler);
    pdfPanel.addEventListener('mouseup', mouseUpHandler);
  };

  // Function to handle mouse up event
  const mouseUpHandler = (e: MouseEvent) => {
    pdfPanel.removeEventListener('mousemove', mouseMoveHandler);
    const pdfPanelRect = pdfPanel.getBoundingClientRect();
    // Calculate mouse coordinates adjusted for PDF panel position and scrolling
    const newX = e.pageX - pdfPanelRect.left + pdfPanel.scrollLeft;
    const newY = e.pageY - pdfPanelRect.top + pdfPanel.scrollTop;
    // Restrict coordinates update during click
    if (startX !== newX && startY !== newY) {
      updateCoordinates(undefined);
    } else {
      // Clear highlighted tokens
      store.dispatch(setTokenListToHighlightAction());
    }
  };

  // Function to remove event listeners and reset mouse events
  const removeListner = (e: MouseEvent) => {
    e.stopPropagation();
    pdfPanel.removeEventListener('mousemove', mouseMoveHandler);
    pdfPanel.removeEventListener('mousedown', mouseDownHandler);
  };
  // Add event listener to stop resizing when start dragging bbox
  boundingBox?.addEventListener('mousedown', removeListner);
  // Add event listener to resume resizing when dragging bbox stopped
  boundingBox?.addEventListener('mouseup', () =>
    pdfPanel.addEventListener('mousedown', mouseDownHandler),
  );

  // Add initial event listener to start resizing when the mouse is pressed
  pdfPanel.addEventListener('mousedown', mouseDownHandler);
};

export const getVerticesFromStyles = (
  styles: { top: string; left: string; width: string; height: string },
  scale: number,
) => {
  // ALL THE POSITION AND DIMENSIONS ARE ADJUSTED BACK TO ORIGINAL USING SCALE
  // e.g IF SCALE IS 0.6, ACTUAL PDF HEIGHT = 2200px, DISPLAYED HEIGHT OF DOCUMENT WILL BE 2200 * 0.6 = 1320px
  // THE CALCULATED STYLES WILL BE AS PER 1320px. TO STORE THEM ACC TO 2200px, WE DIVIDE BY 0.6
  const left = parseInt(styles.left, 10) / scale;
  const top = parseInt(styles.top, 10) / scale;
  const width = parseInt(styles.width, 10) / scale;
  const height = parseInt(styles.height, 10) / scale;
  const topLeft = { x: left, y: top };
  const topRight = { x: left + width, y: top };
  const bottomLeft = { x: left, y: top + height };
  const bottomRight = { x: left + width, y: top + height };

  // THESE VERTICES CORRESPONDS TO THEIR LOCATION FROM THE PAGE ORIGINALLY STORED
  // e.g IF EARLIER PAGE = 1 AND WE MOVE THE BOX TO PAGE 3, THEN THEIR Y COORD WILL
  // BE RELATED TO PAGE = 1 NOT PAGE = 3, WE ACTALLY NEED THEM RELATED TO PAGE = 3
  // THIS WE WILL CALCULATE BELOW IN VARIABLE - pageCorrespondingVertices
  return [topLeft, topRight, bottomRight, bottomLeft];
};

export const getCollidingTokenListToHighlight = (
  styles: { start: Vertex; end: Vertex },
  tokensInDocument: DocumentPageToken[],
): DocumentPageToken[] => {
  const collidingTokens: DocumentPageToken[] = [];
  const { start, end } = styles;

  tokensInDocument.forEach((token) => {
    // Get the top-left and bottom-right corners of the token's bounding box
    const tokenVertices = token?.layout?.boundingPoly?.vertices || [];
    const tokenTopLeft =
      boxPositionValuesUtilV2.getTopLeftCornerVertices(tokenVertices);
    const tokenBottomRight =
      boxPositionValuesUtilV2.getBottomRightCornerVertices(tokenVertices);
    // Check if there's an overlap between the bounding boxes of the token and the selection
    // Two conditions are checked:
    // 1. The token's bounding box is completely contained within the selection area vertically.
    // 2. The token's bounding box intersects with the selection area both vertically and horizontally.

    const isTokenTopLeftUnderStartY =
      (start?.y || 0) < (tokenBottomRight?.y || 0);
    const isTokenBottomRightAboveEndY =
      (end?.y || 0) - 5 > (tokenBottomRight?.y || 0);
    const isTokenTopLeftAboveEndY = (end?.y || 0) > (tokenTopLeft?.y || 0);
    const isTokenTopLeftBeforeEndX = (end?.x || 0) > (tokenTopLeft?.x || 0);
    // Checking for tokenStartedFromMiddle only when collidingTokens are empty else return true
    const tokenStartedFromMiddle = collidingTokens.length
      ? true
      : (start?.x || 0) < (tokenBottomRight?.x || 0);

    if (
      (isTokenTopLeftUnderStartY &&
        isTokenBottomRightAboveEndY &&
        tokenStartedFromMiddle) ||
      (isTokenTopLeftUnderStartY &&
        isTokenTopLeftAboveEndY &&
        tokenStartedFromMiddle &&
        isTokenTopLeftBeforeEndX)
    ) {
      collidingTokens.push(token);
    }
  });

  return collidingTokens;
};

export const getVerticesForHighlightSegments = (vertices?: Vertex[]) => {
  if (vertices?.length) {
    const topLeft = boxPositionValuesUtilV2.getTopLeftCornerVertices(vertices);
    const topRight =
      boxPositionValuesUtilV2.getTopRightCornerVertices(vertices);
    const bottomRight =
      boxPositionValuesUtilV2.getBottomRightCornerVertices(vertices);
    const bottomLeft =
      boxPositionValuesUtilV2.getBottomLeftCornerVertices(vertices);
    return [topLeft, topRight, bottomRight, bottomLeft];
  }
};

const areTokensOnSameYAxis = (
  vertices1: Vertex[] | undefined,
  vertices2: Vertex[] | undefined,
) => {
  // Extract vertices for the current token
  const tokenVertices = getVerticesForHighlightSegments(vertices1);
  // Extract vertices for the current segment
  const segmentVertices = getVerticesForHighlightSegments(vertices2);
  // Check if the token is on the same or in -10px to 10px difference with Y-axis as the current segment
  const additionalSpace = 10;
  const topLeftDiff =
    segmentVertices?.[0]?.y &&
    tokenVertices?.[0]?.y &&
    segmentVertices?.[0]?.y - tokenVertices?.[0]?.y;
  const bottomRightDiff =
    segmentVertices?.[3]?.y &&
    tokenVertices?.[3]?.y &&
    segmentVertices?.[3]?.y - tokenVertices?.[3]?.y;
  // Check if both vertices are on same Y axis
  const isOnSameYAxis =
    topLeftDiff !== undefined &&
    bottomRightDiff !== undefined &&
    ((topLeftDiff >= -additionalSpace && topLeftDiff <= additionalSpace) ||
      (bottomRightDiff >= -additionalSpace &&
        bottomRightDiff <= additionalSpace));
  return isOnSameYAxis;
};

const isNextLineFullyAnnotated = (
  firstTokenVertices: Vertex[] | undefined,
  slicedHighlightedTokens: DocumentPageToken[],
  pageTokens: DocumentPageToken[],
): boolean => {
  if (!firstTokenVertices || slicedHighlightedTokens.length === 0) {
    return false;
  }
  const lastTokenVertices =
    slicedHighlightedTokens?.[slicedHighlightedTokens.length - 1]?.layout
      ?.boundingPoly?.vertices;
  // Check if firstTokenVertices and lastTokenVertices of slicedHighlightedTokens are on same Y axis
  if (areTokensOnSameYAxis(firstTokenVertices, lastTokenVertices)) {
    const isNonHighlightedTokenOnRight = pageTokens.some((token) => {
      const tokenVertices =
        getVerticesForHighlightSegments(
          token?.layout?.boundingPoly?.vertices,
        ) || [];
      const lastTokenIndices =
        getVerticesForHighlightSegments(lastTokenVertices) || [];
      // Check if any non-highlighted token exists on the right side with the same Y axis as lastTokenVertices
      return (
        areTokensOnSameYAxis(tokenVertices, lastTokenVertices) &&
        (lastTokenIndices?.[1]?.x || 0) < (tokenVertices?.[1]?.x || 0)
      );
    });
    if (isNonHighlightedTokenOnRight) {
      return false;
    }
  }

  let fullyAnnotated = false;

  slicedHighlightedTokens.forEach((token) => {
    // Set fullyAnnotated true if any token in slicedHighlightedTokens exists on next line or all the tokens of slicedHighlightedTokens are on same line
    if (
      !areTokensOnSameYAxis(
        firstTokenVertices,
        token?.layout?.boundingPoly?.vertices,
      ) ||
      slicedHighlightedTokens.every((token) =>
        areTokensOnSameYAxis(
          firstTokenVertices,
          token?.layout?.boundingPoly?.vertices,
        ),
      )
    ) {
      fullyAnnotated = true;
    }
  });
  return fullyAnnotated;
};

// Function to check if current line fully annotated
const isCurrentLineFullyAnnotated = (
  prevTokenVertices: Vertex[] | undefined,
  highlightedTokens: DocumentPageToken[],
  pageTokens: DocumentPageToken[],
) => {
  // Initialize topHighlightedTokenVertices with prevTokenVertices
  let topHighlightedTokenVertices = prevTokenVertices;

  // Loop through highlightedTokens and set topHighlightedTokenVertices with the token vertices having least
  // x coord and on same Y axis
  for (let i = 0; i < highlightedTokens.length; i++) {
    const token = highlightedTokens[i];
    const tokenVertices =
      getVerticesForHighlightSegments(token?.layout?.boundingPoly?.vertices) ||
      [];
    if (
      areTokensOnSameYAxis(tokenVertices, topHighlightedTokenVertices) &&
      (tokenVertices[0].x || 0) < (topHighlightedTokenVertices?.[0].x || 0)
    ) {
      topHighlightedTokenVertices = tokenVertices;
    }
  }

  //  Get non highlighted token on the left side of topHighlightedTokenVertices if available
  const isNonHighlightedTokenOnLeft = pageTokens.some((token) => {
    const tokenVertices =
      getVerticesForHighlightSegments(token?.layout?.boundingPoly?.vertices) ||
      [];
    return (
      areTokensOnSameYAxis(tokenVertices, topHighlightedTokenVertices) &&
      (topHighlightedTokenVertices?.[0]?.x || 0) > (tokenVertices?.[0]?.x || 0)
    );
  });

  // Return false if isNonHighlightedTokenOnLeft
  if (isNonHighlightedTokenOnLeft) {
    return false;
  }

  return true;
};

/**
 * Updates the entity information with new text segments based on the highlighted tokens.
 *
 * @param highlightedTokenList - List of highlighted tokens to be processed.
 * @param selectedEntityInfo - The entity information that will be updated with new text segments.
 * @param selectedTask - The task associated with the selected entity.
 * @param page - The page number where the tokens are highlighted.
 * @param selectedSegmentID - Optional ID of a specific segment to be deleted before adding new segments.
 *
 * @returns Updated entity information including new text segments.
 */
export const getEntityInfoWithHighlightedTextSegments = (
  highlightedTokenList: DocumentPageToken[],
  selectedEntityInfo: EntityInfo,
  selectedTask: Task,
  page: number,
  selectedSegmentID?: string,
  isAddLocation = false,
  isTable = false,
) => {
  // Retrieve the selected task document
  const selectedTaskDocument = getSelectedTaskDocument(selectedTask)
    ?.documents?.[0] as Document;
  // Retrieve the height and width of the specified page
  const pageHeight = selectedTaskDocument?.pages?.[page]?.image
    ?.height as number;
  const pageWidth = selectedTaskDocument?.pages?.[page]?.image?.width as number;

  // Create a copy of the existing text segments

  // In case of table entities we clear out all the previous
  // textSegments if add location is not intended
  const newTextSegments: Record<string, TextSegmentInfo> =
    isTable && !isAddLocation
      ? {}
      : {
          ...selectedEntityInfo?.textSegments,
        };

  if (!isTable) {
    // Deleted selected segment or first segment accordingly
    delete newTextSegments[
      selectedSegmentID ?? Number(Object.keys(newTextSegments)[0])
    ];
  }

  // Initialize the current segment
  let currentSegment: TextSegmentInfo;
  let lastAddedSegmentId = '';
  // Iterate through each token in the highlighted tokens
  highlightedTokenList?.forEach((token, index) => {
    // Extract vertices for the current token
    const tokenVertices = getVerticesForHighlightSegments(
      token?.layout?.boundingPoly?.vertices,
    );
    // Extract vertices for the current segment
    const segmentVertices = getVerticesForHighlightSegments(
      currentSegment?.vertices,
    );

    if (
      areTokensOnSameYAxis(
        token?.layout?.boundingPoly?.vertices,
        currentSegment?.vertices,
      ) ||
      (currentSegment &&
        isCurrentLineFullyAnnotated(
          highlightedTokenList[index - 1].layout?.boundingPoly?.vertices,
          highlightedTokenList,
          selectedTaskDocument?.pages?.[page]?.tokens || [],
        ) &&
        isNextLineFullyAnnotated(
          token?.layout?.boundingPoly?.vertices,
          highlightedTokenList.slice(index + 1),
          selectedTaskDocument?.pages?.[page]?.tokens || [],
        ))
    ) {
      // Tokens are on the same Y-axis, accumulate vertices and text
      const accumulatedVertices = getVerticesForHighlightSegments([
        ...(segmentVertices as Vertex[]),
        ...(tokenVertices as Vertex[]),
      ]);
      const accumulatedText = (currentSegment.text +
        ' ' +
        tokenValuesUtil.getTextFromTokens(
          [token],
          selectedTaskDocument?.text || '',
        )) as string;
      currentSegment.vertices = accumulatedVertices as Vertex[];
      currentSegment.pageCorrespondingVertices =
        accumulatedVertices as Vertex[];
      currentSegment.text = accumulatedText;
      currentSegment.textFromTokens = accumulatedText;
      currentSegment.endIndex =
        token?.layout?.textAnchor?.textSegments?.[0]?.endIndex || 0;
    } else {
      // Tokens are on a different Y-axis, finalize the current segment if exists
      if (currentSegment) {
        // Calculate normalized vertices for the current segment
        const normalizedVertices = getNormalizedVertices(
          pageHeight,
          pageWidth,
          segmentVertices as Vertex[],
        );
        // Add the current segment to the new text segments
        newTextSegments[currentSegment.id] = {
          ...currentSegment,
          normalizedVertices,
        };
        if (isAddLocation) {
          selectedEntityInfo.entityText += ' ' + currentSegment.text;
        }
      }
      // Start a new segment
      const newSegmentId = getNewTextSegmentId();
      lastAddedSegmentId = newSegmentId;
      currentSegment = {
        id: newSegmentId,
        entityId: selectedEntityInfo.id,
        page,
        modifiedPage: page,
        text: tokenValuesUtil.getTextFromTokens(
          [token],
          selectedTaskDocument?.text || '',
        ) as string,
        textFromTokens: tokenValuesUtil.getTextFromTokens(
          [token],
          selectedTaskDocument?.text || '',
        ) as string,
        vertices: tokenVertices,
        pageCorrespondingVertices: tokenVertices,
        startIndex:
          token?.layout?.textAnchor?.textSegments?.[0]?.startIndex || 0,
        endIndex: token?.layout?.textAnchor?.textSegments?.[0]?.endIndex || 0,
      } as TextSegmentInfo;
    }
  });
  // Add the last segment if needed
  // @ts-ignore
  if (currentSegment) {
    const segmentVertices = getVerticesForHighlightSegments(
      currentSegment?.vertices,
    );
    const normalizedVertices = getNormalizedVertices(
      pageHeight,
      pageWidth,
      segmentVertices as Vertex[],
    );
    newTextSegments[currentSegment.id] = {
      ...currentSegment,
      normalizedVertices,
    };
    if (isAddLocation) {
      selectedEntityInfo.entityText += ' ' + currentSegment.text;
    }
  }

  if (!isAddLocation) {
    selectedEntityInfo.entityText = getTextFromTextSegments(newTextSegments);
  }

  // Set selectedTextSegmentId
  if (lastAddedSegmentId) {
    store.dispatch(setSelectedTextSegmentIdAction(lastAddedSegmentId));
  }
  // Return the updated entity information with highlighted text segments
  return { ...selectedEntityInfo, textSegments: newTextSegments } as EntityInfo;
};

export const getPdfPageHighlightedbox = (
  pages: DocumentPage[],
  yCoord: number,
  padding: number,
): number => {
  let accumulatedHeight = 0;
  // Loop over each page to find the page index where the bounding box lies
  for (let index = 0; index < pages.length; index++) {
    const pageHeight = (pages?.[index]?.image?.height || 0) + padding;
    // Check if the y coordinate is within the current page's range
    if (
      yCoord >= accumulatedHeight &&
      yCoord < accumulatedHeight + pageHeight
    ) {
      return index; // Bounding box lies on this page
    }
    // Accumulate the height for the next page
    accumulatedHeight += pageHeight;
  }
  // If the bounding box doesn't lie on any page, default to page index 0
  return 0;
};

/**
 * Retrieves the vertices and page index of highlighted tokens based on provided styles and scale.
 *
 * @param styles - An object containing the start and end coordinates of the highlighted area:
 *   - `startX`: The x-coordinate of the start point as a string.
 *   - `startY`: The y-coordinate of the start point as a string.
 *   - `endX`: The x-coordinate of the end point as a string.
 *   - `endY`: The y-coordinate of the end point as a string.
 * @param selectedTask - The task object that contains the document information. This is used to retrieve the document.
 * @param scale - The scale factor to adjust the coordinates.
 *
 * @returns An object containing:
 *   - `pageCorrespondingStyles`: An object with updated start and end vertices, adjusted for the page.
 *     - `start`: The adjusted start vertex with `x` and `y` properties.
 *     - `end`: The adjusted end vertex with `x` and `y` properties.
 *   - `page`: The page index where the bounding box is located.
 */
export const getVerticesAndPageOfHighlightedTokens = (
  styles: { startX: string; startY: string; endX: string; endY: string },
  selectedTask: Task,
  scale: number,
) => {
  // Extract start and end vertices from the provided styles using the scale
  const startX = parseInt(styles.startX, 10) / scale;
  const startY = parseInt(styles.startY, 10) / scale;
  const endX = parseInt(styles.endX, 10) / scale;
  const endY = parseInt(styles.endY, 10) / scale;
  const start = { x: startX, y: startY };
  const end = { x: endX, y: endY };

  // Retrieve the selected task document
  const selectedTaskDocument = getSelectedTaskDocument(selectedTask)
    ?.documents?.[0] as Document;

  // total padding will be CURRENT PADDING / scale
  const padding = PADDING_BW_PDF_PAGES / scale;
  // Determine the page index for the bounding box based on the provided vertices
  const page = getPdfPageHighlightedbox(
    selectedTaskDocument?.pages as DocumentPage[],
    start?.y as number,
    padding,
  );

  const pageCorrespondingStyles = { start, end };
  // Updating styles according to page
  pageCorrespondingStyles.start = {
    ...start,
    y: getYCoordForCurrentPage(
      selectedTaskDocument?.pages as DocumentPage[],
      start?.y as number,
      padding,
      0,
    ),
  };
  pageCorrespondingStyles.end = {
    ...end,
    y: getYCoordForCurrentPage(
      selectedTaskDocument?.pages as DocumentPage[],
      end?.y as number,
      padding,
      0,
    ),
  };

  return { pageCorrespondingStyles, page };
};

/**
 * Checks if the highlighted coordinates are inside the given coordinates.
 *
 * @param highlightedCoords - An object containing the start and end vertices of the highlighted area.
 * @param coords - An array of vertices representing the bounding box.
 * @returns boolean - Returns true if the highlighted area is completely inside the bounding box, otherwise false.
 */
export const isHighlightedInsideGivenCoords = (
  highlightedCoords: { start: Vertex; end: Vertex },
  coords: Vertex[],
) => {
  const tableTopLeft = boxPositionValuesUtilV2.getTopLeftCornerVertices(coords);
  const tableBottomRight =
    boxPositionValuesUtilV2.getBottomRightCornerVertices(coords);
  // Check if the highlighted area's start and end x-coordinates are within the given coord's x-coordinates
  const insideX =
    highlightedCoords.start.x! >= tableTopLeft.x! &&
    highlightedCoords.end.x! <= tableBottomRight.x!;
  // Check if the highlighted area's start and end y-coordinates are within the given coord's y-coordinates
  const insideY =
    highlightedCoords.start.y! >= tableTopLeft.y! &&
    highlightedCoords.end.y! <= tableBottomRight.y!;

  // Return true if both x and y coordinates of the highlighted area are inside the given coords, otherwise false
  return insideX && insideY;
};

/**
 * Finds the cell in the table that collides with the highlighted coordinates.
 *
 * @param table - The table containing rows and cells to check.
 * @param highlightedCoords - An object containing the start and end vertices of the highlighted area.
 * @returns The cell that collides with the highlighted area, or undefined if no such cell is found.
 */
export const getCollidingCell = (
  table: DocumentPageTable,
  highlightedCoords: { start: Vertex; end: Vertex },
) => {
  let collidingCell;
  for (const row of [
    ...(table?.bodyRows || []),
    ...(table?.headerRows || []),
  ]) {
    // Find the cell in the current row that collides with the highlighted coordinates
    const highlightedCell = row?.cells?.find((cell) => {
      return isHighlightedInsideGivenCoords(
        highlightedCoords,
        cell?.layout?.boundingPoly?.vertices as Vertex[],
      );
    });
    if (highlightedCell !== undefined) {
      collidingCell = highlightedCell;
      break;
    }
  }
  return collidingCell;
};

/**
 * Gets the tokens that collide with the given cell vertices.
 *
 * @param cellsVertices - An array of vertices defining the bounding box of the cell.
 * @param tokens - An array of tokens to check for collision with the cell.
 * @returns An array of tokens that collide with the cell.
 */
export const getCollidingCellTokens = (
  cellsVertices: Vertex[],
  tokens: DocumentPageToken[],
) => {
  const cellTopLeft =
    boxPositionValuesUtilV2.getTopLeftCornerVertices(cellsVertices);
  const cellBottomRight =
    boxPositionValuesUtilV2.getBottomRightCornerVertices(cellsVertices);
  // Filter tokens to find those that are completely inside the cell's bounding box
  return tokens.filter((token) => {
    const tokenVertices = token.layout?.boundingPoly?.vertices as Vertex[];
    const tokenTopLeft =
      boxPositionValuesUtilV2.getTopLeftCornerVertices(tokenVertices);
    const tokenBottomRight =
      boxPositionValuesUtilV2.getBottomRightCornerVertices(tokenVertices);
    // Check if the token's x-coordinates are within the cell's x-coordinates
    const insideX =
      cellTopLeft.x! <= tokenTopLeft.x! &&
      cellBottomRight.x! >= tokenBottomRight.x!;
    // Check if the token's y-coordinates are within the cell's y-coordinates
    const insideY =
      cellTopLeft.y! <= tokenTopLeft.y! &&
      cellBottomRight.y! >= tokenBottomRight.y!;
    // Return true if both x and y coordinates of the token are inside the cell's bounding box
    return insideX && insideY;
  });
};

/**
 * Function to get the vertices of cells in a table annotation that collide with the given bounding box.
 *
 * @param boundingBoxVertices - The vertices of the bounding box to check against.
 * @param page - The page number where the table is located in the document.
 * @param document - The document object that contains the page and table information. If undefined, the function returns undefined.
 *
 * @returns An array of vertices for cells that collide with the bounding box, or undefined if no collision is found or if the document is not provided.
 */
export const getCellVerticesForColumnAnnotation = (
  boundingBoxVertices: Vertex[],
  page: number,
  document: Document | undefined,
): Vertex[] | undefined => {
  if (!document) return;
  const tables: DocumentPageTable[] = document?.pages?.[page]?.tables ?? [];
  const collidingTable = tables?.find((table) => {
    return boxPositionValuesUtilV2.isBoxOverlappingAnotherBox(
      table.layout!.boundingPoly!.vertices!,
      boundingBoxVertices,
    );
  });

  if (!collidingTable) return;

  const cellsVertices: Vertex[] = [];

  for (const row of [
    ...(collidingTable.headerRows ?? []),
    ...(collidingTable.bodyRows ?? []),
  ]) {
    for (const cell of row.cells!) {
      // Check if the cell's bounding box collides with the provided bounding box
      const doesCellCollide =
        boxPositionValuesUtilV2.isBoxOverlappingAnotherBox(
          boundingBoxVertices,
          cell.layout!.boundingPoly!.vertices!,
        );
      // If the cell collides, add its vertices to the cellsVertices
      if (doesCellCollide) {
        cellsVertices.push(...cell.layout!.boundingPoly!.vertices!);
        break;
      }
    }
  }

  return cellsVertices;
};
