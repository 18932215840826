/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { messageTypeRegistry } from "../../typeRegistry";
import { Process } from "../process_discovery/process";

export const protobufPackage = "pb.v1alpha2";

export interface GetProcessRequest {
  $type?: "pb.v1alpha2.GetProcessRequest";
  processId?: string | undefined;
}

export interface GetProcessResponse {
  $type?: "pb.v1alpha2.GetProcessResponse";
  process?: Process | undefined;
}

export interface ListProcessesRequest {
  $type?: "pb.v1alpha2.ListProcessesRequest";
  /** Maximum number of results to return */
  pageSize?:
    | number
    | undefined;
  /** Page number for the current request */
  pageNumber?: number | undefined;
}

export interface ListProcessesResponse {
  $type?: "pb.v1alpha2.ListProcessesResponse";
  /** TODO: use fieldmask. */
  processes?: Process[] | undefined;
  totalCount?: number | undefined;
}

function createBaseGetProcessRequest(): GetProcessRequest {
  return { $type: "pb.v1alpha2.GetProcessRequest", processId: "" };
}

export const GetProcessRequest = {
  $type: "pb.v1alpha2.GetProcessRequest" as const,

  encode(message: GetProcessRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processId !== undefined && message.processId !== "") {
      writer.uint32(10).string(message.processId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetProcessRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetProcessRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetProcessRequest {
    return {
      $type: GetProcessRequest.$type,
      processId: isSet(object.processId) ? globalThis.String(object.processId) : "",
    };
  },

  toJSON(message: GetProcessRequest): unknown {
    const obj: any = {};
    if (message.processId !== undefined && message.processId !== "") {
      obj.processId = message.processId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetProcessRequest>, I>>(base?: I): GetProcessRequest {
    return GetProcessRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetProcessRequest>, I>>(object: I): GetProcessRequest {
    const message = createBaseGetProcessRequest();
    message.processId = object.processId ?? "";
    return message;
  },
};

messageTypeRegistry.set(GetProcessRequest.$type, GetProcessRequest);

function createBaseGetProcessResponse(): GetProcessResponse {
  return { $type: "pb.v1alpha2.GetProcessResponse", process: undefined };
}

export const GetProcessResponse = {
  $type: "pb.v1alpha2.GetProcessResponse" as const,

  encode(message: GetProcessResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.process !== undefined) {
      Process.encode(message.process, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetProcessResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetProcessResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.process = Process.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetProcessResponse {
    return {
      $type: GetProcessResponse.$type,
      process: isSet(object.process) ? Process.fromJSON(object.process) : undefined,
    };
  },

  toJSON(message: GetProcessResponse): unknown {
    const obj: any = {};
    if (message.process !== undefined) {
      obj.process = Process.toJSON(message.process);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetProcessResponse>, I>>(base?: I): GetProcessResponse {
    return GetProcessResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetProcessResponse>, I>>(object: I): GetProcessResponse {
    const message = createBaseGetProcessResponse();
    message.process = (object.process !== undefined && object.process !== null)
      ? Process.fromPartial(object.process)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(GetProcessResponse.$type, GetProcessResponse);

function createBaseListProcessesRequest(): ListProcessesRequest {
  return { $type: "pb.v1alpha2.ListProcessesRequest", pageSize: 0, pageNumber: 0 };
}

export const ListProcessesRequest = {
  $type: "pb.v1alpha2.ListProcessesRequest" as const,

  encode(message: ListProcessesRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageSize !== undefined && message.pageSize !== 0) {
      writer.uint32(32).int32(message.pageSize);
    }
    if (message.pageNumber !== undefined && message.pageNumber !== 0) {
      writer.uint32(40).int32(message.pageNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListProcessesRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListProcessesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 4:
          if (tag !== 32) {
            break;
          }

          message.pageSize = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.pageNumber = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListProcessesRequest {
    return {
      $type: ListProcessesRequest.$type,
      pageSize: isSet(object.pageSize) ? globalThis.Number(object.pageSize) : 0,
      pageNumber: isSet(object.pageNumber) ? globalThis.Number(object.pageNumber) : 0,
    };
  },

  toJSON(message: ListProcessesRequest): unknown {
    const obj: any = {};
    if (message.pageSize !== undefined && message.pageSize !== 0) {
      obj.pageSize = Math.round(message.pageSize);
    }
    if (message.pageNumber !== undefined && message.pageNumber !== 0) {
      obj.pageNumber = Math.round(message.pageNumber);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListProcessesRequest>, I>>(base?: I): ListProcessesRequest {
    return ListProcessesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListProcessesRequest>, I>>(object: I): ListProcessesRequest {
    const message = createBaseListProcessesRequest();
    message.pageSize = object.pageSize ?? 0;
    message.pageNumber = object.pageNumber ?? 0;
    return message;
  },
};

messageTypeRegistry.set(ListProcessesRequest.$type, ListProcessesRequest);

function createBaseListProcessesResponse(): ListProcessesResponse {
  return { $type: "pb.v1alpha2.ListProcessesResponse", processes: [], totalCount: 0 };
}

export const ListProcessesResponse = {
  $type: "pb.v1alpha2.ListProcessesResponse" as const,

  encode(message: ListProcessesResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.processes !== undefined && message.processes.length !== 0) {
      for (const v of message.processes) {
        Process.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    if (message.totalCount !== undefined && message.totalCount !== 0) {
      writer.uint32(16).int32(message.totalCount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ListProcessesResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseListProcessesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.processes!.push(Process.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.totalCount = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ListProcessesResponse {
    return {
      $type: ListProcessesResponse.$type,
      processes: globalThis.Array.isArray(object?.processes)
        ? object.processes.map((e: any) => Process.fromJSON(e))
        : [],
      totalCount: isSet(object.totalCount) ? globalThis.Number(object.totalCount) : 0,
    };
  },

  toJSON(message: ListProcessesResponse): unknown {
    const obj: any = {};
    if (message.processes?.length) {
      obj.processes = message.processes.map((e) => Process.toJSON(e));
    }
    if (message.totalCount !== undefined && message.totalCount !== 0) {
      obj.totalCount = Math.round(message.totalCount);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ListProcessesResponse>, I>>(base?: I): ListProcessesResponse {
    return ListProcessesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ListProcessesResponse>, I>>(object: I): ListProcessesResponse {
    const message = createBaseListProcessesResponse();
    message.processes = object.processes?.map((e) => Process.fromPartial(e)) || [];
    message.totalCount = object.totalCount ?? 0;
    return message;
  },
};

messageTypeRegistry.set(ListProcessesResponse.$type, ListProcessesResponse);

export interface ProcessDiscovery {
  GetProcess(request: DeepPartial<GetProcessRequest>, metadata?: grpc.Metadata): Promise<GetProcessResponse>;
  ListProcesses(request: DeepPartial<ListProcessesRequest>, metadata?: grpc.Metadata): Promise<ListProcessesResponse>;
}

export class ProcessDiscoveryClientImpl implements ProcessDiscovery {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetProcess = this.GetProcess.bind(this);
    this.ListProcesses = this.ListProcesses.bind(this);
  }

  GetProcess(request: DeepPartial<GetProcessRequest>, metadata?: grpc.Metadata): Promise<GetProcessResponse> {
    return this.rpc.unary(ProcessDiscoveryGetProcessDesc, GetProcessRequest.fromPartial(request), metadata);
  }

  ListProcesses(request: DeepPartial<ListProcessesRequest>, metadata?: grpc.Metadata): Promise<ListProcessesResponse> {
    return this.rpc.unary(ProcessDiscoveryListProcessesDesc, ListProcessesRequest.fromPartial(request), metadata);
  }
}

export const ProcessDiscoveryDesc = { serviceName: "pb.v1alpha2.ProcessDiscovery" };

export const ProcessDiscoveryGetProcessDesc: UnaryMethodDefinitionish = {
  methodName: "GetProcess",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetProcessRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetProcessResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const ProcessDiscoveryListProcessesDesc: UnaryMethodDefinitionish = {
  methodName: "ListProcesses",
  service: ProcessDiscoveryDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return ListProcessesRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = ListProcessesResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P> | "$type">]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
