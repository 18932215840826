// Auth
export enum AuthActionType {
  LOGIN = 'auth/login',
  LOGIN_COMPLETED = 'auth/login/completed',
  LOGIN_ERROR = 'auth/login/error',

  SAML_LOGIN = 'auth/login/saml',
  SAML_LOGIN_COMPLETED = 'auth/login/saml/completed',
  SAML_LOGIN_ERROR = 'auth/login/saml/error',

  GOOGLE_SIGN_IN_REQUEST = 'auth/google/sign-in/request',
  GOOGLE_LOGIN = 'auth/login/google',
  GOOGLE_LOGIN_COMPLETED = 'auth/login/google/completed',
  GOOGLE_LOGIN_ERROR = 'auth/login/google/error',

  MICROSOFT_SIGN_IN_REQUEST = 'auth/microsoft/sign-in/request',
  MICROSOFT_LOGIN = 'auth/login/microsoft',
  MICROSOFT_LOGIN_COMPLETED = 'auth/login/microsoft/completed',
  MICROSOFT_LOGIN_ERROR = 'auth/login/microsoft/error',

  SIGN_UP = 'auth/sign-up',
  SIGN_UP_COMPLETED = 'auth/sign-up/completed',
  SIGN_UP_ERROR = 'auth/sign-up/error',

  LOGOUT = 'auth/logout',
  LOGOUT_COMPLETED = 'auth/logout/completed',
  LOGOUT_ERROR = 'auth/logout/error',

  GOOGLE_LOGOUT = 'auth/logout/google',
  GOOGLE_LOGOUT_COMPLETED = 'auth/logout/google/completed',
  GOOGLE_LOGOUT_ERROR = 'auth/logout/google/error',

  UNAUTHENTICATED_ERROR = 'auth/unauthenticated/error',
}

// User
export enum UserActionType {
  FETCH_LOGGED_IN_USER = 'logged-in-user/fetch',
  FETCH_LOGGED_IN_USER_COMPLETED = 'logged-in-user/fetch/completed',
  FETCH_LOGGED_IN_USER_ERROR = 'logged-in-user/fetch/error',

  UPDATE_USER = 'user/update',
  UPDATE_USER_COMPLETED = 'user/update/completed',
  UPDATE_USER_ERROR = 'user/update/error',

  UPDATE_PASSWORD = 'user/password/update',
  UPDATE_PASSWORD_COMPLETED = 'user/password/update/completed',
  UPDATE_PASSWORD_ERROR = 'user/password/update/error',

  LIST_USERS = 'users/list',
  LIST_USERS_COMPLETED = 'users/list/completed',
  LIST_USERS_ERROR = 'users/list/error',

  ADD_USER_TO_ORGANIZATION = 'users/add-to-organization',
  ADD_USER_TO_ORGANIZATION_COMPLETED = 'users/add-to-organization/completed',
  ADD_USER_TO_ORGANIZATION_ERROR = 'users/add-to-organization/error',

  SET_SELECTED_ORG_INFO = 'org-info/set',
  SET_SELECTED_ORG_INFO_COMPLETED = 'org-info/set/completed',

  SET_UPDATED_USER = 'updated-user/set',
  SET_ADDED_USER = 'added-user/set',

  UPDATE_GOOGLE_TOKEN = 'google-token/update',
  UPDATE_GOOGLE_TOKEN_COMPLETED = 'google-token/update/completed',
  UPDATE_GOOGLE_TOKEN_ERROR = 'google-token/update/error',
  CLEAR_GOOGLE_TOKEN_UPDATE_STATUS = 'google-token/update/clear',

  UPDATE_MICROSOFT_TOKEN = 'microsoft-token/update',
  UPDATE_MICROSOFT_TOKEN_COMPLETED = 'microsoft-token/update/completed',
  UPDATE_MICROSOFT_TOKEN_ERROR = 'microsoft-token/update/error',
  CLEAR_MICROSOFT_TOKEN_UPDATE_STATUS = 'microsoft-token/update/clear',

  GET_USER_PERMISSIONS_COMPLETED = 'user/permissions/get/completed',
  GET_USER_PERMISSIONS_ERROR = 'user/permissions/get/error',
}

// Task
export enum TaskActionType {
  LIST_TASKS = 'tasks/list',
  LIST_TASKS_COMPLETED = 'tasks/list/completed',
  LIST_TASKS_ERROR = 'tasks/list/error',

  CREATE_TASK = 'task/create',
  CREATE_TASK_COMPLETED = 'task/create/completed',
  CREATE_TASK_ERROR = 'task/create/error',

  UPDATE_TASK = 'task/update',
  UPDATE_TASK_COMPLETED = 'task/update/completed',
  UPDATE_TASK_ERROR = 'task/update/error',
}

export enum TaskV2ActionType {
  LIST_TASKS_V2 = 'tasks_v2/list',
  LIST_TASKS_V2_COMPLETED = 'tasks_v2/list/completed',
  LIST_TASKS_V2_ERROR = 'tasks_v2/list/error',

  LIST_PENDING_TASKS = 'tasks/pending/list',
  LIST_PENDING_TASKS_COMPLETED = 'tasks/pending/list/completed',
  LIST_PENDING_TASKS_ERROR = 'tasks/pending/list/error',

  UPDATE_BATCH_TASKS = 'task/update/batch-tasks',
  UPDATE_BATCH_TASKS_COMPLETED = 'task/update/batch-tasks/completed',
  UPDATE_BATCH_TASKS_ERROR = 'task/update/batch-tasks/error',
  RESET_REASSIGNMENT_STATE = 'task/update/batch-tasks/reset-state',

  RETRY_TASKS = 'task/update/retry-tasks',
  RETRY_TASKS_COMPLETED = 'task/update/retry-tasks/completed',
  RETRY_TASKS_ERROR = 'task/update/retry-tasks/error',
  CLEAR_RETRY_TASKS = 'task/update/retry-tasks/clear',

  LIST_PENDING_TASKS_ADDITIONAL_ROUND = 'tasks/pending/list/AdditionalRound',
  LIST_PENDING_TASKS_ADDITIONAL_ROUND_COMPLETED = 'tasks/pending/list/completed/AdditionalRound',
  LIST_PENDING_TASKS_ADDITIONAL_ROUND_ERROR = 'tasks/pending/list/error/AdditionalRound',

  LIST_DECLINED_TASKS_V2 = 'tasks_v2/pending/list',
  LIST_DECLINED_TASKS_V2_COMPLETED = 'tasks_v2/pending/list/completed',
  LIST_DECLINED_TASKS_V2_ERROR = 'tasks_v2/pending/list/error',

  LIST_SYSTEM_DECLINED_TASKS_V2 = 'tasks_v2/SystemDeclined/list',
  LIST_SYSTEM_DECLINED_TASKS_V2_COMPLETED = 'tasks_v2/SystemDeclined/list/completed',
  LIST_SYSTEM_DECLINED_TASKS_V2_ERROR = 'tasks_v2/SystemDeclined/list/error',

  CREATE_TASK_V2 = 'tasks_v2/create',
  CREATE_TASK_V2_COMPLETED = 'tasks_v2/create/completed',
  CREATE_TASK_V2_ERROR = 'tasks_v2/create/error',
  CREATE_TASK_V2_CLEAR = 'tasks_v2/create/clear',

  UPDATE_TASK_V2 = 'tasks_v2/update',
  UPDATE_TASK_V2_COMPLETED = 'tasks_v2/update/completed',
  UPDATE_TASK_V2_ERROR = 'tasks_v2/update/error',

  SET_TASK_DECLINED_SUCCESS = 'tasks_v2/set/taskDeclinedSuccess',
  SET_COMPLETED_TASK_SUCCESS = 'tasks_v2/set/completedTaskSuccess',
  DELETE_TASK = 'task_v2/delete',
  DELETE_TASK_COMPLETED = 'task_v2/delete/completed',
  DELETE_TASK_ERROR = 'task_v2/delete/error',
  CLEAR_DELETED_TASK = 'task_v2/delete/clear',

  UPDATE_ENTITY_STATUS = 'task_v2/entity_status/update',

  SET_SELECTED_TASK = 'task/selected/set',

  ADD_ENTITY_TO_TASK = 'task_v2/entity/add',
  CLEAR_MANUAL_TEXT = 'task_v2/entity/clear_text',
  UPDATE_ENTITY_IN_TASK = 'task_v2/entity/update',
  REMOVE_PAGE_ANCHOR = 'task_v2/entity/remove_amchor',
  UPDATE_CELL_TIMESTAMP = 'task_v2/cell/set_timestamp',
  SAVE_UPLOADED_DOCUMENT = 'task_v2/save_document',
  REMOVE_SAVED_DOCUMENT = 'task_v2/remove_saved_document',

  UPDATE_DEBUG_LAYOUT = 'task_v2/debug_layout/update',
  UPDATE_ACTIVITY_MONITOR_VISIBILITY = 'task_v2/activity_monitor_visibility/update',

  LIST_API_EXECUTION_HISTORY = 'tasks/execution_history/list',
  LIST_API_EXECUTION_HISTORY_COMPLETED = 'tasks/execution_history/list/completed',
  LIST_API_EXECUTION_HISTORY_ERROR = 'tasks/execution_history/list/error',

  EXPORT_TASKS = 'tasks/export',
  EXPORT_TASKS_COMPLETED = 'tasks/export/completed',
  EXPORT_TASKS_ERROR = 'tasks/export/error',
  CLEAR_EXPORT_TASKS = 'tasks/export/clear',
  LIST_UI_EXECUTION_HISTORY = 'tasks/execution_history_ui/list',
  LIST_UI_EXECUTION_HISTORY_COMPLETED = 'tasks/execution_history_ui/list/completed',
  LIST_UI_EXECUTION_HISTORY_ERROR = 'tasks/execution_history_ui/list/error',

  GET_BLOCKED_WORKFLOW_EXECUTION_STATISTICS = 'tasks/blocked_workflow_execution_statistics/get',
  GET_BLOCKED_WORKFLOW_EXECUTION_STATISTICS_COMPLETED = 'tasks/blocked_workflow_execution_statistics/get/completed',
  GET_BLOCKED_WORKFLOW_EXECUTION_STATISTICS_ERROR = 'tasks/blocked_workflow_execution_statistics/get/error',

  DELETE_UI_EXECUTION_HISTORY = 'tasks/execution_history_ui/delete',
  DELETE_UI_EXECUTION_HISTORY_COMPLETED = 'tasks/execution_history_ui/delete/completed',
  DELETE_UI_EXECUTION_HISTORY_ERROR = 'tasks/execution_history_ui/delete/error',

  EXPORT_TASKS_DOWNLOAD_PENDING = 'tasks/export/download',
  EXPORT_TASKS_DOWNLOAD_COMPLETED = 'tasks/export/download/completed',
  EXPORT_TASKS_DOWNLOAD_ERROR = 'tasks/export/download/error',
  EXPORT_TASKS_DOWNLOAD_RESET_STATE = 'tasks/export/download/reset',

  DOWNLOAD_TASKS = 'tasks/download',
  DOWNLOAD_TASKS_COMPLETED = 'tasks/download/completed',
  DOWNLOAD_TASKS_ERROR = 'tasks/download/error',
  CLEAR_DOWNLOAD_TASK_COMPLETED = 'tasks/download/clear_completed',
}

// Task
export enum AutomationMiningActionType {
  GENERATE_ACTIVITY_GRAPH = 'tasks/generate',
  GENERATE_ACTIVITY_GRAPH_COMPLETED = 'tasks/generate/completed',
  GENERATE_ACTIVITY_GRAPH_ERROR = 'tasks/generate/error',
}

export enum ERPActionType {
  LIST_INVOICES = 'invoices/list',
  LIST_INVOICES_COMPLETED = 'invoices/list/completed',
  LIST_INVOICES_ERROR = 'invoices/list/error',

  CREATE_INVOICE = 'invoice/create',
  CREATE_INVOICE_COMPLETED = 'invoice/create/completed',
  CREATE_INVOICE_ERROR = 'invoice/create/error',

  UPDATE_INVOICE = 'invoice/update',
  UPDATE_INVOICE_COMPLETED = 'invoice/update/completed',
  UPDATE_INVOICE_ERROR = 'invoice/update/error',

  SET_SELECTED_INVOICE = 'invoice/select',

  DELETE_INVOICE = 'invoice/delete',
  DELETE_INVOICE_COMPLETED = 'invoice/delete/completed',
  DELETE_INVOICE_ERROR = 'invoice/delete/error',
}

export enum WorkflowActionType {
  LIST_WORKFLOWS = 'workflows/list',
  LIST_WORKFLOWS_COMPLETED = 'workflows/list/completed',
  LIST_WORKFLOWS_ERROR = 'workflows/list/error',

  LIST_TEMPLATE_WORKFLOWS = 'workflows/templates/list',
  LIST_TEMPLATE_WORKFLOWS_COMPLETED = 'workflows/templates/list/completed',
  LIST_TEMPLATE_WORKFLOWS_ERROR = 'workflows/templates/list/error',

  CREATE_WORKFLOW = 'workflow/create',
  CREATE_WORKFLOW_COMPLETED = 'workflow/create/completed',
  CREATE_WORKFLOW_ERROR = 'workflow/create/error',

  UPDATE_WORKFLOW = 'workflow/update',
  UPDATE_WORKFLOW_COMPLETED = 'workflow/update/completed',
  UPDATE_WORKFLOW_ERROR = 'workflow/update/error',

  DELETE_WORKFLOW = 'workflow/delete',
  DELETE_WORKFLOW_COMPLETED = 'workflow/delete/completed',
  DELETE_WORKFLOW_ERROR = 'workflow/delete/error',

  GET_WORKFLOW = 'workflow/get',
  GET_WORKFLOW_COMPLETED = 'workflow/get/completed',
  GET_WORKFLOW_ERROR = 'workflow/get/error',

  SET_SELECTED_WORKFLOW = 'selected-workflow/set',

  SET_WORKFLOW_COLORS = 'workflow/colors',

  // Workflow Fields
  LIST_WORKFLOW_FIELDS = 'workflow/fields/list',
  LIST_WORKFLOW_FIELDS_COMPLETED = 'workflow/fields/list/completed',
  LIST_WORKFLOW_FIELDS_ERROR = 'workflow/fields/list/error',
}

export enum OrbotWorkflowActionType {
  LIST_WORKFLOWS = 'orbot/workflows/list',
  LIST_WORKFLOWS_COMPLETED = 'orbot/workflows/list/completed',
  LIST_WORKFLOWS_ERROR = 'orbot/workflows/list/error',
  DELETE_WORKFLOW = 'orbot/workflow/delete',
  DELETE_WORKFLOW_COMPLETED = 'orbot/workflow/delete/completed',
  DELETE_WORKFLOW_ERROR = 'orbot/workflow/delete/error',

  LIST_WORKFLOWS_HOOK = 'orbot/workflows/list/hook',
}

export enum DashboardActionType {
  GET_DASHBOARD_STATS = 'dashboard-stats/get',
  GET_DASHBOARD_STATS_COMPLETED = 'dashboard-stats/get/completed',
  GET_DASHBOARD_STATS_ERROR = 'dashboard-stats/get/error',
}

export enum GetFiltersActionType {
  GET_USER_FILTERS = 'user-filters/get',
  GET_USER_FILTERS_COMPLETED = 'user-filters/get/completed',
  GET_USER_FILTERS_ERROR = 'user-filters/get/error',
  GET_WORKFLOW_FILTERS = 'workflow-filters/get',
  GET_WORKFLOW_FILTERS_COMPLETED = 'workflow-filters/get/completed',
  GET_WORKFLOW_FILTERS_ERROR = 'workflow-filters/get/error',

  GET_ORBOT_WORKFLOW_FILTERS = 'orbot-workflow-filters/get',
  GET_ORBOT_WORKFLOW_FILTERS_COMPLETED = 'orbot-workflow-filters/get/completed',
  GET_ORBOT_WORKFLOW_FILTERS_ERROR = 'orbot-workflow-filters/get/error',
}

export enum UtilityActionType {
  GET_GMAIL_LABELS = 'utility/get/',
  GET_GMAIL_LABELS_ERROR = 'utility/get/error',
  GET_GMAIL_LABELS_COMPLETED = 'utility/get/completed',
  GET_SCHEDULED_TASKS = 'utility/scheduled-task/get',
  GET_SCHEDULED_TASKS_ERROR = 'utility/scheduled-task/get/error',
  GET_SCHEDULED_TASKS_COMPLETED = 'utility/scheduled-task/get/completed',
}

export enum ConnectorActionType {
  LIST_CONNECTORS = 'connectors/list',
  LIST_CONNECTORS_COMPLETED = 'connectors/list/completed',
  LIST_CONNECTORS_ERROR = 'connectors/list/error',

  CREATE_CONNECTOR = 'connector/create',
  CREATE_CONNECTOR_COMPLETED = 'connector/create/completed',
  CREATE_CONNECTOR_ERROR = 'connector/create/error',

  UPDATE_CONNECTOR = 'connector/update',
  UPDATE_CONNECTOR_COMPLETED = 'connector/update/completed',
  UPDATE_CONNECTOR_ERROR = 'connector/update/error',

  DELETE_CONNECTOR = 'connector/delete',
  DELETE_CONNECTOR_COMPLETED = 'connector/delete/completed',
  DELETE_CONNECTOR_ERROR = 'connector/delete/error',

  GET_CONNECTOR = 'connector/get',
  GET_CONNECTOR_COMPLETED = 'connector/get/completed',
  GET_CONNECTOR_ERROR = 'connector/get/error',

  SET_SELECTED_CONNECTOR = 'selected-connector/set',

  CLEAR = 'connector/clear',
}

export enum ReviewTaskActionType {
  GET_TASK_FOR_REVIEW = 'task/get-for-review',
  GET_TASK_FOR_REVIEW_COMPLETED = 'task/get-for-review/completed',
  GET_TASK_FOR_REVIEW_ERROR = 'task/get-for-review/error',

  SET_SELECTED_ENTITY_ID = 'task/set-selected-entity-id',
  SET_SELECTED_ENTITY_INFO = 'task/set-selected-entity-info',
  COPY_SELECTED_ENTITY = 'task/copy-selected-entity',
  DELETE_SELECTED_ENTITY = 'task/delete-selected-entity',

  SET_SELECTED_TEXT_SEGMENT_ID = 'task/set-selected-text-segment-id',

  UPDATE_ENTITY_INFO = 'entity-info/update',
  UPDATE_ENTITY_INFO_NORMALIZED_VALUE = 'entity-info/update/normalized',
  UPDATE_TASK_ENTITY_INFO = 'task/entity-info/update',
  UPDATE_CLASSIFICATION_LABEL = 'task/classification-lable/update',

  UPDATE_TASK = 'task/update',
  UPDATE_TASK_COMPLETED = 'task/update/completed',
  UPDATE_TASK_ERROR = 'task/update/error',

  SAVE_TASK = 'task/save',
  SAVE_TASK_COMPLETED = 'task/save/completed',
  SAVE_TASK_ERROR = 'task/save/error',

  // TODO: Remove these actions from review task reducer and instead leverage above taskUpdate actions
  SET_AUTOMATION_IN_PRORGRESS = 'task/set-automation-in-progress',
  SET_AUTOMATION_COMPLETED = 'task/set-automation-completed',
  SET_AUTOMATION_COMPLETED_ERROR = 'task/set-automation-completed-error',

  GET_SELECTED_REVIEW_FILTER_SECTION = 'task/get-selected-review-filter-section',
  UPDATE_SELECTED_REVIEW_FILTER_SECTION = 'task/update-selected-review-filter-section',
  SET_SEARCH_TEXT = 'task/search_text/set',
  CLEAR = 'task/clear',

  TOGGLE_SEARCH_RESULT = 'search-result/toggle',

  SET_TOKEN_FOR_HIGHLIGHT = 'token-for-highlight/set',

  SET_SELECTED_PARENT_ENTITY_INFO = 'task/set-selected-parent-entity-info',
  SET_SELECTED_ENTITY_IDS_FOR_ANNOTATION = 'task/selected-entity-ids-for-annotation/set',

  UPDATE_ENTITY_INFO_FOR_TABLE_ANNOTATION = 'task/table-annotation/update-entity-info',

  CONFIRM_TABLE_ENTITIES_INFO = 'task/table-entities-info/confirm',
  CLEAR_ALL_TABLE_ENTITIES_INFO = 'task/table-entities-info/clear-all',
  ADD_LOCATION = 'task/table-annotation/add-location',
  LAST_LOCATED_ENTITY_TYPE = 'task/table-annotation/last-located-entity-type',
  UPDATE_ORDER_OF_TABLE_ENTITIES = 'task/table-annotation/update-order',
  HIDE_COLUMN_FROM_TABLE_ANNOTATION = 'task/table-annotation/hide-column',
  SHOW_COLUMNS_FOR_TABLE_ANNOTATION = 'task/table-annotation/show-columns',

  CLEAR_TASK_ANNOTATION_INFO = 'task/annotation-info/clear',
  DELETE_TEXT_SEGMENTS_FROM_ENTITIES = 'task/annotation-info/delete-text-segments',
  UPDATE_ORDER_OF_TABLE_ROWS = 'task/annotation-info/update-order-of-table-rows',

  COLLAPSE_ENTITY_SIDE_PANEL = 'task/collapse-entity-side-panel',
  SET_TOKEN_LIST_TO_HIGHLIGHT = 'token-list-to-highlight/set',

  OPEN_ADD_ROW_MODAL = 'task/open-add-row-modal',
  SET_SUGGESTION_DATA = 'task/set-suggestion-data',
  ADD_SUGGESTED_ROWS = 'task/add-suggested-rows',
  ALLOW_EDITING_TASK = 'task/allow-editing-task',
  TASK_UNDO = 'task/history/undo',
  TASK_REDO = 'task/history/redo',
  CLEAR_TASK_HISTORY = 'task/history/clear',

  // activity
  RECORD_IDLE_SESSION = 'task/idle-sessions/set',
  REVIEW_START_TIME = 'task/review_start_time',

  // HITL
  UPDATE_MATCHES = 'task/update-matches',
}

export enum ArchivedResourceActionType {
  // Listing
  LIST_ARCHIVED_RESOURCES = 'archived_resources/list',
  LIST_ARCHIVED_RESOURCES_COMPLETED = 'archived_resources/list/completed',
  LIST_ARCHIVED_RESOURCES_ERROR = 'archived_resources/list/error',

  // Restoring
  RESTORE_ARCHIVED_RESOURCES = 'archived_resources/restore',
  RESTORE_ARCHIVED_RESOURCES_COMPLETED = 'archived_resources/restore/completed',
  RESTORE_ARCHIVED_RESOURCES_ERROR = 'archived_resources/restore /error',

  RESTORE_DELETED_RESOURCES = 'archived_resources/restore-deleted',
  RESTORE_DELETED_RESOURCES_COMPLETED = 'archived_resources/restore-deleted/completed',
  RESTORE_DELETED_RESOURCES_ERROR = 'archived_resources/restore-deleted/error',
}

export enum OrganizationActionType {
  GET_ORGANIZATION = 'organization/get',
  GET_ORGANIZATION_COMPLETED = 'organization/get/completed',
  GET_ORGANIZATION_ERROR = 'organization/get/error',

  UPDATE_ORGANIZATION = 'organization/update',
  UPDATE_ORGANIZATION_COMPLETED = 'organization/update/completed',
  UPDATE_ORGANIZATION_ERROR = 'organization/update/error',

  CLEAR_UPDATE_STATUS = 'organization/update/clear',

  LIST_HOLIDAY_LISTS = 'organization/list-holiday-lists',
  LIST_HOLIDAY_LISTS_COMPLETED = 'organization/list-holiday-lists/completed',
  LIST_HOLIDAY_LISTS_ERROR = 'organization/list-holiday-lists/error',
  LIST_HOLIDAY_LISTS_IS_LOADING = 'organization/list-holiday-lists/is-loading',

  GET_SINGLE_HOLIDAY = 'organization/get-single-holiday',
  GET_SINGLE_HOLIDAY_COMPLETED = 'organization/get-single-holiday/completed',
  GET_SINGLE_HOLIDAY_ERROR = 'organization/get-single-holiday/error',
  GET_SINGLE_HOLIDAY_IS_LOADING = 'organization/get-single-holiday/is-loading',

  UPSERT_HOLIDAY_LIST = 'organization/upsert-holiday-list',
  UPSERT_HOLIDAY_LIST_COMPLETED = 'organization/upsert-holiday-list/completed',
  UPSERT_HOLIDAY_LIST_ERROR = 'organization/upsert-holiday-list/error',
  UPSERT_HOLIDAY_LIST_LOADING = 'organization/upsert-holiday-list/loading',

  DELETE_HOLIDAY_LIST = 'organization/delete-holiday-list',
  DELETE_HOLIDAY_LIST_COMPLETED = 'organization/delete-holiday-list/completed',
  DELETE_HOLIDAY_LIST_ERROR = 'organization/delete-holiday-list/error',
  DELETE_HOLIDAY_LIST_LOADING = 'organization/delete-holiday-list/loading',
}

export enum FeatureFlagActionType {
  LIST_FEATURE_FLAGS = 'feature_flags/list',
  LIST_FEATURE_FLAGS_COMPLETED = 'feature_flags/list/completed',
  LIST_FEATURE_FLAGS_ERROR = 'feature_flags/list/error',
  CREATE_FEATURE_FLAG = 'feature_flags/create',
  CREATE_FEATURE_FLAG_COMPLETED = 'feature_flags/create/completed',
  CREATE_FEATURE_FLAG_ERROR = 'feature_flags/create/error',
  UPDATE_FEATURE_FLAG = 'feature_flags/update',
  UPDATE_FEATURE_FLAG_COMPLETED = 'feature_flags/update/completed',
  UPDATE_FEATURE_FLAG_ERROR = 'feature_flags/update/error',
  DELETE_FEATURE_FLAG = 'feature_flags/delete',
  DELETE_FEATURE_FLAG_COMPLETED = 'feature_flags/delete/completed',
  DELETE_FEATURE_FLAG_ERROR = 'feature_flags/delete/error',
  GET_FEATURE_FLAG = 'feature_flags/get',
  GET_FEATURE_FLAG_COMPLETED = 'feature_flags/get/completed',
  GET_FEATURE_FLAG_ERROR = 'feature_flags/get/error',
  SET_SELECTED_FEATURE_FLAG = 'feature_flags/set-selected',
  CLEAR = 'feature_flags/clear',

  GET_FEATURE_FLAGS_FOR_ORG_AND_USER = 'feature_flags/org-user/list',
  GET_FEATURE_FLAGS_FOR_ORG_AND_USER_COMPLETED = 'feature_flags/org-user/list/completed',
  GET_FEATURE_FLAGS_FOR_ORG_AND_USER_ERROR = 'feature_flags/org-user/list/error',
  UPDATE_FEATURE_FLAGS_LOADER_FOR_ORG_AND_USER = 'feature_flags/org-user/list/loader/hide',
}

export const WorkflowTaskActionType = {
  GET_WORKFLOW_TASK_SUCCESS: 'workflow_task/get/success',
  GET_WORKFLOW_TASK_FAILURE: 'workflow_task/get/error',
  GET_WORKFLOW_BY_ID: 'workflow_task/workflow/get',
  UPDATE_WORKFLOW_LINKED_TASKS: 'workflow_task/linked_tasks/update',
  GET_WORKFLOW_WITH_TASK: 'workflow_task/workflow_with_task/get',
  CLEAR_WORKFLOW_WITH_TASK: 'workflow_task/workflow_with_task/clear',

  // TODO: Workflow template related state should be tracked separate from Workflow tasks
  LIST_WORKFLOWS_TEMPLATE_ORBOT: 'workflows/templates/orbot/list',
  LIST_WORKFLOWS_TEMPLATE_COMPLETED_ORBOT:
    'workflows/templates/orbot/list/completed',
  LIST_WORKFLOWS_TEMPLATE_ERROR_ORBOT: 'workflows/templates/orbot/list/error',

  GET_WORKFLOW_TEMPLATE_ORBOT: 'workflow/template/orbot/get',
  GET_WORKFLOW_TEMPLATE_COMPLETED_ORBOT:
    'workflow/template/orbot/get/completed',
  GET_WORKFLOW_TEMPLATE_ERROR_ORBOT: 'workflow/template/orbot/get/error',

  CANCEL_EXECUTION: 'workflow_task/cancel',
  CANCEL_EXECUTION_COMPLETED: 'workflow_task/cancel/completed',
  CANCEL_EXECUTION_ERROR: 'workflow_task/cancel/error',
  CANCEL_CHILD_EXECUTIONS: 'workflow_task/cancel-child-executions',
  CANCEL_CHILD_EXECUTIONS_COMPLETED:
    'workflow_task/cancel-child-executions/completed',
  CANCEL_CHILD_EXECUTIONS_ERROR: 'workflow_task/cancel-child-executions/error',
  CLEAR_CANCELLING_EXECUTION: 'workflow_Task/cancel/clear',
};

export const WorkflowDetailsType = {
  SET_WORKFLOW_SUCCESS: 'workflow_details/set/success',
  GET_WORKFLOW_FAILURE: 'workflow_details/get/error',
  FETCH_WORKFLOW: 'workflow_details/fetch',
  UPDATE_WORKFLOW_TITLE: 'workflow_details/title/update',
  SET_HAS_UNSAVED_CHANGES: 'workflow_details/has_unsaved_changes/set',
  ADD_INVALID_CHANGE: 'workflow_details/invalid_change/add',
  DELETE_INVALID_CHANGE: 'workflow_details/invalid_change/delete',
  SET_ACTIONS_FOR_REVIEW: 'workflow_details/review/set',
  SET_ASSIGNMENT_MODE: 'workflow_details/assignment_mode/set',
  SET_LOW_CONFIDENCE_THRESHOLD: 'workflow_details/low_confidence_threshold/set',
  SET_SAMPLE_PERCENTAGE: 'workflow_details/sample_percentage/set',
  ADD_REVIEWER: 'workflow_details/reviewer/add',
  REMOVE_REVIEWER: 'workflow_details/reviewer/remove',
  ADD_REVIEWER_OLD: 'workflow_details/reviewer/add/old', // TODO: remove this once the new settings are implemented
  REMOVE_REVIEWER_OLD: 'workflow_details/reviewer/remove/old', // TODO: remove this once the new settings are implemented
  CLEAR_SECOND_ROUND_REVIEWER: 'workflow_details/reviewer/clear-second-round',
  ADD_ADMIN: 'workflow_details/admin/add',
  REMOVE_ADMIN: 'workflow_details/admin/remove',
  SAVE_WORKFLOW: 'workflow_details/save',
  SAVE_WORKFLOW_COMPLETED: 'workflow_details/save/completed',
  SAVE_WORKFLOW_ERROR: 'workflow_details/save/error',
  CLEAR_SAVE_WORKFLOW: 'workflow_details/save/clear',
  SAVE_CURRENT_WORKFLOW: 'workflow_details/save/current',
  SET_SAVE_WORKFLOW_ERROR_OBJECT: 'workflow_details/save/error/object',
  CLEAR_SAVE_WORKFLOW_ERROR_OBJECT: 'workflow_details/save/error/object/clear',
  ADD_SECOND_ROUND_REVIEW_LIST: 'workflow_details/second_round_reviewer/add',
  SEND_INVITE_TO_ADMINS: 'workflow_details/admins/invite',
  SEND_INVITE_TO_ADMINS_COMPLETED: 'workflow_details/admins/invite/completed',

  CHANGE_LOADING_STATUS: 'workflow_details/loading_status/update',
  ADD_ACTION_AFTER: 'workflow_details/action/after/add',
  UPDATE_ACTION: 'workflow_details/action/update',
  REMOVE_ACTION: 'workflow_details/action/remove',
  ADD_FALSE_CONDITION_ACTION: 'workflow_details/false_condition_action/add',
  FETCH_ACTIONABLE_ELEMENTS: 'workflow_details/actionable_elements/fetch',

  SET_SCHEDULES: 'workflow_details/schedules/set',
  FETCH_SCHEDULES: 'workflow_details/schedules/fetch',
  CHANGE_SCHEDULES_LOADING_STATUS: 'workflow_details/schedules/loading/change',
  FETCH_SCHEDULES_ERROR: 'workflow_details/schedules/fetch/error',
  CREATE_SCHEDULE: 'workflow_details/schedule/create',
  CREATE_SCHEDULE_LOADING: 'workflow_details/schedule/create/loading',
  CREATE_SCHEDULE_ERROR: 'workflow_details/schedule/create/error',
  UPDATE_SECRET_IDS_IN_WORKFLOW: 'workflow_details/secret_ids/update',
  CREATE_UPDATE_SCHEDULE_LOADING:
    'workflow_details/schedule/create/update/loading',
  CREATE_UPDATE_SCHEDULE_ERROR: 'workflow_details/schedule/create/update/error',
  DELETE_SCHEDULE: 'workflow_details/schedule/delete',
  DELETE_SCHEDULE_ERROR: 'workflow_details/schedule/delete/error',
  FETCH_SCHEDULE: 'workflow_details/schedule/fetch',
  SET_SCHEDULE: 'workflow_details/schedule/set',
  UPDATE_SCHEDULE: 'workflow_details/schedule/update',
};

export enum WorkflowSecretActionType {
  FETCH_SECRETS_FROM_WORKFLOW = 'workflow_secrets/fetch_secrets_from_workflow',
  SET_SECRETS_FROM_WORKFLOW = 'workflow_secrets/set_secrets_from_workflow',
  CREATE_SECRET_BLOCK = 'workflow_secrets/create_secret_block',
  CREATE_SECRET_BLOCK_COMPLETED = 'workflow_secrets/create_secret_block/completed',
  CREATE_SECRET_BLOCK_ERROR = 'workflow_secrets/create_secret_block/error',
  UPDATE_SECRET_BLOCK = 'workflow_secrets/update_secret_block',
  UPDATE_SECRET_BLOCK_COMPLETED = 'workflow_secrets/update_secret_block/completed',
  UPDATE_SECRET_BLOCK_ERROR = 'workflow_secrets/update_secret_block/error',
  DELETE_SECRET_BLOCK = 'workflow_secrets/delete_secret_block',
}
