/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { messageTypeRegistry } from "../../typeRegistry";
import { Element, ElementLocator } from "./element";
import { Action, ExecutedAction, PageContent, PreparedAction } from "./orbot_action";
import { Workflow } from "./orbot_workflow";

export const protobufPackage = "pb.v1alpha1";

export interface GetElementLocatorsRequest {
  $type?: "pb.v1alpha1.GetElementLocatorsRequest";
  workflowId?: string | undefined;
  actionId?: string | undefined;
}

export interface GetElementLocatorsResponse {
  $type?: "pb.v1alpha1.GetElementLocatorsResponse";
  elementLocators?: ElementLocator[] | undefined;
}

export interface LocateElementRequest {
  $type?: "pb.v1alpha1.LocateElementRequest";
  /**
   * Contains the full HTML page, each element in the page has a unique ID assigned by the extension.
   * Deprecated, use root_element instead
   *
   * @deprecated
   */
  pageContent?: PageContent | undefined;
  rootElement?:
    | Element
    | undefined;
  /**
   * The action that request to locate the element, which includes the ElementLocator.
   *
   * @deprecated
   */
  action?: PreparedAction | undefined;
  currentAction?:
    | Action
    | undefined;
  /** The full workflow contains name/description and other actions as context. */
  workflow?:
    | Workflow
    | undefined;
  /** populated on the server side before sending to ML. */
  previousExecutions?:
    | LocateElementRequestPreviousExecution[]
    | undefined;
  /** Natural language description of the action */
  description?:
    | string
    | undefined;
  /** Parameter used internally for LLM API monitoring */
  bypassLlmCache?: boolean | undefined;
  pipelineType?: LocateElementRequestPipelineType | undefined;
}

/**
 * The pipeline (model) type to be used to locate the element.
 * TODO; reconsider the name of this enum and the values.
 */
export enum LocateElementRequestPipelineType {
  UNSPECIFIED = 0,
  TEXT_GPT_4_TURBO_0125_PREVIEW = 1,
  VISION_ORBY_ACTIO_0_1 = 2,
  ORBY_ACTIO_ACTIONFINDER_0_0_1 = 3,
  UNRECOGNIZED = -1,
}

export function locateElementRequestPipelineTypeFromJSON(object: any): LocateElementRequestPipelineType {
  switch (object) {
    case 0:
    case "PIPELINE_TYPE_UNSPECIFIED":
      return LocateElementRequestPipelineType.UNSPECIFIED;
    case 1:
    case "TEXT_GPT_4_TURBO_0125_PREVIEW":
      return LocateElementRequestPipelineType.TEXT_GPT_4_TURBO_0125_PREVIEW;
    case 2:
    case "VISION_ORBY_ACTIO_0_1":
      return LocateElementRequestPipelineType.VISION_ORBY_ACTIO_0_1;
    case 3:
    case "ORBY_ACTIO_ACTIONFINDER_0_0_1":
      return LocateElementRequestPipelineType.ORBY_ACTIO_ACTIONFINDER_0_0_1;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LocateElementRequestPipelineType.UNRECOGNIZED;
  }
}

export function locateElementRequestPipelineTypeToJSON(object: LocateElementRequestPipelineType): string {
  switch (object) {
    case LocateElementRequestPipelineType.UNSPECIFIED:
      return "PIPELINE_TYPE_UNSPECIFIED";
    case LocateElementRequestPipelineType.TEXT_GPT_4_TURBO_0125_PREVIEW:
      return "TEXT_GPT_4_TURBO_0125_PREVIEW";
    case LocateElementRequestPipelineType.VISION_ORBY_ACTIO_0_1:
      return "VISION_ORBY_ACTIO_0_1";
    case LocateElementRequestPipelineType.ORBY_ACTIO_ACTIONFINDER_0_0_1:
      return "ORBY_ACTIO_ACTIONFINDER_0_0_1";
    case LocateElementRequestPipelineType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** Contains the information from previous successful executions */
export interface LocateElementRequestPreviousExecution {
  $type?: "pb.v1alpha1.LocateElementRequest.PreviousExecution";
  /** ExecutedActions contain the ElementLocator and the located element during execution. */
  action?:
    | ExecutedAction
    | undefined;
  /**
   * The full page content during action execution.
   *
   * @deprecated
   */
  pageContent?: PageContent | undefined;
  rootElement?: Element | undefined;
}

export interface LocateElementResponse {
  $type?: "pb.v1alpha1.LocateElementResponse";
  /** The unique ID (generated by Orby) for the located element in PageContent. */
  elementId?: string | undefined;
  confidence?: number | undefined;
  error?: LocateElementResponseLocateElementError | undefined;
}

export enum LocateElementResponseLocateElementError {
  ERROR_UNSPECIFIED = 0,
  ACTION_NOT_SUPPORTED = 1,
  ERROR_UNKNOWN = 2,
  UNRECOGNIZED = -1,
}

export function locateElementResponseLocateElementErrorFromJSON(object: any): LocateElementResponseLocateElementError {
  switch (object) {
    case 0:
    case "ERROR_UNSPECIFIED":
      return LocateElementResponseLocateElementError.ERROR_UNSPECIFIED;
    case 1:
    case "ACTION_NOT_SUPPORTED":
      return LocateElementResponseLocateElementError.ACTION_NOT_SUPPORTED;
    case 2:
    case "ERROR_UNKNOWN":
      return LocateElementResponseLocateElementError.ERROR_UNKNOWN;
    case -1:
    case "UNRECOGNIZED":
    default:
      return LocateElementResponseLocateElementError.UNRECOGNIZED;
  }
}

export function locateElementResponseLocateElementErrorToJSON(object: LocateElementResponseLocateElementError): string {
  switch (object) {
    case LocateElementResponseLocateElementError.ERROR_UNSPECIFIED:
      return "ERROR_UNSPECIFIED";
    case LocateElementResponseLocateElementError.ACTION_NOT_SUPPORTED:
      return "ACTION_NOT_SUPPORTED";
    case LocateElementResponseLocateElementError.ERROR_UNKNOWN:
      return "ERROR_UNKNOWN";
    case LocateElementResponseLocateElementError.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface ReportWorkingElementLocatorRequest {
  $type?: "pb.v1alpha1.ReportWorkingElementLocatorRequest";
  workflowId?: string | undefined;
  actionId?: string | undefined;
  elementLocator?: ElementLocator | undefined;
}

/**
 * Used to construct examples of ElementLocatorRequests and their expected results together
 * for test cases and evaluation datasets.
 */
export interface LocateElementExample {
  $type?: "pb.v1alpha1.LocateElementExample";
  request?: LocateElementRequest | undefined;
  response?: LocateElementResponse | undefined;
}

/** Group a set of LocateElementExamples with an optional name. */
export interface LocateElementExamples {
  $type?: "pb.v1alpha1.LocateElementExamples";
  /** identify the set of examples. */
  name?: string | undefined;
  examples?: LocateElementExample[] | undefined;
}

function createBaseGetElementLocatorsRequest(): GetElementLocatorsRequest {
  return { $type: "pb.v1alpha1.GetElementLocatorsRequest", workflowId: "", actionId: "" };
}

export const GetElementLocatorsRequest = {
  $type: "pb.v1alpha1.GetElementLocatorsRequest" as const,

  encode(message: GetElementLocatorsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.workflowId !== undefined && message.workflowId !== "") {
      writer.uint32(10).string(message.workflowId);
    }
    if (message.actionId !== undefined && message.actionId !== "") {
      writer.uint32(18).string(message.actionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetElementLocatorsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetElementLocatorsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.workflowId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.actionId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetElementLocatorsRequest {
    return {
      $type: GetElementLocatorsRequest.$type,
      workflowId: isSet(object.workflowId) ? globalThis.String(object.workflowId) : "",
      actionId: isSet(object.actionId) ? globalThis.String(object.actionId) : "",
    };
  },

  toJSON(message: GetElementLocatorsRequest): unknown {
    const obj: any = {};
    if (message.workflowId !== undefined && message.workflowId !== "") {
      obj.workflowId = message.workflowId;
    }
    if (message.actionId !== undefined && message.actionId !== "") {
      obj.actionId = message.actionId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetElementLocatorsRequest>, I>>(base?: I): GetElementLocatorsRequest {
    return GetElementLocatorsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetElementLocatorsRequest>, I>>(object: I): GetElementLocatorsRequest {
    const message = createBaseGetElementLocatorsRequest();
    message.workflowId = object.workflowId ?? "";
    message.actionId = object.actionId ?? "";
    return message;
  },
};

messageTypeRegistry.set(GetElementLocatorsRequest.$type, GetElementLocatorsRequest);

function createBaseGetElementLocatorsResponse(): GetElementLocatorsResponse {
  return { $type: "pb.v1alpha1.GetElementLocatorsResponse", elementLocators: [] };
}

export const GetElementLocatorsResponse = {
  $type: "pb.v1alpha1.GetElementLocatorsResponse" as const,

  encode(message: GetElementLocatorsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.elementLocators !== undefined && message.elementLocators.length !== 0) {
      for (const v of message.elementLocators) {
        ElementLocator.encode(v!, writer.uint32(10).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetElementLocatorsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetElementLocatorsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.elementLocators!.push(ElementLocator.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetElementLocatorsResponse {
    return {
      $type: GetElementLocatorsResponse.$type,
      elementLocators: globalThis.Array.isArray(object?.elementLocators)
        ? object.elementLocators.map((e: any) => ElementLocator.fromJSON(e))
        : [],
    };
  },

  toJSON(message: GetElementLocatorsResponse): unknown {
    const obj: any = {};
    if (message.elementLocators?.length) {
      obj.elementLocators = message.elementLocators.map((e) => ElementLocator.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetElementLocatorsResponse>, I>>(base?: I): GetElementLocatorsResponse {
    return GetElementLocatorsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetElementLocatorsResponse>, I>>(object: I): GetElementLocatorsResponse {
    const message = createBaseGetElementLocatorsResponse();
    message.elementLocators = object.elementLocators?.map((e) => ElementLocator.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(GetElementLocatorsResponse.$type, GetElementLocatorsResponse);

function createBaseLocateElementRequest(): LocateElementRequest {
  return {
    $type: "pb.v1alpha1.LocateElementRequest",
    pageContent: undefined,
    rootElement: undefined,
    action: undefined,
    currentAction: undefined,
    workflow: undefined,
    previousExecutions: [],
    description: "",
    bypassLlmCache: false,
    pipelineType: 0,
  };
}

export const LocateElementRequest = {
  $type: "pb.v1alpha1.LocateElementRequest" as const,

  encode(message: LocateElementRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageContent !== undefined) {
      PageContent.encode(message.pageContent, writer.uint32(10).fork()).ldelim();
    }
    if (message.rootElement !== undefined) {
      Element.encode(message.rootElement, writer.uint32(66).fork()).ldelim();
    }
    if (message.action !== undefined) {
      PreparedAction.encode(message.action, writer.uint32(18).fork()).ldelim();
    }
    if (message.currentAction !== undefined) {
      Action.encode(message.currentAction, writer.uint32(58).fork()).ldelim();
    }
    if (message.workflow !== undefined) {
      Workflow.encode(message.workflow, writer.uint32(26).fork()).ldelim();
    }
    if (message.previousExecutions !== undefined && message.previousExecutions.length !== 0) {
      for (const v of message.previousExecutions) {
        LocateElementRequestPreviousExecution.encode(v!, writer.uint32(34).fork()).ldelim();
      }
    }
    if (message.description !== undefined && message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    if (message.bypassLlmCache !== undefined && message.bypassLlmCache !== false) {
      writer.uint32(48).bool(message.bypassLlmCache);
    }
    if (message.pipelineType !== undefined && message.pipelineType !== 0) {
      writer.uint32(72).int32(message.pipelineType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LocateElementRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocateElementRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pageContent = PageContent.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.rootElement = Element.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.action = PreparedAction.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.currentAction = Action.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.workflow = Workflow.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.previousExecutions!.push(LocateElementRequestPreviousExecution.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.bypassLlmCache = reader.bool();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.pipelineType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LocateElementRequest {
    return {
      $type: LocateElementRequest.$type,
      pageContent: isSet(object.pageContent) ? PageContent.fromJSON(object.pageContent) : undefined,
      rootElement: isSet(object.rootElement) ? Element.fromJSON(object.rootElement) : undefined,
      action: isSet(object.action) ? PreparedAction.fromJSON(object.action) : undefined,
      currentAction: isSet(object.currentAction) ? Action.fromJSON(object.currentAction) : undefined,
      workflow: isSet(object.workflow) ? Workflow.fromJSON(object.workflow) : undefined,
      previousExecutions: globalThis.Array.isArray(object?.previousExecutions)
        ? object.previousExecutions.map((e: any) => LocateElementRequestPreviousExecution.fromJSON(e))
        : [],
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      bypassLlmCache: isSet(object.bypassLlmCache) ? globalThis.Boolean(object.bypassLlmCache) : false,
      pipelineType: isSet(object.pipelineType) ? locateElementRequestPipelineTypeFromJSON(object.pipelineType) : 0,
    };
  },

  toJSON(message: LocateElementRequest): unknown {
    const obj: any = {};
    if (message.pageContent !== undefined) {
      obj.pageContent = PageContent.toJSON(message.pageContent);
    }
    if (message.rootElement !== undefined) {
      obj.rootElement = Element.toJSON(message.rootElement);
    }
    if (message.action !== undefined) {
      obj.action = PreparedAction.toJSON(message.action);
    }
    if (message.currentAction !== undefined) {
      obj.currentAction = Action.toJSON(message.currentAction);
    }
    if (message.workflow !== undefined) {
      obj.workflow = Workflow.toJSON(message.workflow);
    }
    if (message.previousExecutions?.length) {
      obj.previousExecutions = message.previousExecutions.map((e) => LocateElementRequestPreviousExecution.toJSON(e));
    }
    if (message.description !== undefined && message.description !== "") {
      obj.description = message.description;
    }
    if (message.bypassLlmCache !== undefined && message.bypassLlmCache !== false) {
      obj.bypassLlmCache = message.bypassLlmCache;
    }
    if (message.pipelineType !== undefined && message.pipelineType !== 0) {
      obj.pipelineType = locateElementRequestPipelineTypeToJSON(message.pipelineType);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LocateElementRequest>, I>>(base?: I): LocateElementRequest {
    return LocateElementRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LocateElementRequest>, I>>(object: I): LocateElementRequest {
    const message = createBaseLocateElementRequest();
    message.pageContent = (object.pageContent !== undefined && object.pageContent !== null)
      ? PageContent.fromPartial(object.pageContent)
      : undefined;
    message.rootElement = (object.rootElement !== undefined && object.rootElement !== null)
      ? Element.fromPartial(object.rootElement)
      : undefined;
    message.action = (object.action !== undefined && object.action !== null)
      ? PreparedAction.fromPartial(object.action)
      : undefined;
    message.currentAction = (object.currentAction !== undefined && object.currentAction !== null)
      ? Action.fromPartial(object.currentAction)
      : undefined;
    message.workflow = (object.workflow !== undefined && object.workflow !== null)
      ? Workflow.fromPartial(object.workflow)
      : undefined;
    message.previousExecutions =
      object.previousExecutions?.map((e) => LocateElementRequestPreviousExecution.fromPartial(e)) || [];
    message.description = object.description ?? "";
    message.bypassLlmCache = object.bypassLlmCache ?? false;
    message.pipelineType = object.pipelineType ?? 0;
    return message;
  },
};

messageTypeRegistry.set(LocateElementRequest.$type, LocateElementRequest);

function createBaseLocateElementRequestPreviousExecution(): LocateElementRequestPreviousExecution {
  return {
    $type: "pb.v1alpha1.LocateElementRequest.PreviousExecution",
    action: undefined,
    pageContent: undefined,
    rootElement: undefined,
  };
}

export const LocateElementRequestPreviousExecution = {
  $type: "pb.v1alpha1.LocateElementRequest.PreviousExecution" as const,

  encode(message: LocateElementRequestPreviousExecution, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.action !== undefined) {
      ExecutedAction.encode(message.action, writer.uint32(18).fork()).ldelim();
    }
    if (message.pageContent !== undefined) {
      PageContent.encode(message.pageContent, writer.uint32(10).fork()).ldelim();
    }
    if (message.rootElement !== undefined) {
      Element.encode(message.rootElement, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LocateElementRequestPreviousExecution {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocateElementRequestPreviousExecution();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.action = ExecutedAction.decode(reader, reader.uint32());
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pageContent = PageContent.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.rootElement = Element.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LocateElementRequestPreviousExecution {
    return {
      $type: LocateElementRequestPreviousExecution.$type,
      action: isSet(object.action) ? ExecutedAction.fromJSON(object.action) : undefined,
      pageContent: isSet(object.pageContent) ? PageContent.fromJSON(object.pageContent) : undefined,
      rootElement: isSet(object.rootElement) ? Element.fromJSON(object.rootElement) : undefined,
    };
  },

  toJSON(message: LocateElementRequestPreviousExecution): unknown {
    const obj: any = {};
    if (message.action !== undefined) {
      obj.action = ExecutedAction.toJSON(message.action);
    }
    if (message.pageContent !== undefined) {
      obj.pageContent = PageContent.toJSON(message.pageContent);
    }
    if (message.rootElement !== undefined) {
      obj.rootElement = Element.toJSON(message.rootElement);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LocateElementRequestPreviousExecution>, I>>(
    base?: I,
  ): LocateElementRequestPreviousExecution {
    return LocateElementRequestPreviousExecution.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LocateElementRequestPreviousExecution>, I>>(
    object: I,
  ): LocateElementRequestPreviousExecution {
    const message = createBaseLocateElementRequestPreviousExecution();
    message.action = (object.action !== undefined && object.action !== null)
      ? ExecutedAction.fromPartial(object.action)
      : undefined;
    message.pageContent = (object.pageContent !== undefined && object.pageContent !== null)
      ? PageContent.fromPartial(object.pageContent)
      : undefined;
    message.rootElement = (object.rootElement !== undefined && object.rootElement !== null)
      ? Element.fromPartial(object.rootElement)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(LocateElementRequestPreviousExecution.$type, LocateElementRequestPreviousExecution);

function createBaseLocateElementResponse(): LocateElementResponse {
  return { $type: "pb.v1alpha1.LocateElementResponse", elementId: "", confidence: 0, error: 0 };
}

export const LocateElementResponse = {
  $type: "pb.v1alpha1.LocateElementResponse" as const,

  encode(message: LocateElementResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.elementId !== undefined && message.elementId !== "") {
      writer.uint32(10).string(message.elementId);
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      writer.uint32(21).float(message.confidence);
    }
    if (message.error !== undefined && message.error !== 0) {
      writer.uint32(24).int32(message.error);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LocateElementResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocateElementResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.elementId = reader.string();
          continue;
        case 2:
          if (tag !== 21) {
            break;
          }

          message.confidence = reader.float();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.error = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LocateElementResponse {
    return {
      $type: LocateElementResponse.$type,
      elementId: isSet(object.elementId) ? globalThis.String(object.elementId) : "",
      confidence: isSet(object.confidence) ? globalThis.Number(object.confidence) : 0,
      error: isSet(object.error) ? locateElementResponseLocateElementErrorFromJSON(object.error) : 0,
    };
  },

  toJSON(message: LocateElementResponse): unknown {
    const obj: any = {};
    if (message.elementId !== undefined && message.elementId !== "") {
      obj.elementId = message.elementId;
    }
    if (message.confidence !== undefined && message.confidence !== 0) {
      obj.confidence = message.confidence;
    }
    if (message.error !== undefined && message.error !== 0) {
      obj.error = locateElementResponseLocateElementErrorToJSON(message.error);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LocateElementResponse>, I>>(base?: I): LocateElementResponse {
    return LocateElementResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LocateElementResponse>, I>>(object: I): LocateElementResponse {
    const message = createBaseLocateElementResponse();
    message.elementId = object.elementId ?? "";
    message.confidence = object.confidence ?? 0;
    message.error = object.error ?? 0;
    return message;
  },
};

messageTypeRegistry.set(LocateElementResponse.$type, LocateElementResponse);

function createBaseReportWorkingElementLocatorRequest(): ReportWorkingElementLocatorRequest {
  return {
    $type: "pb.v1alpha1.ReportWorkingElementLocatorRequest",
    workflowId: "",
    actionId: "",
    elementLocator: undefined,
  };
}

export const ReportWorkingElementLocatorRequest = {
  $type: "pb.v1alpha1.ReportWorkingElementLocatorRequest" as const,

  encode(message: ReportWorkingElementLocatorRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.workflowId !== undefined && message.workflowId !== "") {
      writer.uint32(10).string(message.workflowId);
    }
    if (message.actionId !== undefined && message.actionId !== "") {
      writer.uint32(18).string(message.actionId);
    }
    if (message.elementLocator !== undefined) {
      ElementLocator.encode(message.elementLocator, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ReportWorkingElementLocatorRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseReportWorkingElementLocatorRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.workflowId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.actionId = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.elementLocator = ElementLocator.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ReportWorkingElementLocatorRequest {
    return {
      $type: ReportWorkingElementLocatorRequest.$type,
      workflowId: isSet(object.workflowId) ? globalThis.String(object.workflowId) : "",
      actionId: isSet(object.actionId) ? globalThis.String(object.actionId) : "",
      elementLocator: isSet(object.elementLocator) ? ElementLocator.fromJSON(object.elementLocator) : undefined,
    };
  },

  toJSON(message: ReportWorkingElementLocatorRequest): unknown {
    const obj: any = {};
    if (message.workflowId !== undefined && message.workflowId !== "") {
      obj.workflowId = message.workflowId;
    }
    if (message.actionId !== undefined && message.actionId !== "") {
      obj.actionId = message.actionId;
    }
    if (message.elementLocator !== undefined) {
      obj.elementLocator = ElementLocator.toJSON(message.elementLocator);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ReportWorkingElementLocatorRequest>, I>>(
    base?: I,
  ): ReportWorkingElementLocatorRequest {
    return ReportWorkingElementLocatorRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ReportWorkingElementLocatorRequest>, I>>(
    object: I,
  ): ReportWorkingElementLocatorRequest {
    const message = createBaseReportWorkingElementLocatorRequest();
    message.workflowId = object.workflowId ?? "";
    message.actionId = object.actionId ?? "";
    message.elementLocator = (object.elementLocator !== undefined && object.elementLocator !== null)
      ? ElementLocator.fromPartial(object.elementLocator)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(ReportWorkingElementLocatorRequest.$type, ReportWorkingElementLocatorRequest);

function createBaseLocateElementExample(): LocateElementExample {
  return { $type: "pb.v1alpha1.LocateElementExample", request: undefined, response: undefined };
}

export const LocateElementExample = {
  $type: "pb.v1alpha1.LocateElementExample" as const,

  encode(message: LocateElementExample, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.request !== undefined) {
      LocateElementRequest.encode(message.request, writer.uint32(10).fork()).ldelim();
    }
    if (message.response !== undefined) {
      LocateElementResponse.encode(message.response, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LocateElementExample {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocateElementExample();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.request = LocateElementRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.response = LocateElementResponse.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LocateElementExample {
    return {
      $type: LocateElementExample.$type,
      request: isSet(object.request) ? LocateElementRequest.fromJSON(object.request) : undefined,
      response: isSet(object.response) ? LocateElementResponse.fromJSON(object.response) : undefined,
    };
  },

  toJSON(message: LocateElementExample): unknown {
    const obj: any = {};
    if (message.request !== undefined) {
      obj.request = LocateElementRequest.toJSON(message.request);
    }
    if (message.response !== undefined) {
      obj.response = LocateElementResponse.toJSON(message.response);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LocateElementExample>, I>>(base?: I): LocateElementExample {
    return LocateElementExample.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LocateElementExample>, I>>(object: I): LocateElementExample {
    const message = createBaseLocateElementExample();
    message.request = (object.request !== undefined && object.request !== null)
      ? LocateElementRequest.fromPartial(object.request)
      : undefined;
    message.response = (object.response !== undefined && object.response !== null)
      ? LocateElementResponse.fromPartial(object.response)
      : undefined;
    return message;
  },
};

messageTypeRegistry.set(LocateElementExample.$type, LocateElementExample);

function createBaseLocateElementExamples(): LocateElementExamples {
  return { $type: "pb.v1alpha1.LocateElementExamples", name: "", examples: [] };
}

export const LocateElementExamples = {
  $type: "pb.v1alpha1.LocateElementExamples" as const,

  encode(message: LocateElementExamples, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== undefined && message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.examples !== undefined && message.examples.length !== 0) {
      for (const v of message.examples) {
        LocateElementExample.encode(v!, writer.uint32(18).fork()).ldelim();
      }
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LocateElementExamples {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocateElementExamples();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.examples!.push(LocateElementExample.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LocateElementExamples {
    return {
      $type: LocateElementExamples.$type,
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      examples: globalThis.Array.isArray(object?.examples)
        ? object.examples.map((e: any) => LocateElementExample.fromJSON(e))
        : [],
    };
  },

  toJSON(message: LocateElementExamples): unknown {
    const obj: any = {};
    if (message.name !== undefined && message.name !== "") {
      obj.name = message.name;
    }
    if (message.examples?.length) {
      obj.examples = message.examples.map((e) => LocateElementExample.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<LocateElementExamples>, I>>(base?: I): LocateElementExamples {
    return LocateElementExamples.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<LocateElementExamples>, I>>(object: I): LocateElementExamples {
    const message = createBaseLocateElementExamples();
    message.name = object.name ?? "";
    message.examples = object.examples?.map((e) => LocateElementExample.fromPartial(e)) || [];
    return message;
  },
};

messageTypeRegistry.set(LocateElementExamples.$type, LocateElementExamples);

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in Exclude<keyof T, "$type">]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P> | "$type">]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
