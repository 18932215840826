import React from 'react';
import { IconButton } from '@mui/material';
import { OrbyColorPalette } from 'orby-ui/src';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

const ThreeDotActionMenu = ({
  onClick,
  iconSx = { color: OrbyColorPalette['grey-400'] },
  key = '',
  disabled = false,
  tabIndex = 0,
  title = 'Menu',
  ariaLabel = 'Menu',
}: {
  onClick?: (
    event:
      | React.MouseEvent<HTMLButtonElement | HTMLDivElement>
      | React.KeyboardEvent<HTMLDivElement>,
  ) => void;
  iconSx?: React.CSSProperties;
  key?: string;
  disabled?: boolean;
  tabIndex?: number;
  title?: string;
  ariaLabel?: string;
}) => {
  return (
    <IconButton
      title={title}
      aria-label={ariaLabel}
      key={key}
      disabled={disabled}
      tabIndex={tabIndex}
      onClick={(event) => {
        event.stopPropagation();
        onClick?.(event);
      }}
      style={{
        cursor: 'pointer',
      }}
    >
      <MoreVertOutlinedIcon sx={iconSx} />
    </IconButton>
  );
};

export default React.memo(ThreeDotActionMenu);
