import { WorkflowDetailsType } from '../actions/actions.constants';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { select } from 'typed-redux-saga';
import { orbotService } from '../../services/OrbotService';
import {
  getWorkflowFailure,
  setWorkflowSuccess,
  setHasUnsavedChanges,
  setLoading,
  setSchedules,
  changeSchedulesLoadingStatus,
  createWorkflowScheduleLoading,
  createUpdateWorkflowScheduleError,
  deleteWorkflowScheduleError,
  setWorkflowSchedule,
  getSchedulesError,
  setSaveWorkflowCompleted,
  setSaveWorkflowError,
  setSendInviteToAdminsCompleted,
} from '../actions/workflow_details.constants';
import {
  GetWorkflowRequest,
  UpdateWorkflowRequest,
} from 'protos/pb/v1alpha1/orbot_service';
import { Workflow } from 'protos/pb/v1alpha1/orbot_workflow';
import {
  CreateScheduleRequest,
  ListSchedulesRequest,
  DeleteScheduleRequest,
  UpdateScheduleRequest,
  GetScheduleRequest,
} from 'protos/pb/v1alpha1/schedule_service';
import { workflowSchedulerService } from '../../services/WorkflowSchedulerService';
import { fetchSecretsFromWorkflowAction } from '../actions/workflow_secrets.actions';
import { workflowSelector } from '../selectors/workflow_details.selectors';
import { saveWorkflow as saveWorkflowAction } from '../actions/workflow_details.constants';

export interface GetOrbotWorkflow {
  type: typeof WorkflowDetailsType.FETCH_WORKFLOW;
  payload: { req: GetWorkflowRequest };
}

function* getOrbotWorkflow(action: GetOrbotWorkflow): any {
  try {
    yield put(setLoading(true));
    const { response: workflow, error } = yield call(
      orbotService.getWorkflowById,
      action.payload.req,
    );

    if (workflow) {
      yield put(fetchSecretsFromWorkflowAction({ workflow })); // Fetch the secrets associated with the wf
      yield put(setWorkflowSuccess(workflow)); // Dispatch success action with response
    } else {
      yield put(getWorkflowFailure(error as Error)); // Dispatch failure action with error
    }
  } catch (error) {
    yield put(getWorkflowFailure(error as Error)); // Dispatch failure action if any unexpected error occurs
  } finally {
    yield put(setLoading(false));
  }
}

export interface SaveWorkflowRequest {
  type: typeof WorkflowDetailsType.SAVE_WORKFLOW;
  payload: { workflow: Workflow; orgId: string | undefined };
}

function* saveWorkflow(action: SaveWorkflowRequest): any {
  if (!action.payload?.workflow) {
    yield put(setSaveWorkflowError());
    throw Error('Workflow is not set');
  }

  try {
    yield put(setLoading(true));

    // API call to update workflow
    const request: UpdateWorkflowRequest = {
      // We will just revert the workflow migration until everything has been moved to the new Action object
      workflow: action.payload?.workflow,
      updateMask: [
        'display_name',
        'task_execution.generated_action_groups',
        'processes',
        'actions_for_review',
        'low_confidence_threshold',
        'reviewer_ids',
        'admin_ids',
        'reviewer_lists',
      ],
      orgId: action.payload?.orgId,
    };

    const updatedWorkflow: Workflow = yield call(
      orbotService.updateWorkflow,
      request,
    );

    yield put(setSaveWorkflowCompleted());
    yield put(setWorkflowSuccess(updatedWorkflow));
    yield put(setHasUnsavedChanges(false));
  } catch {
    // TODO: Add a proper error handling
  } finally {
    yield put(setLoading(false));
  }
}

export interface SendInviteToAdminsRequest {
  type: typeof WorkflowDetailsType.SEND_INVITE_TO_ADMINS;
  payload: {
    workflow: Workflow;
    message: string;
    adminIds: string[];
    orgId: string;
  };
}

function* sendInviteToAdmins(action: SendInviteToAdminsRequest): any {
  if (!action.payload?.workflow) {
    yield put(setSaveWorkflowError());
    throw Error('Workflow is not set');
  }

  try {
    yield put(setLoading(true));

    // API call to update workflow
    const request: UpdateWorkflowRequest = {
      // We will just revert the workflow migration until everything has been moved to the new Action object
      workflow: {
        ...action.payload?.workflow,
        adminEmailConfig: {
          emailMessage: action.payload?.message,
          sendEmailToAdmins: true,
        },
        adminIds: [
          ...(action.payload?.workflow.adminIds || []),
          ...(action.payload?.adminIds || []),
        ],
      },
      updateMask: [
        'display_name',
        'task_execution.generated_action_groups',
        'processes',
        'actions_for_review',
        'low_confidence_threshold',
        'reviewer_ids',
        'admin_ids',
        'reviewer_lists',
      ],
      orgId: action.payload?.orgId,
    };

    const updatedWorkflow: Workflow = yield call(
      orbotService.updateWorkflow,
      request,
    );

    yield put(setSendInviteToAdminsCompleted());
    yield put(setWorkflowSuccess(updatedWorkflow));
    yield put(setHasUnsavedChanges(false));
  } catch {
    // TODO: Add a proper error handling
    yield put(setSaveWorkflowError());
  } finally {
    yield put(setLoading(false));
  }
}

// Function to save the current workflow that is in store in redux.
// for safety, we should prefer this over the saveWorkflow function above,
// to keep things consistent and ensure we're always fetching from redux store
function* saveCurrentWorkflow() {
  const workflow = yield* select(workflowSelector);
  if (!workflow) {
    throw Error('Workflow is not set');
  }
  yield put(saveWorkflowAction(workflow, workflow.orgId));
}

// The below function is used to fetch the schedules
export interface GetSchedulesRequest {
  type: typeof WorkflowDetailsType.FETCH_SCHEDULES;
  payload: { req: ListSchedulesRequest; reset: boolean };
}

export function* getSchedules(action: GetSchedulesRequest): any {
  try {
    yield put(changeSchedulesLoadingStatus(true));
    const { response: schedules, error } = yield call(
      workflowSchedulerService.getSchedules,
      action.payload.req,
    );

    if (schedules) {
      yield put(setSchedules(schedules, action.payload.reset));
    } else {
      yield put(
        getSchedulesError(
          new Error(error?.message || 'Failed to fetch schedules'),
        ),
      );
    }
  } catch (error) {
    yield put(getSchedulesError(error as Error));
  } finally {
    yield put(changeSchedulesLoadingStatus(false));
  }
}

export interface CreateWorkflowScheduleRequest {
  type: typeof WorkflowDetailsType.CREATE_SCHEDULE;
  payload: { req: CreateScheduleRequest; refreshResults?: () => void };
}

export function* createWorkflowSchedule(
  action: CreateWorkflowScheduleRequest,
): any {
  try {
    if (!action.payload?.req) {
      throw Error('Request is not set');
    }
    yield put(createWorkflowScheduleLoading(true));
    const apiResponse = yield call(
      workflowSchedulerService.createSchedule,
      action.payload.req,
      action.payload.refreshResults,
    );

    if (apiResponse?.error) {
      yield put(createUpdateWorkflowScheduleError(apiResponse.error));
    }
  } catch (error) {
    yield put(createUpdateWorkflowScheduleError(error as Error));
  } finally {
    yield put(createWorkflowScheduleLoading(false));
    yield put(changeSchedulesLoadingStatus(false));
  }
}

export interface DeleteWorkflowScheduleRequest {
  type: typeof WorkflowDetailsType.DELETE_SCHEDULE;
  payload: {
    req: DeleteScheduleRequest;
    workflowId: string;
    refreshResults?: () => void;
  };
}

export function* deleteWorkflowSchedule(
  action: DeleteWorkflowScheduleRequest,
): any {
  try {
    yield put(changeSchedulesLoadingStatus(true));
    const apiResponse = yield call(
      workflowSchedulerService.deleteSchedule,
      action.payload.req,
      action.payload.refreshResults,
    );
    if (apiResponse?.error) {
      yield put(deleteWorkflowScheduleError(apiResponse.error));
    }
  } catch (error) {
    yield put(deleteWorkflowScheduleError(error as Error));
  } finally {
    yield put(changeSchedulesLoadingStatus(false));
  }
}

export interface GetWorkflowScheduleRequest {
  type: typeof WorkflowDetailsType.FETCH_SCHEDULE;
  payload: { req: GetScheduleRequest };
}

export function* getWorkflowSchedule(action: GetWorkflowScheduleRequest): any {
  try {
    yield put(createWorkflowScheduleLoading(true));
    const { response: schedule, error } = yield call(
      workflowSchedulerService.getSchedule,
      action.payload.req,
    );

    if (schedule) {
      yield put(setWorkflowSchedule(schedule));
    }

    if (error) {
      yield put(createUpdateWorkflowScheduleError(error));
    }
  } catch (error) {
    // TODO: Add a proper error handling
    yield put(createUpdateWorkflowScheduleError(error));
  } finally {
    yield put(createWorkflowScheduleLoading(false));
  }
}

export interface UpdateWorkflowScheduleRequest {
  type: typeof WorkflowDetailsType.UPDATE_SCHEDULE;
  payload: { req: UpdateScheduleRequest; refreshResults?: () => void };
}

export function* updateWorkflowSchedule(
  action: UpdateWorkflowScheduleRequest,
): any {
  try {
    yield put(createWorkflowScheduleLoading(true));
    const apiResponse = yield call(
      workflowSchedulerService.updateSchedule,
      action.payload.req,
      action.payload.refreshResults,
    );
    if (apiResponse?.error) {
      yield put(createUpdateWorkflowScheduleError(apiResponse.error));
    }
  } catch (error) {
    yield put(createUpdateWorkflowScheduleError(error as Error));
  } finally {
    yield put(createWorkflowScheduleLoading(false));
  }
}

function* workflowDetailsSaga() {
  yield all([
    takeLatest(WorkflowDetailsType.FETCH_WORKFLOW, getOrbotWorkflow),
    takeLatest(WorkflowDetailsType.SAVE_WORKFLOW, saveWorkflow),
    takeLatest(WorkflowDetailsType.FETCH_SCHEDULES, getSchedules),
    takeLatest(WorkflowDetailsType.CREATE_SCHEDULE, createWorkflowSchedule),
    takeLatest(WorkflowDetailsType.DELETE_SCHEDULE, deleteWorkflowSchedule),
    takeLatest(WorkflowDetailsType.FETCH_SCHEDULE, getWorkflowSchedule),
    takeLatest(WorkflowDetailsType.UPDATE_SCHEDULE, updateWorkflowSchedule),
    takeLatest(WorkflowDetailsType.SAVE_CURRENT_WORKFLOW, saveCurrentWorkflow),
    takeLatest(WorkflowDetailsType.SEND_INVITE_TO_ADMINS, sendInviteToAdmins),
  ]);
}

export default workflowDetailsSaga;
