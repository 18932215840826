import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomNodeType } from '../../pages/ProcessDiscovery/components/CustomNode';
import {
  GetProcessRequest,
  GetProcessResponse,
  ListProcessesRequest,
  ListProcessesResponse,
} from 'protos/pb/v1alpha2/process_discovery_service';
import { DataLoadingStatus } from '../../utils/constants';

export enum NodeToolbarOpenedBy {
  CLICK = 'CLICK',
  HOVER = 'HOVER',
}

export interface NodeToolbar {
  node?: CustomNodeType;
  openedBy?: NodeToolbarOpenedBy;
}

export interface EdgeCounts {
  [key: string]: number;
}

export interface ProcessDiscoveryState {
  listProcesses: ListProcessesResponse | null;
  listProcessesLoadingStatus: DataLoadingStatus;
  listProcessesError: string | null;
  getProcess: GetProcessResponse | null;
  getProcessLoadingStatus: DataLoadingStatus;
  getProcessError: string | null;
  showSummarySection: boolean;
  hoveredNode: CustomNodeType | undefined;
  selectedNodes: CustomNodeType[];
  excludedNodeIds: string[];
  excludedInstanceIds: string[];
  selectedPath: number | undefined;
  nodeToolbar?: NodeToolbar;

  isFrequencyFilterOpen: boolean;
  frequencyRange: {
    min: number;
    max: number;
  };
  frequencyFilterSelectedValue: {
    min: number;
    max: number;
  };

  edgeCounts: EdgeCounts;
}

const initialState: ProcessDiscoveryState = {
  listProcesses: null,
  listProcessesLoadingStatus: DataLoadingStatus.INITIAL,
  listProcessesError: null,
  getProcess: null,
  getProcessLoadingStatus: DataLoadingStatus.INITIAL,
  getProcessError: null,
  showSummarySection: true,
  hoveredNode: undefined,
  selectedNodes: [],
  excludedNodeIds: [],
  excludedInstanceIds: [],
  selectedPath: undefined,
  nodeToolbar: undefined,

  isFrequencyFilterOpen: false,
  frequencyRange: {
    min: 0,
    max: 0,
  },
  frequencyFilterSelectedValue: {
    min: 0,
    max: 0,
  },

  edgeCounts: {},
};

// Actions for calling the process discovery services
export const listProcessesAction = createAction<ListProcessesRequest>(
  'processDiscovery/fetchProcesses',
);

export const getProcessAction = createAction<GetProcessRequest>(
  'processDiscovery/getProcess',
);

export const processDiscoverySlice = createSlice({
  name: 'processDiscovery',
  initialState,
  reducers: {
    setShowSummarySection: (state, action: PayloadAction<boolean>) => {
      state.showSummarySection = action.payload;
    },
    setHoveredNode: (
      state,
      action: PayloadAction<CustomNodeType | undefined>,
    ) => {
      state.hoveredNode = action.payload;
    },
    setSelectedNode: (state, action: PayloadAction<CustomNodeType>) => {
      const node = action.payload;
      if (state.selectedNodes.find((n) => n.id === node.id)) {
        state.selectedNodes = state.selectedNodes.filter(
          (n) => n.id !== node.id,
        );
        // If the node was clicked again, we can remove the node toolbar as well
        state.nodeToolbar = undefined;
      } else {
        state.selectedNodes = [...state.selectedNodes, node];
        // When a node is selected, we can show the node toolbar
        state.nodeToolbar = {
          node,
          openedBy: NodeToolbarOpenedBy.CLICK,
        };
      }

      // Apply both node selection and frequency filters
      applyFilters(state);
    },
    clearSelectedNodes: (state) => {
      state.selectedNodes = [];
      state.excludedInstanceIds = [];
      state.excludedNodeIds = [];
    },
    setSelectedPath: (state, action: PayloadAction<number | undefined>) => {
      state.selectedPath = action.payload;
    },
    setListProcesses: (state, action: PayloadAction<ListProcessesResponse>) => {
      state.listProcesses = action.payload;
    },
    setGetProcess: (state, action: PayloadAction<GetProcessResponse>) => {
      state.getProcess = action.payload;
      // Initialize edge counts
      const edgeCounts = new Map<string, number>();
      const processInstances = state.getProcess?.process?.processInstances;
      const stepNodes = state.getProcess?.process?.stepNodes;
      if (!processInstances) return;
      for (const instance of processInstances) {
        let steps = instance.stepNodeInstances || [];
        if (steps.length === 0) continue;
        // Temporary hack to add START and END nodes to the steps
        // These should be included in the process instance
        const firstNodeType = stepNodes?.find(
          (node) => node.id === steps[0].stepNodeId,
        )?.stepTypeId;
        const lastNodeType = stepNodes?.find(
          (node) => node.id === steps[steps.length - 1].stepNodeId,
        )?.stepTypeId;
        if (
          firstNodeType !== 'START' &&
          stepNodes?.find((node) => node.id === 'START')
        ) {
          steps = [{ stepNodeId: 'START' }, ...steps];
        }
        if (
          lastNodeType !== 'END' &&
          stepNodes?.find((node) => node.id === 'END')
        ) {
          steps = [...steps, { stepNodeId: 'END' }];
        }
        for (let i = 0; i < steps.length - 1; i++) {
          const currentStepId = steps[i].stepNodeId;
          const nextStepId = steps[i + 1].stepNodeId;
          if (!currentStepId || !nextStepId) continue;

          const edgeKey = `${currentStepId}-${nextStepId}`;
          edgeCounts.set(edgeKey, (edgeCounts.get(edgeKey) || 0) + 1);
        }
      }
      state.edgeCounts = Object.fromEntries(edgeCounts);
      // Calculate frequency range
      const minCount = Math.min(...Array.from(edgeCounts.values()));
      const maxCount = Math.max(...Array.from(edgeCounts.values()));
      state.frequencyRange = {
        min: minCount,
        max: maxCount,
      };
    },
    setListProcessesLoading: (
      state,
      action: PayloadAction<DataLoadingStatus>,
    ) => {
      state.listProcessesLoadingStatus = action.payload;
    },
    setGetProcessLoading: (state, action: PayloadAction<DataLoadingStatus>) => {
      state.getProcessLoadingStatus = action.payload;
    },
    setListProcessesError: (state, action: PayloadAction<string | null>) => {
      state.listProcessesError = action.payload;
    },
    setGetProcessError: (state, action: PayloadAction<string | null>) => {
      state.getProcessError = action.payload;
    },
    setNodeToolbar: (state, action: PayloadAction<NodeToolbar | undefined>) => {
      state.nodeToolbar = action.payload;
    },

    setIsFrequencyFilterOpen: (state, action: PayloadAction<boolean>) => {
      state.isFrequencyFilterOpen = action.payload;
    },

    setFrequencyFilterSelectedValue: (
      state,
      action: PayloadAction<{ min: number; max: number }>,
    ) => {
      if (action.payload.min >= 0 && action.payload.max >= 0) {
        state.frequencyFilterSelectedValue = action.payload;

        // Apply both node selection and frequency filters
        applyFilters(state);
      }
    },

    clearProcess: (state) => {
      state.getProcess = null;
      state.getProcessLoadingStatus = DataLoadingStatus.INITIAL;
      state.getProcessError = null;
      state.nodeToolbar = undefined;
      state.selectedNodes = [];
      state.excludedNodeIds = [];
      state.excludedInstanceIds = [];
      state.selectedPath = undefined;
      state.isFrequencyFilterOpen = false;
      state.showSummarySection = true;
      state.frequencyRange = {
        min: 0,
        max: 0,
      };
      state.frequencyFilterSelectedValue = {
        min: 0,
        max: 0,
      };
      state.edgeCounts = {};
    },
  },
  selectors: {
    showSummarySectionSelector: (state) => state.showSummarySection,
    hoveredNodeSelector: (state) => state.hoveredNode,
    selectedNodesSelector: (state) => state.selectedNodes,
    excludedNodeIdsSelector: (state) => state.excludedNodeIds,
    excludedInstanceIdsSelector: (state) => state.excludedInstanceIds,
    selectedPathSelector: (state) => state.selectedPath,
    listProcessesSelector: (state) => state.listProcesses,
    getProcessSelector: (state) => state.getProcess,
    listProcessesLoadingStatusSelector: (state) =>
      state.listProcessesLoadingStatus,
    getProcessLoadingStatusSelector: (state) => state.getProcessLoadingStatus,
    listProcessesErrorSelector: (state) => state.listProcessesError,
    getProcessErrorSelector: (state) => state.getProcessError,
    processDiscoveryListStateSelector: (state) => ({
      processesData: state.listProcesses,
      isLoading: state.listProcessesLoadingStatus === DataLoadingStatus.LOADING,
      error: state.listProcessesError,
    }),
    nodeToolbarSelector: (state) => state.nodeToolbar,

    isFrequencyFilterOpenSelector: (state) => state.isFrequencyFilterOpen,
    frequencyRangeSelector: (state) => state.frequencyRange,
    frequencyFilterSelectedValueSelector: (state) =>
      state.frequencyFilterSelectedValue,
    edgeCountsSelector: (state) => state.edgeCounts,
  },
});

export const {
  setShowSummarySection,
  setHoveredNode,
  setSelectedNode,
  clearSelectedNodes,
  setSelectedPath,
  setListProcesses,
  setGetProcess,
  setListProcessesLoading,
  setGetProcessLoading,
  setListProcessesError,
  setGetProcessError,
  setIsFrequencyFilterOpen,
  setNodeToolbar,
  clearProcess,
  setFrequencyFilterSelectedValue,
} = processDiscoverySlice.actions;

export const {
  showSummarySectionSelector,
  hoveredNodeSelector,
  selectedNodesSelector,
  excludedNodeIdsSelector,
  excludedInstanceIdsSelector,
  selectedPathSelector,
  listProcessesSelector,
  getProcessSelector,
  listProcessesLoadingStatusSelector,
  getProcessLoadingStatusSelector,
  listProcessesErrorSelector,
  getProcessErrorSelector,
  processDiscoveryListStateSelector,
  isFrequencyFilterOpenSelector,
  nodeToolbarSelector,
  frequencyRangeSelector,
  frequencyFilterSelectedValueSelector,
  edgeCountsSelector,
} = processDiscoverySlice.selectors;

export default processDiscoverySlice.reducer;

const applyFilters = (state: ProcessDiscoveryState) => {
  const selectedNodeIds = new Set<string>();
  const excludedInstanceIds = new Set<string>();
  const { min, max } = state.frequencyFilterSelectedValue;

  state.getProcess?.process?.processInstances?.forEach((instance) => {
    if (!instance.id) return;

    // Check node selection criteria
    const hasAllSelectedNodes =
      state.selectedNodes.length === 0 ||
      state.selectedNodes.every((selectedNode) =>
        instance.stepNodeInstances?.some(
          (step) => step.stepNodeId === selectedNode.id,
        ),
      );

    // Check frequency criteria for all edges in this instance
    let meetsFrequencyFilter = true;
    const steps = instance.stepNodeInstances || [];
    for (let i = 0; i < steps.length - 1 && meetsFrequencyFilter; i++) {
      const currentStepId = steps[i].stepNodeId;
      const nextStepId = steps[i + 1].stepNodeId;
      if (!currentStepId || !nextStepId) continue;

      const edgeCount = state.edgeCounts[`${currentStepId}-${nextStepId}`];
      if (edgeCount === undefined || edgeCount < min || edgeCount > max) {
        meetsFrequencyFilter = false;
        break;
      }
    }

    // Instance is valid only if it meets both criteria
    if (hasAllSelectedNodes && meetsFrequencyFilter) {
      // Collect nodes from valid instances
      instance.stepNodeInstances?.forEach(
        (step) => step.stepNodeId && selectedNodeIds.add(step.stepNodeId),
      );
    } else {
      excludedInstanceIds.add(instance.id);
    }
  });

  // Calculate excluded nodes
  const excludedNodeIds = new Set<string>();
  state.getProcess?.process?.stepNodes?.forEach((step) => {
    if (
      step.id &&
      step.stepTypeId !== 'START' &&
      step.stepTypeId !== 'END' &&
      !selectedNodeIds.has(step.id)
    ) {
      excludedNodeIds.add(step.id);
    }
  });

  // If no nodes are selected and frequency filter is at default range, clear all filters
  if (
    state.selectedNodes.length === 0 &&
    min === state.frequencyRange.min &&
    max === state.frequencyRange.max
  ) {
    state.excludedInstanceIds = [];
    state.excludedNodeIds = [];
    state.nodeToolbar = undefined;
  } else {
    state.excludedInstanceIds = Array.from(excludedInstanceIds);
    state.excludedNodeIds = Array.from(excludedNodeIds);
  }
};
