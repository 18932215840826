// OrbyTabs.tsx
import React, { FC, memo } from 'react';
import { Tabs, Tab, SxProps, Theme } from '@mui/material';
import '../../styles/global.css';
import OrbyTypography from '../typography/OrbyTypography';
import OrbyColorPalette from '../colors/ColorPalette';

export interface OrbyTabsProps {
  /**
   * Tabs
   */
  tabs: Array<{
    label: string | React.ReactNode;
    id: string;
  }>;
  /**
   * Selected Tab
   */
  selectedTab: number;
  /**
   * Set Selected Tab
   */
  setSelectedTab: (tabIndex: number) => void;
  /**
   * Tab Typography Size
   */
  typographySize?: 'sm' | 'md' | 'lg';
  /**
   * Tab Typography Weight
   */
  typographyWeight?: 'regular' | 'medium' | 'semibold' | 'bold';
  /**
   * Tab Options Sx
   */
  tabOptionsSx?: React.CSSProperties;
  /**
   * Tab Container Sx
   */
  tabContainerSx?: React.CSSProperties | SxProps<Theme>;
}

const OrbyTabs: FC<OrbyTabsProps> = ({
  tabs,
  selectedTab,
  setSelectedTab,
  typographySize = 'md',
  typographyWeight = 'medium',
  tabOptionsSx = {},
  tabContainerSx = {},
}) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Tabs
        TabIndicatorProps={{
          style: {
            backgroundColor: OrbyColorPalette['blue-700'],
          },
        }}
        value={selectedTab}
        onChange={handleChange}
        sx={tabContainerSx}
      >
        {tabs.map((tab, index) => (
          <Tab
            sx={{
              textTransform: 'none',
              padding: '14px 24px 10px 24px',
              cursor: 'pointer',
              ...tabOptionsSx,
            }}
            tabIndex={index}
            title={typeof tab.label === 'string' ? tab.label : ''}
            aria-label={typeof tab.label === 'string' ? tab.label : ''}
            key={tab.id}
            label={
              typeof tab.label === 'string' ? (
                <OrbyTypography
                  size={typographySize}
                  weight={typographyWeight}
                  color={OrbyColorPalette['grey-900']}
                >
                  {tab.label}
                </OrbyTypography>
              ) : (
                tab.label
              )
            }
            id={`tab-${tab.id}`}
          />
        ))}
      </Tabs>
    </>
  );
};

export default memo(OrbyTabs);
