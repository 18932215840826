import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createMachine,
  deleteMachine,
  deregisterMachine,
  fetchMachines,
  registerMachine,
  setCreateMachineError,
  setCreateMachineLoading,
  setCreateMachineResponse,
  setDeleteMachineError,
  setDeleteMachineLoadingStatus,
  setDeregisterMachineError,
  setDeregisterMachineLoadingStatus,
  setMachines,
  setMachinesListError,
  setMachinesListLoading,
  setRegisterMachineError,
  setRegisterMachineLoading,
  setRegisterMachineResponse,
} from '../slices/machines.slice';
import { machinesService } from '../../services/MachinesService';
import {
  CreateMachineRequest,
  DeleteMachineRequest,
  DeregisterMachineRequest,
  ListMachinesRequest,
  RegisterMachineRequest,
} from 'protos/pb/v1alpha1/machine_identity_service';
import { DataLoadingStatus } from '../../utils/constants';

export function* listMachinesSaga(data: {
  type: typeof fetchMachines.type;
  payload: ListMachinesRequest;
}): any {
  try {
    yield put(setMachinesListLoading(DataLoadingStatus.LOADING));

    const { response, error } = yield call(
      machinesService.listMachines,
      data.payload,
    );
    if (response) {
      yield put(setMachines(response));
      yield put(setMachinesListLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setMachinesListError(error?.message || error));
      yield put(setMachinesListLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setMachinesListError(error?.message || error));
    yield put(setMachinesListLoading(DataLoadingStatus.ERROR));
  }
}

export function* createMachineSaga(data: {
  type: typeof createMachine.type;
  payload: CreateMachineRequest;
}): any {
  try {
    yield put(setCreateMachineLoading(DataLoadingStatus.LOADING));

    const { response, error } = yield call(
      machinesService.createMachine,
      data.payload,
    );
    if (response) {
      yield put(setCreateMachineResponse(response));
      yield put(setCreateMachineLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setCreateMachineError(error?.message || error));
      yield put(setCreateMachineLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setCreateMachineError(error?.message || error));
    yield put(setCreateMachineLoading(DataLoadingStatus.ERROR));
  }
}
export function* registerMachineSaga(data: {
  type: typeof registerMachine.type;
  payload: RegisterMachineRequest;
}): any {
  try {
    yield put(setRegisterMachineLoading(DataLoadingStatus.LOADING));
    const { response, error } = yield call(
      machinesService.registerMachine,
      data.payload,
    );
    if (response) {
      yield put(setRegisterMachineResponse(response));
      yield put(setRegisterMachineLoading(DataLoadingStatus.LOADED));
    } else {
      yield put(setRegisterMachineError(error?.message || error));
      yield put(setRegisterMachineLoading(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setRegisterMachineError(error?.message || error));
    yield put(setRegisterMachineLoading(DataLoadingStatus.ERROR));
  }
}

export function* deregisterMachineSaga(data: {
  type: typeof deregisterMachine.type;
  payload: DeregisterMachineRequest;
}): any {
  try {
    yield put(setDeregisterMachineLoadingStatus(DataLoadingStatus.LOADING));

    const { response, error } = yield call(
      machinesService.deregisterMachine,
      data.payload,
    );

    if (response) {
      yield put(setDeregisterMachineLoadingStatus(DataLoadingStatus.LOADED));
    } else {
      yield put(setDeregisterMachineError(error?.message || error));
      yield put(setDeregisterMachineLoadingStatus(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setDeregisterMachineError(error?.message || error));
    yield put(setDeregisterMachineLoadingStatus(DataLoadingStatus.ERROR));
  }
}

export function* deleteMachineSaga(data: {
  type: typeof deleteMachine.type;
  payload: DeleteMachineRequest;
}): any {
  try {
    yield put(setDeleteMachineLoadingStatus(DataLoadingStatus.LOADING));

    const { response, error } = yield call(
      machinesService.deleteMachine,
      data.payload,
    );
    if (response) {
      yield put(setDeleteMachineLoadingStatus(DataLoadingStatus.LOADED));
    } else {
      yield put(setDeleteMachineError(error?.message || error));
      yield put(setDeleteMachineLoadingStatus(DataLoadingStatus.ERROR));
    }
  } catch (error) {
    yield put(setDeleteMachineError(error?.message || error));
    yield put(setDeleteMachineLoadingStatus(DataLoadingStatus.ERROR));
  }
}

export function* machinesSaga() {
  yield takeLatest(fetchMachines.type, listMachinesSaga);
  yield takeLatest(createMachine.type, createMachineSaga);
  yield takeLatest(registerMachine.type, registerMachineSaga);
  yield takeLatest(deregisterMachine.type, deregisterMachineSaga);
  yield takeLatest(deleteMachine.type, deleteMachineSaga);
}
