import { authService } from './AuthService';
import { eraseCookie } from '../utils/cookie';
import {
  GetNewGoogleTokenRequest,
  GetNewGoogleTokenResponse,
} from 'protos/pb/v1alpha1/tokens_service';
import { LoginResponse } from 'protos/pb/v1alpha1/users_service';
import { grpc } from '@improbable-eng/grpc-web';
import { SAML_REDIRECT_STATUS_KEY, STORAGE_KEYS } from '../utils/constants';
import { MicrosoftUserInfo } from 'protos/pb/v1alpha1/user';

export interface Token {
  sessionId: string | undefined;
  accessToken: string | undefined;
  accessTokenExpiresAt: number | undefined;
  refreshToken: string | undefined;
  refreshTokenExpiresAt: number | undefined;
  platform?: string;
}

export const convertToken = (pbToken: LoginResponse): Token => {
  return {
    sessionId: pbToken.sessionId,
    accessToken: pbToken.accessToken,
    accessTokenExpiresAt:
      pbToken.accessTokenExpiresAt &&
      new Date(pbToken.accessTokenExpiresAt)?.getTime(),
    refreshToken: pbToken.refreshToken,
    refreshTokenExpiresAt:
      pbToken.refreshTokenExpiresAt &&
      new Date(pbToken.refreshTokenExpiresAt)?.getTime(),
  };
};

export class StorageService {
  private static instance: StorageService;

  public static getInstance(): StorageService {
    if (!this.instance) {
      this.instance = new StorageService();
    }
    return this.instance;
  }

  // For fetching the google token - currently we use when token is expired or cookie FF is enabled
  private fetchGoogleToken(email: string): Promise<GetNewGoogleTokenResponse> {
    return new Promise((resolve, reject) => {
      authService
        .registerGoogleToken({ email } as GetNewGoogleTokenRequest)
        .then((res: GetNewGoogleTokenResponse) => {
          resolve({
            accessToken: res.accessToken as string,
            accessTokenExpiresAt: res.accessTokenExpiresAt!,
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  setStoredToken(token: Token): Promise<void> {
    if (JSON.stringify(token) === '{}') {
      throw Error('Empty token is being stored.');
    }
    return new Promise((resolve) => {
      const tokenSting = JSON.stringify(token);
      localStorage.setItem('token', tokenSting);
      resolve();
    });
  }

  setStoredGoogleToken(token: GetNewGoogleTokenResponse): Promise<void> {
    if (JSON.stringify(token) === '{}') {
      throw Error('Empty token is being stored.');
    }
    return new Promise((resolve) => {
      const tokenSting = JSON.stringify(token);
      localStorage.setItem('google-token', tokenSting);
      resolve();
    });
  }

  getStoredGoogleToken(
    email: string,
    isLoginCookieEnabled?: boolean,
  ): Promise<GetNewGoogleTokenResponse> {
    return new Promise((resolve) => {
      if (isLoginCookieEnabled) {
        return this.fetchGoogleToken(email)
          .then(resolve)
          .catch((e) => {
            console.error('Error fetching google token', e);
            resolve(GetNewGoogleTokenResponse.create());
          });
      }

      const item = localStorage.getItem('google-token');
      const itemToken: GetNewGoogleTokenResponse = item && JSON.parse(item);

      if (!itemToken) {
        console.error('No google token found');
        resolve(GetNewGoogleTokenResponse.create());
      }
      // 30 * 1000 is 30 seconds, so if the access token will expire in 30 seconds , we deem it expired
      if (
        new Date(itemToken?.accessTokenExpiresAt ?? '') <
        new Date(new Date().getTime() + 30 * 1000)
      ) {
        this.fetchGoogleToken(email)
          .then(resolve)
          .catch(() => resolve(itemToken));
      } else {
        resolve(itemToken);
      }
    });
  }

  setStoredOrgResourceName(orgResourceName: string): Promise<void> {
    return new Promise((resolve) => {
      localStorage.setItem('org-resource-name', orgResourceName);
      resolve();
    });
  }

  getStoredOrgResourceName(): Promise<string | null> {
    return new Promise((resolve) => {
      const item = localStorage.getItem('org-resource-name');
      resolve(item);
    });
  }

  getStoredToken(refreshToken = true): Promise<Token | undefined> {
    return new Promise((resolve) => {
      const item = localStorage.getItem('token');
      const itemToken: Token = item && JSON.parse(item);
      if (item) {
        if (refreshToken) {
          authService
            .refreshTokenIfNeeded(itemToken)
            .then((token: Token) => {
              resolve(token);
            })
            .catch(() => {
              resolve(undefined);
            });
        } else {
          resolve(itemToken);
        }
      } else {
        resolve(undefined);
      }
    });
  }

  deleteStoredValues(preserveWorkflowPopup = false): void {
    if (preserveWorkflowPopup) {
      // Store the popup check value before clearing
      const popupChecked = localStorage.getItem('isRunWorkflowPopupChecked');
      localStorage.clear();
      // Restore the popup check value if it existed
      if (popupChecked) {
        localStorage.setItem('isRunWorkflowPopupChecked', popupChecked);
      }
    } else {
      localStorage.clear();
    }
    eraseCookie('token');
  }

  setStoredEnableEventUpload(enable_event_upload: boolean): Promise<void> {
    return new Promise((resolve) => {
      localStorage.setItem(
        'enable_event_upload',
        enable_event_upload.toString(),
      );
      resolve();
    });
  }

  getStoredEnabledEventUpload(): Promise<boolean> {
    return new Promise((resolve) => {
      const item = localStorage.getItem('enable_event_upload');
      resolve(item === 'true');
    });
  }

  getAuthorizationHeader(): Promise<string> {
    return new Promise((resolve) => {
      StorageService.instance
        .getStoredToken()
        .then((token: Token | undefined) => {
          if (token) {
            resolve(`Bearer ${token?.accessToken}`);
          } else {
            /**
             * NOTE: if there is no token present in the local storage,
             * we can resolve this as cookie is automatically being sent to BE in all API calls (if present).
             */
            resolve('');
          }
        });
    });
  }

  async getMetadata(): Promise<grpc.Metadata> {
    const authHeader = await this.getAuthorizationHeader();
    if (authHeader) {
      return new grpc.Metadata({ authorization: authHeader });
    } else {
      return new grpc.Metadata();
    }
  }

  async getScopesFromGoogleToken(
    email: string,
    isLoginCookieEnabled: boolean,
  ): Promise<string | undefined> {
    const googleToken = await this.getStoredGoogleToken(
      email,
      isLoginCookieEnabled,
    );
    if (!googleToken.accessToken) {
      return undefined;
    }
    const response = await fetch(
      `https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=${googleToken.accessToken}`,
    );
    const data = await response.json();
    return data.scope;
  }

  setStoredGoogleScope(scopes: string): Promise<void> {
    return new Promise((resolve) => {
      localStorage.setItem('google-scopes', scopes);
      resolve();
    });
  }

  async getStoredGoogleScope(email: string, isLoginCookieEnabled: boolean) {
    const item = localStorage.getItem('google-scopes');
    if (!item) {
      const scopes = await this.getScopesFromGoogleToken(
        email,
        isLoginCookieEnabled,
      );
      if (scopes) {
        await this.setStoredGoogleScope(scopes);
        return scopes;
      }
      return '';
    }
    return item;
  }

  setStoredMicrosoftInfo(data: MicrosoftUserInfo): Promise<void> {
    return new Promise((resolve) => {
      localStorage.setItem('microsoft-info', JSON.stringify(data));
      resolve();
    });
  }

  getStoredMicrosoftInfo(): Promise<MicrosoftUserInfo | null> {
    return new Promise((resolve) => {
      const item = localStorage.getItem('microsoft-info');
      resolve(item ? JSON.parse(item) : null);
    });
  }

  // We are using session storage instead of local storage because we only
  // need to persist the SAML redirect status for the duration of the current
  // tab session. If the SAML redirect status is not cleared properly,
  // it could cause issues with the front-end code. Session storage ensures
  // that the data is cleared when the tab is closed.

  private getSamlRedirect() {
    return sessionStorage.getItem(SAML_REDIRECT_STATUS_KEY);
  }

  private clearSamlRedirect() {
    return sessionStorage.removeItem(SAML_REDIRECT_STATUS_KEY);
  }

  setSamlRedirect() {
    return sessionStorage.setItem(SAML_REDIRECT_STATUS_KEY, 'true');
  }

  isSamlRedirect() {
    if (this.getSamlRedirect() === 'true') {
      this.clearSamlRedirect();
      return true;
    }
    return false;
  }

  setSessionId(sessionId: string | undefined): Promise<void> {
    return new Promise((resolve) => {
      localStorage.setItem(STORAGE_KEYS.SESSION_ID, sessionId || '');
      resolve();
    });
  }

  getSessionId() {
    return localStorage.getItem(STORAGE_KEYS.SESSION_ID);
  }

  removeSessionId() {
    return localStorage.removeItem(STORAGE_KEYS.SESSION_ID);
  }

  getSessionExpirationTime() {
    const expirationTime = localStorage.getItem(
      STORAGE_KEYS.SESSION_EXPIRATION_TIME,
    );
    return expirationTime ? JSON.parse(expirationTime) : null;
  }

  setSessionExpirationTime(time: number) {
    if (!this.getSessionExpirationTime()) {
      localStorage.setItem(
        STORAGE_KEYS.SESSION_EXPIRATION_TIME,
        JSON.stringify(time),
      );
    }
  }

  getIsSessionExpirationWarningShown(): boolean {
    const expirationTime = localStorage.getItem(
      STORAGE_KEYS.IS_SESSION_EXPIRATION_WARNING_SHOWN,
    );
    return expirationTime ? JSON.parse(expirationTime) : false;
  }

  setIsSessionExpirationWarningShown(isShown: boolean) {
    localStorage.setItem(
      STORAGE_KEYS.IS_SESSION_EXPIRATION_WARNING_SHOWN,
      JSON.stringify(isShown),
    );
  }

  setLastActivityTime(time: number) {
    localStorage.setItem(STORAGE_KEYS.LAST_ACTIVITY_TIME, JSON.stringify(time));
  }

  getLastActivityTime() {
    const time = localStorage.getItem(STORAGE_KEYS.LAST_ACTIVITY_TIME);
    return time ? JSON.parse(time) : null;
  }

  setProcessDiagramState(processId: string | undefined, state: string) {
    if (!processId) return;
    localStorage.setItem(
      `${STORAGE_KEYS.PROCESS_DIAGRAM_STATE}-${processId}`,
      state,
    );
  }

  getProcessDiagramState(processId: string | undefined) {
    if (!processId) return null;
    return localStorage.getItem(
      `${STORAGE_KEYS.PROCESS_DIAGRAM_STATE}-${processId}`,
    );
  }
}

export const storageService = StorageService.getInstance();
